<template>
	<div class="user-bg-swg html-two">
		<uerHeader  type="" class="bg_fff"></uerHeader>
		<div >
			<el-row :gutter="24" class=" wdsffdzcdtg mt40">
				<el-col :span="4" :offset="2">
					<userLeft></userLeft>
				</el-col>
				<el-col :span="16" >
					<div class="m-content fix">
						<div class="user-main" >
							<div v-loading="loading">
								<div v-if="!loading">
									<div class="user-safety c_fff user-safety-blue">
										<div class="flex alcenter">
											<div class="ft60 c_fff">{{setNum}}</div>
											<div class="ml16">
												 <el-rate
												 size="large"
														v-model="account.star"
														disabled
														text-color="#FFFFFF"
													/>
													<div>{{account.remark}}</div>
											</div>
										</div>
										<div class="detection">
											<!-- <el-button color="#fff" @click="detection">检测</el-button> -->
										</div>
									</div>
									<div class="flex alcenter mt24">
										<a class="flex-one qwda " href="javascript:;" @click="openReal">
											<div class="bg_fff exafy" style="margin-left: 0;">
												<div>
													<p class="ft20 mr8 c_333333">
														我的身份认证
														<span>
															<img src="../../assets/user/main/success.png"  v-if="realInfo.name" />
															<img src="../../assets/user/main/cuam.png" v-else  />
														</span>
													</p>
													<p class="mt7 c_7f7f7f ft14">{{realInfo.name?'已完成实名认证':'请尽快去实名认证, 避免影响结算'}}</p>								
												</div>
												<div class="iconfont icon-youjiantou c_0EB6FF"></div>
											</div>
										</a>
										<a class="flex-one qwda" href="javascript:;" @click="openPhone">
											<div class="bg_fff exafy" >
												<div>
													<p class="ft20 mr8 c_333333">
														我的关联手机
														<span>
															<img src="../../assets/user/main/success.png"  v-if="userInfo.phone" />
															<img src="../../assets/user/main/cuam.png" v-else  />
														</span>
													</p>
													<p class="mt7 c_7f7f7f ft14">{{userInfo.phone?'已完绑定手机号':'作为您验证身份的重要方式，请谨慎操作'}}</p>								
												</div>
												<div class="iconfont icon-youjiantou c_0EB6FF"></div>
											</div>
										</a>
										<a class="flex-one qwda" href="javascript:;" @click="openPwd">
											<div class="bg_fff exafy" style="margin-right: 0;">
												<div>
													<p class="ft20 mr8 c_333333">
														我的账号密码
														<span>
															<img src="../../assets/user/main/cuam.png"  />
														</span>
													</p>
													<p class="mt7 c_7f7f7f ft14">密码已设置，登录更加方便了</p>								
												</div>
												<div class="iconfont icon-youjiantou c_0EB6FF"></div>
											</div>
										</a>
									</div>
									<div class="bg_fff mt24 pd24 bord rounded wdsbvuy">
										<div class="font-weight700 ft16">基本信息</div>
										<div class="userinfo-bottom flex alcenter mt24">
											<div class="userinfo-left">
												<div class="flex alcenter">
													<img src="../../assets/user/main/money.png" class="monq" />
													<div >
														<p class="c_737373 ft14">账户余额</p>
														<p class="mt10 ft28 font-weight500">{{userInfo.balance}}</p>
													</div>
												</div>
											</div>
											<div class="hint-user"></div>
											<div class="userinfo-right">
												<div class="flex alcenter">
													<el-button color="#0EB6FF"  class="with-buttonds mr24" @click="openWithdraw">提现</el-button>
													<a class="c_737373 ft16" href="javascript:;" @click="openWithdrawRecord">提现记录</a>
												</div>
											</div>
										</div>
										<div class="flex alcenter  mt30 mb20">
											<div class="userinfo-left">
												<div class="flex alcenter">
													<div class="c_737373 qsfeuser">账号昵称</div>
													<div>
														{{userInfo.nickName?userInfo.nickName:'-'}}
														<!-- <span class="icon-xiugai iconfont" style="color: #737373;font-size: 10px;margin-left: 7px;"></span> -->
													</div>
												</div>
												<div class="flex alcenter mt24">
													<div class="c_737373 qsfeuser">账号ID</div>
													<div>{{userInfo.user_id}}</div>
												</div>
											</div>
											<div class="hint-user"></div>
											<div class="userinfo-right">
												<div class="flex alcenter">
													<div class="c_737373 qsfeuser">注册时间</div>
													<div>{{userInfo.create_time}}</div>
												</div>
												<div class="flex alcenter mt24">
													<div class="c_737373 qsfeuser">关联邮箱</div>
													<div>
														未认证
														<a class="c_0EB6FF ml24" href="javascript:;" @click="openMeail()">
															去关联
															<span class="iconfont icon-youjiantou"></span>
														</a>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="mt40 ">
										<div class="flex alend">
											<div class="userstatus-title">今日交易统计</div>
											<div class="c_737373 ft16">{{today}}</div>
										</div>
										<div class="bg_fff pd24 mt16 rounded wdsbvuy">
											<div class="flex alcenter">
												<div class="usercard-item " @click="getCate_id(-1)" :class="{'usercard-acttive':cate_id === -1}">全部</div>
												<div class="usercard-item" v-for="(item,index) in cardList" :key='index' @click="getCate_id(item.cate_id)"  :class="{'usercard-acttive':cate_id === item.cate_id}">{{item.name}}</div>
											</div>
		<!-- 									<div class="flex alcenter mt30">
												<div class="usergoods-item" v-for="(item,index) in goodsList" :key='index'>
													<img src="../../assets/user/main/test.png" />
													<span>{{item.name}}</span>
												</div>
											</div> -->
											<div class="cardstatistics">
												<div class="flex alcenter">
													<div class="flex-one text-center">
														<p class="c_737373 ft16 ">今日到账(元)</p>
														<p class="ft40 font-weight700 mt24">{{totayStatistics.received_today}}</p>
													</div>
													<div class="flex-one text-center">
														<p class="c_737373 ft16 ">今日回收(张)</p>
														<p class="ft40 font-weight700 mt24">{{totayStatistics.recycling_today}}</p>
													</div>
													<div class="flex-one text-center">
														<p class="c_737373 ft16 ">今日面额不符(张)</p>
														<p class="ft40 font-weight700 mt24">{{totayStatistics.face_value_mismatch}}</p>
													</div>
													<div class="flex-one text-center">
														<p class="c_737373 ft16 ">今日交易失败(张)</p>
														<p class="ft40 font-weight700 mt24">{{totayStatistics.failed_transaction}}</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div v-else style="height: 1000px;background-color: #fff;"></div>
							</div>
						</div>
					</div>
				</el-col>
			</el-row>

		</div>
		<userBottom></userBottom>
	</div>
</template>

<script>
	import uerHeader from '../../components/header/card.vue'
	import userLeft from '../../components/left/index.vue'
	import userBottom from '../../components/footer/user.vue'
	var myDate = new Date();
	export default{
		data(){
			return{
				userInfo:{},
				value:1,
				account:{},
				cardList:[],
				goodsList:[
					{
						name:'移动充值卡',
						img:'../../assets/user/main/test.png',
						id:1
					},
					{
						name:'联通充值卡',
						img:'../../assets/user/main/test.png',
						id:2
					},
					{
						name:'电信充值卡',
						img:'../../assets/user/main/test.png',
						id:3
					}
				],
				loading:false,
				realInfo:{},
				cate_id:-1,
				totayStatistics:{},
				today:"",
				timerResult:null,
				setNum:0
			}
		},
		components:{
			uerHeader,
			userLeft,
			userBottom
		},
		created(){
			this.today = `${myDate.getFullYear()}-${myDate.getMonth()+1}-${myDate.getDate()}`
			this.getUserInfo()
		},
		methods:{
			async detection(){
				await this.detect_account_risks()
				this.numberAdd()
			},
			numberAdd(num){
				this.setNum = 0
				this.timerResult = setInterval(()=> {
					if(this.setNum === this.account.score){
						clearInterval(this.timerDetail)
						return
					}
					this.setNum ++
				},40)
			},
			openReal(){
				this.$router.push( {path:'/user/userDatum',query:{type:2}})
			},
			openPhone(){
				this.$router.push( {path:'/user/userDatum',query:{type:4}})
			},
			openPwd(){
				this.$router.push( {path:'/user/userDatum',query:{type:3}})
			},
			openMeail(){
				this.$router.push( {path:'/user/userDatum',query:{type:5}})
			},
			openWithdraw(){
				this.$router.push( {path:'/user/withdraw'})
			},
			openWithdrawRecord(){
				this.$router.push( {path:'/user/withdraw_record'})
			},
			getCate_id(cate_id){
				this.cate_id = cate_id
				this.today_statistics(cate_id)
			},
			loadDetail1() {
				return new Promise((resolve,reject) => {
					this.$api.getUserRealStatus({
						type:1
					})
					.then(result => {
						this.realInfo = result.data
						resolve(result.data)
					})
				})
			},
			today_statistics(cate_id){
				return new Promise((resolve,reject) => {
					this.$api.today_statistics({
						 cate_id
					})
					.then(result => {
						this.totayStatistics = result.data
						resolve(true)
					})
				})
			},
			cardlist(){
				return new Promise((resolve,reject) => {
					this.$api.cardlist()
					.then(result => {
						this.cardList = result.data
						resolve(true)
					})
				})
			},
			detect_account_risks(){
				return new Promise((resolve,reject) => {
					this.$api.detect_account_risks()
					.then(result => {
						this.account = result.data
						resolve(true)
					})
				})
			},
			getUserInfo(){
				this.loading = true
				this.$api.userInfo()
				.then(async result => {
					if(result.code === 1){
						this.userInfo = result.data
						localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
						await this.loadDetail1()
						await this.cardlist()
						await this.today_statistics(this.cate_id)
						await this.detect_account_risks()
					}
					this.$nextTick(() => {
						setTimeout(() => {
							this.loading = false
							this.numberAdd()
						},500)
						
					})
				})
				.catch(error => {
					this.loading = false
				})
			}
		}
	}
</script>

<style scoped>
	.user-bg-swg{
		background-color: #f7f7f7;
	}
	.el-rate .el-rate__icon.is-active {
	    color: #ffffff!important;
	}
	.el-icon el-rate__icon{
		opacity: 0.5!important;
	}
	.el-rate .el-rate__icon{
		font-size: 30px!important;
	}
	/* :root { --el-rate-void-color: red; --el-rate-fill-color: blue; } */
	.user-safety{
		border-radius: 8px;
		padding: 23px 24px 23px 24px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.user-safety-blue{
		background: linear-gradient(90deg, #5DC5FF 0%, #3E51FF 100%);
	}
	.detection button{
		color: #466EFF;
		font-size: 16px;
		border-radius: 100px;
		width: 100px;
		height: 42px;
	}
	.exafy{
		margin: 0 12px;
		border-radius: 8px;
		padding: 16px 24px 23px 24px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 62px;
	}
	.exafy img{
		width: 20px;
		height: 20px;
	}
/* 	.qwda{
		display: flex;
		align-items: center;
		justify-content: space-between;
	} */
	.exafy:hover{
		box-shadow: 4px 6px 8px #e7e7e7;
		transition: .3s;
	}
	.wdsbvuy:hover{
		box-shadow: 4px 6px 8px #e7e7e7;
		transition: .3s;
	}
	.userinfo-left{
		flex: 0.3;
		font-size: 16px;
	}
	.qsfeuser{
		width: 150px;
	}
	.hint-user{
		background-color: #EDEDED;
		width: 1px;
		height: 40px;
	}
	.userinfo-right{
		flex: 0.7;
		margin-left: 80px;
	}
	.userinfo-bottom{
		border-bottom: 1px dashed #E5E5E5;
		padding-bottom: 24px;
	}
	.monq{
		width: 40px;
		height: 40px;
		margin-right: 16px;
	}
	.with-buttonds{
		width: 120px;
		height: 42px!important;
		color: #ffffff!important;
		font-size: 16px;
	}
	.userstatus-title{
		font-weight: 700;
		font-size: 20px;
		border-right: 1px solid #EDEDED;
		padding-right: 16px;
		margin-right: 16px;
	}
	.usercard-item{
		font-weight: 400;
		font-size: 16px;
	}
	.usercard-item:hover{
		font-weight: 500;
		color: #0EB6FF;
		cursor:pointer;
	}
	.usercard-acttive{
		font-weight: 500;
		color: #0EB6FF;
	}
	.usercard-item + .usercard-item{
		margin-left: 60px;
	}
	.usergoods-item{
		color: #737373;
		font-weight: 400;
		display: flex;
		align-items: center;
		font-size: 16px;
		border-right: 1px solid #EEEEEE;
		padding-right: 24px;
	}
	.usergoods-item img{
		width: 30px;
		height: 30px;
		margin-right: 8px;
	}
	.usergoods-item + .usergoods-item{
		margin-left: 24px;
	}
	.usergoods-item:last-child{
		border: none;
		padding-right: 0;
	}
	.cardstatistics{
		border-top: 1px dashed #E5E5E5;
		margin-top: 24px;
		padding-top: 24px;
	}
</style>