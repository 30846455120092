<template>
	<div>
		<div class="text-center ft16 mt100">
			<div class="text-left" style="width:500px;margin:0 auto">
				<div class="flex alcenter">
					<div class="real-title sqschyu">手机号</div>
					<el-input  placeholder="在此输入您的手机号" disabled v-model="userInfo.phone" />
				</div>
				<div class="flex alcenter mt16 sqschyu">
					<div class="real-title">新登录密码</div>
					<el-input  placeholder="在此输入您的新密码" type="password" show-password v-model="pwd" />
				</div>
				<div class="flex alcenter mt16 sqschyu">
					<div class="real-title">&nbsp;</div>
					<div class="c_FF7544" style="width: 316px;">*由字母加数字或符号至少两种以上字符组成的6-20位半角字符，区分大小写</div>
				</div>
				<div class="flex alcenter mt16 sqschyu">
					<div class="real-title">确认新密码</div>
					<el-input  placeholder="在此输入您的新密码"  type="password" show-password v-model="new_pwd" />
				</div>
				<div class="flex alcenter mt16 sqschyu">
					<div class="real-title">验证码</div>
					<el-input  placeholder="在此输入您收到的验证码"   v-model="code" >
						<template #suffix>
							<el-link :underline="false"  type="primary" @click="getCode" :disabled="time!=0">{{time===0?'发送验证码':time +'s后重新获取'}}</el-link>
						</template>
					</el-input>
					<!-- <el-button color="#0EB6FF" class="code-button ml16" @click="getCode" :disabled="time!=0">{{time===0?'验证码':time +'s后重新获取'}}</el-button> -->
				</div>
				<div class="flex alcenter mt16">
					<div class="real-title">&nbsp;</div>
					<el-button color="#0EB6FF" class="real-button" @click="upPassword">修改密码</el-button>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import { ElMessage, ElMessageBox } from 'element-plus'
	export default{
		data(){
			return{
				userInfo:{},
				pwd:"",
				new_pwd:"",
				code:"",
				time:0,
				interval:null,
			}
		},
		methods:{
			closeDialog(){
				this.code = ""
				this.pwd = ""
				this.new_pwd = ""
				this.time = 0
				this.interval = null
			},
			upPassword(){
				if(!this.code){
					ElMessage({
						message: '请输入验证码',
						type: 'warning',
					})
					return
				}
				if(!this.pwd){
					ElMessage({
						message: '请输入新密码',
						type: 'warning',
					})
					return
				}
				if(this.pwd != this.new_pwd){
					ElMessage({
						message: '两次密码输入不一致',
						type: 'warning',
					})
					return
				}
				this.$api.upPassword({
					code:this.code,
					pass:this.pwd,
					newpass:this.new_pwd
				})
				.then(result => {
					if(result.code === 1){
						ElMessage({
							message: result.msg,
							type: 'success',
						})
						this.getUserInfo()
						this.closeDialog()
					}else{
						ElMessage({
							message: result.msg,
							type: 'error',
						})
					}
				})
			},
			getCode(){
				this.$api.newPhoneMessage({
					mobile:this.userInfo.phone
				})
					.then(result => {
						if(result.code === 1){
							ElMessage({
								message: result.msg,
								type: 'success',
							})
							this.time = 60 ;
							this.interval = setInterval(() => {
								if(this.time == 0){
									clearInterval(this.interval)
									return
								}
								this.time --
							},1000)
						}else{
							ElMessage({
								message: result.msg,
								type: 'error',
							})
						}
					})
			},
			getData(item){
				this.userInfo = item
			},
			getUserInfo(){
				this.$emit("getUserInfo")
			},
		}
	}
</script>

<style scoped>
	.real-title{
		width:150px;
	}
	.el-input{
		border-radius: 8px;
		height: 52px;
		box-show:none;
		width: 316px;
	}
	.wfasftry{
		width: 206px;
	}
	.real-button {
		border-radius: 8px;
		height: 60px;
		color:#fff!important;
		width: 316px;
	}
	.code-button{
		border-radius: 8px;
		width: 94px;
		height: 52px;
		color:#fff!important;
	}
</style>