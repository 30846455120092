<template>
	<div class="login-bottomwew">
		<loginHeader></loginHeader>
		<div class="login-main mt40">
			<div class="flex alend text-left" style="width: 100%;">
				<div class="goLerfy" @click="$router.back()">
					<img src="../../assets/user/left.png" class="wefjtuyj-img" />
					<span class="ft20 font-weight500 ml24">返回上级</span>
				</div>
				<div class="ft40 font-weight600 c_333333">问题反馈</div>
			</div>
			<div class="mt16 c_737373 text-center wdscvfy">
				如果您在使用本平台过程中遇到问题Bug，或者有更好的意见建议，欢迎留言，感谢您对团团回收的支持
				此反馈不会马上回复，如果您遇到卡券处理问题，请联系右下角在线客服，以免耽误您的卡券处理进度。
			</div>
			<div class="wqfdshtr">
				<el-form
					ref="formRef"
					:model="numberValidateForm"
					class="demo-ruleForm"
				>
					<el-form-item
						prop="phone"
						:rules="[
							{ required: true, message: '请输入手机号' },
						]"
					>
						<el-input
							type="text"
							v-model="numberValidateForm.phone"
							placeholder="请留下您的联系方式"
							autocomplete="off"
						>
						</el-input>
					</el-form-item>
					<el-form-item
						prop="content"
						:rules="[
							{ required: true, message: '请输入反馈内容' },
						]"
					>
						<el-input
							type="textarea"
							v-model="numberValidateForm.content"
							placeholder="反馈内容"
							autocomplete="off"
							:rows="7"
						>
						</el-input>
					</el-form-item>
					<el-form-item>
						<el-button class="login-button" color="#0EB6FF" @click="submitForm('formRef')">提交</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
		<loginFooter style="position: absolute;bottom: 0;width: 100%;"></loginFooter>
	</div>
</template>

<script>
	import loginHeader from '../../components/header/login.vue'
	import loginFooter from '../../components/footer/user.vue'
	import { ElMessage, ElMessageBox } from 'element-plus'
	export default{
		data(){
			return{
				numberValidateForm:{
					phone: "",
					content: "",
					images:""
				},
			}
		},
		components:{
			loginHeader,
			loginFooter
		},
		created() {
			
		},
		mounted() {
		  document.querySelector('body').setAttribute('style', 'background-color:#fff')
		},
		beforeDestroy() {
			document.querySelector('body').removeAttribute('style')
		},
		methods:{
			submitForm(formName){
				this.$refs[formName].validate((valid) => {
				   if (valid) {
						this.handProblem()
				   } else {
				    return false;
				   }
				 }); 
			},
			handProblem(){
				this.$api.submitFeedback(this.numberValidateForm)
				.then(result => {
					if(result.code == 0){
						ElMessage({
							message: result.msg,
							type: 'error',
						})
					}else{
						ElMessage({
							message: '提交意见反馈成功',
							type: 'success',
						})
						setTimeout(() => {
							this.$refs['formRef'].resetFields();
						}, 1000)
					}
				})
			}
		}
	}
</script>

<style scoped lang="less">
	.login-main{
		width: 1440px;
		margin: 0 auto;
		margin-top: 80px;
		padding-bottom: 400px;
	}
	.mt80{
		margin-top: 80px;
	}
	.login-right{
		width: 515px;
		height: 600px;
		margin-left: 147px;
	}
	.login-left{
		width: 478px;
	}
	/deep/.el-tabs__nav{
		width: 100%;
	}
	/deep/.el-tabs__item{
		flex: 1;
		font-size: 24px;
		font-weight: 600;
	}
	/deep/.el-tabs__active-bar{
		height: 4px;
	}
	/deep/.el-tabs{
		--el-tabs-header-height: 70px;
	}
	/deep/.el-input{
				height: 52px;
	}
	/deep/.el-form-item__content{

	}
	.qwfregdfhg{
		width: 250px!important;
	}
	.wqfdshtr{
		width: 585px;
		margin: 0 auto;
		margin-top:80px;
	}
	/deep/.el-form-item__error{
		padding-top: 10px;
		font-size: 14px;
	}
	/deep/.el-form-item{
		width: 382px;
		margin: 0 auto;
		font-size: 16px;
		margin-bottom: 28px;
	}
	/deep/.el-input__wrapper{
		border-radius: 8px;
	}
	.login-button{
		width: 382px;
		height: 52px;
		color: #fff!important;
		margin: 0 auto;
		margin-top: 40px;
		border-radius: 8px;
	}
	.freyhgf-ftg{
		border-top: 1px dotted #E5E5E5;
		border-bottom: 1px dotted #E5E5E5;
		padding: 16px 0;
	}
	.feyggs{
		text-align: center;
		border-right: 1px solid #EDEDED;
	}
	.feyggs:last-child{
		border: none;
	}
	.wefjtuyj-img{
		width: 6px;
		height: 12px;
	}
	.goLerfy{
		flex: 0.5;
		cursor:pointer;
	}
	.getds-code{
		width: 113px;
		height: 52px;
		color: #0EB6FF;
		border-radius: 8px;
		margin-left: 16px;
	}
	.ft16{
		font-size: 16px!important;
	}
	.qfgd4etr{
		margin-left: 60px;
	}
	.wdscvfy{
		width:650px;
		margin:0 auto;
		margin-top:16px;
	}
	/deep/.problem-cfqw textarea{
		min-height: 146px;
	}
</style>