<template>
	<div class="bg_fff html" >
		<cardHeader :type="'firm'"></cardHeader>
		<div style="min-height: 100vh;">
			<div class="firm-top-bg">
				<div class="firm-top-title">
					<div class="wqdff">团团收专业卡券回收平台</div>
					<div class="terwt">专注于礼品卡的即时处理服务。我们实现礼品卡的自动、快速回收，致力于为用户提供无与伦比的体验。</div>
				</div>
			</div>
			<el-row :gutter="20">
				<el-col :span="20" :offset="2" >
					<div class=" text-center">
						<div class="firm-title">
							企业回收优势
						</div>
						<div class="c_b3b3b3 ft20">专业回收，值得信赖</div>
						<div class="mt40 flex alcenter">
							<div v-for="(item,index) in firmList" :key="index" class="flex-one ">
								<img :src="item.img" class="firm-img" />
								<div class="firm-ss-title">{{item.title}}</div>
								<div class="firm-ss-content">{{item.content}}</div>
							</div>
						</div>
					</div>
				</el-col>

			</el-row>
			<div style="margin-bottom: 60px;"></div>
			<userBottom></userBottom>
		</div>


		
	</div>
</template>

<script>
	import cardHeader from '../../components/header/card.vue'
	import userBottom from '../../components/footer/user.vue'
	export default{
		components:{
			cardHeader,
			userBottom
		},
		data(){
			return{
				firmList:[
					{
						img:require('@/assets/firm/firm1.png'),
						title:"一对一的服务",
						content:"我们出色的服务团队，确保您的交易安全，让您满意"
					},
					{
						img:require('@/assets/firm/firm2.png'),
						title:"交易方式多样",
						content:"我们提供线上、线下、API接口，为您定制交易方式"
					},
					{
						img:require('@/assets/firm/firm3.png'),
						title:"种类丰富，卡券齐全",
						content:"提供回收类型覆盖线上线下，等几十种礼品卡卡券"
					},
					{
						img:require('@/assets/firm/firm4.png'),
						title:"极速资金回流",
						content:"我们在验卡成功后立即打款，资金回流速度超乎想象"
					}
				]
			}
		}
	}
</script>

<style lang="less" scoped>
	page{
		background-color: #fff!important;
		
	}
	.firm-top-bg{
		background-image: url('@/assets/firm/bg.png');
		background-size: 100% 100%;
		height: 350px;
		position: relative;
		color: #fff;
		margin-bottom: 60px;
	}
	.firm-top-title{
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 512px;
		text-align: center;
		height: 100px;
	}
	.firm-top-title .wqdff{
		font-weight: bold;
		font-size: 40px;
	}
	.firm-top-title .terwt{
		font-weight: 400;
		font-size: 16px;
		margin-top: 12px;
		opacity: 0.8;
	}
	.firm-title{
		font-weight: bold;
		font-size: 40px;
		color: #333333;
	}
	.firm-img{
		width: 52px;
		height: 52px;
		transition: .5s;
	}
	.firm-ss-title{
		font-size: 20px;
		color: #333333;
		font-weight: 600;
		margin-top: 25px;
	}
	.firm-ss-content{
		font-weight: 400;
		font-size: 16px;
		color: #B3B3B3;
		width: 226px;
		margin: 0 auto;
		margin-top: 16px;
	}
	.firm-img:hover{ -webkit-transform: rotate(180deg); transform: rotate(180deg)};
</style>