<template>
	<div>
		<div class="eshop-top-bg">
			<el-row :gutter="20" class="wdsffdzcdtg">
				<el-col :span="18" :offset="3">
					<div class="eshop-top-bg-item">
						<img src="../../assets/card/logo.png"  />
						<div class="flex alcenter">
								<el-select
									v-model="searchValue"
									filterable
									remote
									reserve-keyword
									placeholder="输入您想找、想出售的东西"
									:remote-method="remoteMethod"
									:loading="loading"
									class="search-input"
								>
									<el-option
										v-for="item in options"
										:key="item.id"
										:label="item.goods_name"
										:value="item.id"
									/>
								</el-select>
							<el-button color="#FF4D4D"  class="wedazx-button" @click="search()">
									<el-icon><Search /></el-icon>
							</el-button>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
	import { ElMessage, ElMessageBox } from 'element-plus'
	export default{
		props:{
			type: {
				type:String,
				default: 'rest',
			}
		},
		data(){
			return{
				searchValue:"",
				
				
				loading:false,
				options:[]
			}
		},
		methods:{
			remoteMethod(query){
				if(query){
					this.loading = true
					this.$api.searchGoods({
						goods_name: query
					})
					.then(result => {
						this.options = result.data
						this.data = this.options
						this.loading = false
					})
				}else{
					this.options = []
				}
			},
			search(){
				if(this.searchValue){
					let dict = []
					for(let i=0;i<this.data.length;i++){
						if(this.data[i].id === this.searchValue){
							dict = this.data[i]
						}
					}
					if(this.type === 'card'){
						this.$emit('handleSearch',{id:dict.id,cate_id:dict.cate_id})
					}else{
						this.$router.push({
							path:"/card/huishou",
							query:{id:dict.id,cate_id:dict.cate_id}
						})
					}
				}else{
					ElMessage({
					  message: "请输入正确的搜索内容",
					  type: "warning",
					});
				}
			}
		}
	}
</script>

<style scoped lang="less">
	.eshop-top-bg{
		background-image: url('../../assets/card/top-bg.png');
		width: 100%;
		padding: 50px 0;
	}
	.eshop-top-bg-item{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.qfdsqw-input{
		width: 472px;
		height: 40px;
	}
	.wedazx-button{
		background: linear-gradient(180deg, #FF4D4D 0%, #FF9052 100%);
		border-radius: 8px;
		color:#fff!important;
		margin-left: 12px;
		min-width: 64px;
		height: 40px;
	}
	.search-input{
		width: 472px;
		/deep/ .el-input__wrapper{
			height: 40px;
		}
	}
</style>