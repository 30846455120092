<template>
	<div >
		<!-- <div style="width: 100%;height: 276px;"></div> -->
			<el-row :gutter="20" class="wdsffdzcdtg">
				<el-col :span="18" :offset="3">
					<div class="bottom-wda">
						<div class="alcenter flex-between">
							<div>
								<div class="c_fff ft40">{{getContactUs?getContactUs.mobile:""}}</div>
								<div class="c_b3b3b3">客服热线（周一至周日  早上08:30-晚上23:00）</div>
								<div class="flex alcenter">
									<a href="javascript:void(0);" @click="openUrl(item)"  class="optyionsf-item" v-for="(item,index) in optio" :key="index">
										{{item.name}}
									</a>
								</div>
							</div>
							<div>
								<img :src="systemsettings && systemsettings.wxmp_img?systemsettings.wxmp_img.file_path:''" class="kyfimg" />
								<p class="ft14 c_b3b3b3 mt10">关注微信公众号</p>
							</div>
						</div>
						<div class="icprecord">
							<div>
								<a href="https://beian.miit.gov.cn/#/Integrated/index" class="mr10">工信部备案编号：{{systemsettings?systemsettings.reg_no:''}} </a>
								<a :href="systemsettings.reg_icp_no" v-if="systemsettings && systemsettings.reg_icp_no"  class="mr10">电信增值业务许可证：{{systemsettings.reg_icp_no}}  </a>
								<a :href="systemsettings.pub_reg_no_url" v-if="systemsettings &&  systemsettings.pub_reg_no"><img src="https://www.xianzhuanxia.com/_nuxt/img/wbimg.0c57706.png" />{{systemsettings.pub_reg_no}}</a>
							</div>
							<div>
								<a :href="systemsettings.lawyerurl" v-if="systemsettings && systemsettings.lawyer"  class="mr10">法律顾问：{{systemsettings.lawyer}}  </a>
							</div>
							<div>
								<span >{{systemsettings &&  systemsettings.copyright?systemsettings.copyright:''}}</span>
							</div>
						</div>
					</div>
				</el-col>
			</el-row>
	</div>
</template>

<script>
	import config from '../../config.js'
	export default{
		data(){
			return{
				baseUrl:config.baseUrl,
				getContactUs:JSON.parse(localStorage.getItem("getContactUs")),
				systemsettings:JSON.parse(localStorage.getItem("systemsettings")),
				optio:[
					{
						id:1,
						name:'企业回收',
						url: "/firm/index"
					},
					{
						id:2,
						name:'常见问题',
						url:"/notice/question"
					},
					{
						id:4,
						name:'建议反馈',
						url:"/problem"
					}
				]
			}
		},
		created() {
			this.getContactUs = JSON.parse(localStorage.getItem("getContactUs"))
			this.systemsettings = JSON.parse(localStorage.getItem("systemsettings"))
			if(!this.systemsettings){
				this.systemsettingsLwwf()
			}
		},
		methods:{
			systemsettingsLwwf(){
				this.$api.systemsettings()
				.then(result => {
					this.systemsettings = result
					localStorage.setItem("systemsettings",JSON.stringify(result));
				})
			},
			openUrl(item){
				this.$router.push({
					path:item.url
				})
			}
		}
	}
</script>

<style scoped>
	.bottom-wda{
/* 		width: 1440px;
		margin: 0 auto; */
		/* border-top: 1px solid #525354; */
		/* padding-top: 40px; */
		margin-top: 44px;
		padding-bottom: 40px;
	}
	.optyionsf-item{
		color: #737373;
		/* e6e6e6 */
		border-right: 1px solid #525354;
		padding-right: 14px;
		margin-top: 28px;
	}
	.optyionsf-item + .optyionsf-item{
		margin-left: 14px;
	}
	.optyionsf-item:last-child{
		border: none;
		padding-right: 0;
	}
	.kyfimg{
		width: 108px;
		height: 108px;
	}
	.icprecord{
		border-top: 1px solid #525354;
		padding-top: 12px;
		margin-top: 40px;
		font-size: 14px;
		color: #B3B3B3;
	}
	.icprecord div+div{
		margin-top: 10px;
	}
	.icprecord a{
		color: #B3B3B3;
	}
	.icprecord span+span{
		margin-left: 54px;
	}
	.wdsffdzcdtg{
		background-color: #000;
		/* margin-top:20px; */
	}
</style>