import axios from 'axios'
import router from "../router"
import QS from 'qs'; // 引入qs模块，用来序列化post类型的数据，后面会提到
import { ElMessage, ElMessageBox } from 'element-plus'
import config from '../config.js'
axios.defaults.baseURL = config.apiUrl
axios.defaults.timeout = 1000 * 10;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
// axios.defaults.headers['Access-Token'] = ;
// 请求拦截器
axios.interceptors.request.use(
    config => {
        // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
        const token = localStorage.getItem("token");
        token && (config.headers['Access-Token'] = token);
        return config;
    },
    error => {
        return Promise.error(error);
    })

// 响应拦截器
axios.interceptors.response.use(
    response => {
        if (response.status === 200) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    // 服务器状态码不是200的情况
    error => {
        if (error.response.status) {
            switch (error.response.status) {
                // 401: 未登录
                // 未登录则跳转登录页面，并携带当前页面的路径
                // 在登录成功后返回当前页面，这一步需要在登录页操作。
                case 401:
										// 清除token
										localStorage.removeItem('token');
										ElMessage({
											message: error.response.data.msg,
											type: 'error',
										})
										router.push({path:'/login'})
										setTimeout(() => {
											window.location.reload()
										}, 300)
                    break;
                // 403 token过期
                // 登录过期对用户进行提示
                // 清除本地token和清空vuex中token对象
                // 跳转登录页面
                case 403:
										err.message = '登录过期请重新登录'
                    // 清除token
                    localStorage.removeItem('token');

                    // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
                    break;
                // 404请求不存在
                case 404:
								err.message = '网络请求不存在'
                break;
                // 其他错误，直接抛出错误提示
                default:
								err.message = error.response.data.message
            }
            return Promise.reject(error.response);
        }
    }
);

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params){
    return new Promise((resolve, reject) =>{
        axios.get(url, {
            params: params
        }).then(res => {
            resolve(res.data);
        }).catch(err =>{
            reject(err.data)
    })
});}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
    return new Promise((resolve, reject) => {
         axios.post(url, QS.stringify(params))
        .then(res => {
            resolve(res.data);
        })
        .catch(err =>{
            reject(err.data)
        })
    });
}
