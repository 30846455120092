<template>
	<div class="user-bg-swg html-two">
			<uerHeader class="bg_fff" type=""></uerHeader>
			<div class="">
				<el-row :gutter="24" class=" wdsffdzcdtg mt40">
					<el-col :span="4" :offset="2">
						<userLeft></userLeft>
					</el-col>
					<el-col :span="16" >
						<div class="m-content fix">
							<div class="user-main" >
								<div class="  " v-loading="loading">
									<div class="">
										<div class="withdraw-type pd24 bg_fff">
											<router-link   :to="{path:'/user/withdraw'}">提现</router-link>
											<router-link class="active"  :to="{path:'/user/withdraw_record'}">提现记录</router-link>
										</div>
										<div class="flex 	 mt24"  >
											<el-input v-model="serch" placeholder="输入提现账号" class="order-input mr16"  :prefix-icon="Search" @keyup.enter="getData()">
												<template #prepend>
													<el-icon><Search /></el-icon>
												</template>
											</el-input>
											<div class="order-input mr32">
												<el-date-picker
													v-model="searchData"
													type="daterange"
													class="order-input"
													range-separator="到"
													start-placeholder="开始日期"
													end-placeholder="结束日期"
													format="YYYY-MM-DD"
													value-format="YYYY-MM-DD"
												/>
											</div>
											<el-button @click="getData" class="wduqsc">搜索</el-button>
										</div>
										<div class="mt24 order-screen bg_fff rounded " >
											<div class="flex alcenter  qwdxzvdfrty  order-goods">
												<a class="wdszxty " href="javascript:;" :class="{'card-type-active':deliveryType === -1}" @click="orderType(-1)">全部</a>
												<a href="javascript:;" v-for="(item,index) in options" class="wdszxty" :key="index" :class="{'card-type-active':deliveryType === item.value}" @click="orderType(item)">
													{{item.label}}
												</a>
											</div>
											<div class="flex alcenter mt16 qwdxzvdfrty">
												<a class="wdszxty " href="javascript:;" :class="{'card-type-active':kaleiActive1 === -1}" @click="kaleiActive1 = -1;start_time = '';end_time = '';getData()">全部</a>
												<a href="javascript:;" v-for="(item,index) in dataType" class="wdszxty" :key="index" :class="{'card-type-active':kaleiActive1 === index}" @click="kaleibtn1(index)">
													{{item.name}}
												</a>
											</div>
										</div>
										<div class="mt24  order-screen bg_fff rounded">
											<el-table :data="tableData" stripe style="width: 100%" v-if="tableData.length > 0"
												:cell-style="{ textAlign: 'center' ,height:'64px','color':'#737373','font-size':'16px'}"
												:header-cell-style="{ 'text-align': 'center','color':'#333333','font-size':'18px'}"
														 >
												 <el-table-column
													 prop="id"
													 label="提现单号"
													 width="150"
													>
												 </el-table-column>
												<el-table-column
													 prop="create_time"
													 label="提现时间"
													 width="200"
												 >
												</el-table-column>
												<el-table-column prop="account" label="提现方式/收款账号" width="200">
													<template #default="scope,index">
														<p >{{scope.row.pay_type == 1 ? '支付宝 ' : scope.row.pay_type == 2 ? '银行卡 ' : '微信'}}</p>
														<div>{{scope.row.account}}</div>
													</template>
												</el-table-column>
												<el-table-column prop="money" label="提现金额/到账金额" min-width="200" >
													<template #default="scope,index">
														<div class="c_FF7544">{{scope.row.money}}</div>
														<div class="c_43DB8E">{{scope.row.real_price}}</div>
													</template>
												</el-table-column>
												<el-table-column prop="service_price" label="手续费" min-width="80">
													<template #default="scope,index">
														<span class="c_FF5BA0">{{scope.row.service_price}}</span>
													</template>
												</el-table-column>
												<el-table-column prop="pay_type" label="提现状态" min-width="150">
													<template #default="scope,index">
														<p :style="{'color':deliveryList[scope.row.apply_status].class}">{{deliveryList[scope.row.apply_status].name}}</p>
													</template>
												</el-table-column>
												<el-table-column prop="reject_reason" label="备注" min-width="200" >
												</el-table-column>
											</el-table>
											<el-empty description="还没有内容" v-else />
											<div class="flex-center mt24 pb25 bottom-pagin">
												<el-pagination
													v-model:current-page="currentPage"
													:background="true"
													layout="prev, pager, next, jumper"
													:total="total"
													@current-change="handleCurrentChange"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
		<userBottom></userBottom>
	</div>
</template>

<script>
	import uerHeader from '../../components/header/card.vue'
	import userLeft from '../../components/left/index.vue'
	import userBottom from '../../components/footer/user.vue'
	export default{
		data(){
			return{
				currentPage:1,
				total:100,
				tableData:[],
				deliveryList:{
					'10': {name:'待审核',class:"#56AAE8"},
					"20": {name:'审核通过',class:"#3ED77B"},
					"30": {name:'驳回',class:"#EA4949"},
					"40": {name:'已打款',class:"#3ED77B"},
				},
				options: [{
					value: 2,
					label: '待审核'
				}, {
					value: 3,
					label: '处理中'
				}, {
					value: 4,
					label: '完成'
				}],
				deliveryType:-1,
				dataType:[
					{
						name:'今天',
						id:1
					},
					{
						name:'近七天',
						id:2
					},
					{
						name:'一个月',
						id:3
					},
					{
						name:'三个月',
						id:4
					}
				],
				loading:false,
				start_time:"",
				end_time:"",
				kaleiActive1:-1,
				serch:"",
				searchData:[]
			}
		},
		components:{
			uerHeader,
			userLeft,
			userBottom
		},
		created() {
			this.getData()
		},
		methods:{
			fun_date(aa) {
				var date1 = new Date(),
				time1 = date1.getFullYear() + "-" + (date1.getMonth() + 1) + "-" + date1.getDate(); //time1表示当前时间
				var date2 = new Date(date1);
				date2.setDate(date1.getDate() + aa + 1);
				let mm = date2.getMonth() + 1
				let dd = date2.getDate()
				if (mm < 10) {
					mm = String(mm)
					mm = '0' + mm
				}
				if (dd < 10) {
					dd = String(dd)
					dd = '0' + dd
				}
				var time2 = date2.getFullYear() + "-" + mm + "-" + dd;
				return time2
			},
			kaleibtn1(index) {
				this.kaleiActive1 = index
				let times = this.dataType[index]
				let yy = new Date().getFullYear();
				let mm = new Date().getMonth() + 1;
				let dd = new Date().getDate();
				if (mm < 10) {
					mm = String(mm)
					mm = '0' + mm
				}
				if (dd < 10) {
					dd = String(dd)
					dd = '0' + dd
				}
				if (times.id === 1) {
					this.start_time = yy + '-' + mm + '-' + dd
					this.end_time = yy + '-' + mm + '-' + dd
				} else if (times.id === 2) {
					this.start_time = yy + '-' + mm + '-' + dd
					this.end_time = this.fun_date(-7)
				} else if (times.id === 3) {
					this.start_time = yy + '-' + mm + '-' + dd
					this.end_time = this.fun_date(-30)
				} else if (times.id === 4) {
					this.start_time = yy + '-' + mm + '-' + dd
					this.end_time = this.fun_date(-90)
				}
				this.searchData = [this.start_time,this.end_time]
				this.getData()
			},
			orderType(item){
				this.deliveryType = item.value
				this.getData()
			},
			getData(){
				this.loading = true
				this.$api.WithdrawList({
					page:this.currentPage,
					type:this.deliveryType,
					seachtime:this.searchData.length > 1 ? this.searchData[0] + ' 00:00:00' : '',
					endtime:this.searchData.length > 1 ? this.searchData[1] + ' 23:59:59':'',
					account:this.serch
				})
				.then(result => {
					this.currentPage = result.data.current_page
					this.total = result.data.total
					this.tableData = result.data.data
					this.loading = false
				})
			},
			handleCurrentChange(e){
				this.currentPage = e
				this.getData()
			}
		}
	}
</script>

<style lang="less" scoped>
	a{
		 text-decoration:none;
	}
	.order-input{
		width: 340px;
		border-radius: 8px;
		height: 42px;
		color: #B3B3B3;
	}
	.wduqsc{
		height: 42px;
		width: 80px;
	}
	/deep/.el-range-editor.el-input__wrapper{
		width: 340px;
		border-radius: 4px;
		height: 42px;
		color: #B3B3B3;
	}
	/deep/ .order-input .el-input-group__prepend{
		background-color: #fff;
		box-shadow: none;
	}
	/deep/ .order-input .el-input-group__append{
		background-color: #fff;
		box-shadow: none;
		border-left:1px solid #EEEEEE;
		color: #B3B3B3;
	}
	/deep/ .order-input .el-input__wrapper{
		box-shadow: none;
	}
	.order-serch-button button{
		height: 42px;
		border-radius: 8px;
		color: #333333;
		border: none;
	}
	/deep/ .real-inputd .el-input__wrapper{
		 background: #F7F9FC;
		 border-radius: 8px;
		 color: #B3B3B3;
		 height: 60px;
		 border-color: #F7F9FC;
	}
	.wdszxty{
		font-size: 16px;
	}
	.wdszxty + .wdszxty{
		margin-left: 60px;
	}
	.card-type-active{
		color: #0EB6FF!important;
		font-weight: 600;
	}
		.qwdxzvdfrty a{
			color: #333333;
		}
		.qwdxzvdfrty .active{
			
		}
		.qwdxzvdfrty a:hover{
			color: #79bbff;
		}
		.order-screen{
			padding: 15px 26px 24px 26px;
		}
		.order-goods{
			padding-bottom: 24px;
			border-bottom: 1px dashed #E5E5E5;
		}
</style>