<template>
	<div>
		<div class="text-center ft16 mt100">
			<div class="text-left" style="width:500px;margin:0 auto">
				<div class="flex alcenter">
					<div class="real-title sqschyu">邮箱地址</div>
					<el-input  placeholder="在此输入您的邮箱" v-model="email" />
				</div>
				<div class="flex alcenter mt16 sqschyu">
					<div class="real-title">邮箱验证码</div>
					<el-input  placeholder="在此输入您的验证码"  class="" v-model="code" >
						<template #suffix>
							<el-link :underline="false"  type="primary" @click="getCode" :disabled="time!=0">{{time===0?'发送验证码':time +'s后重新获取'}}</el-link>
						</template>
					</el-input>
					<!-- <el-button color="#0EB6FF" class="code-button ml16" @click="getCode" :disabled="time!=0">{{time===0?'验证码':time +'s后重新获取'}}</el-button> -->
				</div>
				<div class="flex alcenter mt16 sqschyu">
					<div class="real-title">&nbsp;</div>
					<div class="c_b3b3b3" style="width: 316px;">*邮箱验证码 5 分钟内有效，若已失效或未收到验证码，请点击重新获取</div>
				</div>
				<div class="flex alcenter mt16">
					<div class="real-title">&nbsp;</div>
					<el-button color="#0EB6FF" class="real-button">确认提交</el-button>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import { ElMessage, ElMessageBox } from 'element-plus'
	export default{
		data(){
			return{
				email:"",
				code:"",
				time:0
			}
		},
		methods:{
			getCode(){
				var eamiltest = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
				if(!eamiltest.test(this.email)){
					ElMessage({
						message: '请输入正确的邮箱',
						type: 'warning',
					})
					return
				}
				this.$api.getEamilCode({
					email:this.email
				})
				.then(result => {
					if(result.code === 1){
						ElMessage({
							message: '发送成功',
							type: 'success',
						})
					}else{
						ElMessage({
							message: result.msg,
							type: 'error',
						})
					}
				})
			}
		}
	}
</script>

<style scoped>
	.real-title{
		width:150px;
	}
	.el-input{
		border-radius: 8px;
		height: 52px;
		box-show:none;
		width: 316px;
	}
	.wfasftry{
		width: 206px;
	}
	.real-button {
		border-radius: 8px;
		height: 60px;
		color:#fff!important;
		width: 316px;
	}
	.code-button{
		border-radius: 8px;
		width: 94px;
		height: 52px;
		color:#fff!important;
	}
</style>