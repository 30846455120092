<template>
	<div>
		<div class="text-center ft16 ">
			<el-tabs v-model="activeName" type="border-card" @tab-click="handleType">
				<el-tab-pane :label="item.name" v-for="(item,index) in tabs" :key="index" :name="item.value">
					<div class="text-left" style="width:500px;margin:0 auto" v-loading="loading">
						<div class=" qwrec c_737373 " v-for="(item,index) in withdrawList" :key="index" :class="item.type === 1?'account-have-item':item.type === 2?'account-have-item-bank':'account-have-item-wx'">
							<div class=" flex alcenter  qwtf flex-between" :class="item.type === 1?'account-show':item.type === 2?'account-show-bank':'account-show-wx'">
								<div class="flex alcenter">
									<img :src="item.type === 1 ? require('../../../assets/user/withdraw/alipay.png') : item.type === 2?require('../../../assets/user/withdraw/ubank.png'):require('../../../assets/user/withdraw/wx.png')" class="alipay-img mr16" />
									<div class="c_333333 font-weight500">{{item.type === 1?'支付宝':item.type === 2?item.bank_name:"微信"}}</div>
								</div>
								<div class="c_b3b3b3">{{item.type === 1?item.account:item.type === 2?item.account:item.nickName}}</div>
							</div>
							<div class="text-right mt19">
								<a href="javascript:;" class="mr49 c_737373" v-if="activeName != 3" @click="updaeAccount(item)">编辑</a>
								<a href="javascript:;" class="mr12 c_737373" @click="delAccount(item)">删除</a>
							</div>
						</div>
						<div class="account-item c_737373 mt24" @click="oepnWx()" v-if="activeName === 3">
							<span class="icon-jiahao iconfont mr16"></span>
							<span>添加微信</span>
						</div>
						<div class="account-item c_737373 mt24" @click="$refs.poppup.openAlipay()" v-if="activeName === 1">
							<span class="icon-jiahao iconfont mr16"></span>
							<span>添加支付宝</span>
						</div>
						<div class="account-item c_737373 mt24" @click="$refs.poppup.openBank()" v-if="activeName === 2">
							<span class="icon-jiahao iconfont mr16"></span>
							<span>添加银行卡</span>
						</div>
					</div>
				</el-tab-pane>
			</el-tabs>
			<div class="ft18 text-center mt50 w316" v-if="!realInfo.name" style="margin: 0 auto;">
				<p class="c_737373">您还没有进行实名认证，无法添加提现账号</p>
				<br/>
				<a href="javascript:;" class="c_0EB6FF mt13" @click="oepnReal">
					前往认证
					<span class="icon-youjiantou iconfont"></span>
				</a>
			</div>
		</div>
		<withdrawItem ref="poppup" :realInfo='realInfo' @getWithdrawList='getWithdrawList'></withdrawItem>
	</div>
</template>

<script>
	import withdrawItem from './components/withdraw_item/index'
	import { ElMessage, ElMessageBox } from 'element-plus'
	export default{
		data(){
			return{
				realInfo:{},
				tabs: [],
				activeName:null,
				loading:false,
				withdrawList:[]
			}
		},
		components:{
			withdrawItem
		},
		created() {
			this.loadDetail1()
			this.getWithdrawType()
		},
		methods:{
			delAccount(item){
				ElMessageBox.confirm(
					'确认是否删除此账号',
					'温馨提示',
					{
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning',
					}
				)
					.then(() => {
						if(item.type === 1){
							this.delWithdrawAli(item)
						}else if(item.type === 2){
							this.delWithdrawBank(item)
						}else{
							this.delWithdrawWx(item)
						}
					})
			},
			delWithdrawBank(item){
				this.$api.delWithdrawBank({
					id:item.id
				})
				.then(result => {
					this.resultAccount(result)
				})
			},
			delWithdrawWx(item){
				this.$api.delWithdrawWx({
					id:item.id
				})
				.then(result => {
					this.resultAccount(result)
				})
			},
			delWithdrawAli(item){
				this.$api.delWithdrawAli({
					id:item.id
				})
				.then(result => {
					this.resultAccount(result)
				})
			},
			resultAccount(result){
				if(result.code === 1){
					ElMessage({
						message: result.msg,
						type: 'success',
					})
					this.getWithdrawList()
				}else{
					ElMessage({
						message: result.msg,
						type: 'error',
					})
				}
			},
			updaeAccount(item){
				if(item.type === 1){
					this.$refs.poppup.openupdateAlipay(item)
				}else{
					this.$refs.poppup.openupdateBank(item)
				}
			},
			handleType(tab){
				if(tab.props.name!=this.activeName){
					this.activeName = tab.props.name
					this.getWithdrawList()
				}
				
			},
			getWithdrawType(){
				this.$api.getWithdrawType({})
				.then(result => {
					let type_tixian = result.data
					if(type_tixian.aliPay === 1){
							this.tabs.push({"name":"支付宝",'value':1})
					}
					if(type_tixian.wxPay === 1){
						this.tabs.push({"name":"微信",'value':3})
					}
					if(type_tixian.bankPay === 1){
						this.tabs.push({"name":"银行卡",'value':2})
					}
					this.activeName = this.tabs[0].value
					this.getWithdrawList()
				})
			},
			getWithdrawList(){
				this.loading = true
				this.$api.getWithdrawList({
					type:this.activeName
				})
				.then(result => {
					this.loading = false
					this.withdrawList = result.data
					console.log(result);
				})
			},
			oepnReal(){
				this.$emit("handlePage",'2')
			},
			loadDetail1() {
				return new Promise((resolve,reject) => {
					this.$api.getUserRealStatus({
						type:1
					})
					.then(result => {
						this.realInfo = result.data
						resolve(result.data)
					})
				})
			},
			oepnWx(){
				this.$refs.poppup.openWx()
			},
			openBank(){
				this.$refs.popup.openBank()
			}
		}
	}
</script>

<style scoped>
	.user-datumf-sw{
		margin-right: 80px;
	}
	.mr87{
		margin-right:86px
	}
	.w316{
		width: 316px;
	}
	.account-item{
		border: 1px solid #EEEEEE;
		border-radius: 8px;
		width: 316px;
		height: 60px;
		line-height: 60px;
		text-align: center;
		cursor:pointer;
	}
	.account-item:hover{
		color:#0EB6FF;
		border-color: #0EB6FF;
	}
	.account-item + .account-item{
		margin-top: 24px;
	}
	.account-have-item{
		border: 1px solid #EDF3FF;

	}
	.account-show{
		background: #EDF3FF;
	}
	.alipay-img{
		width: 40px;
		height: 40px;
	}
	/deep/ .el-tabs__item{
		padding-top: 20px;
	}
	.qwrec + .qwrec{
		margin-top: 24px;
	}
	.qwtf{
		height:67px;
		padding:0 15px;
	}
	.qwrec{
		border-radius: 8px;
		width: 319px;
		height: 123px;
	}
	.account-show-bank{
		background-color: #14cae2;
	}
	.account-have-item-bank{
		border: 1px solid #14cae2;
	}
	.account-have-item-wx{
		border: 1px solid #3eb136;
	}
	.account-show-wx {
		background-color: #3eb136;
		
	}
	.account-show-wx .c_b3b3b3{
		color: #fff!important;
	}
	.account-show-wx .c_333333{
		color: #fff!important;
	}
	.account-have-item-bank .c_b3b3b3{
		color: #fff!important;
	}
	.account-have-item-bank .c_333333{
		color: #fff!important;
	}
</style>