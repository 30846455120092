<template>
	<div class="">
		<el-dialog v-model="addWxVisible" title="" width='522px' custom-class="pop-radius">
			<div class="text-center">
				<p class="ft24 font-weight700 c_333333">请用微信扫码下方二维码</p>
				<p class="c_737373 ft18 mt20 " style="">请确保实名认证信息与微信信息一直，否则将无法提现</p>
				<img :src="imgWx"  class="mt30 withdawfv" />
			</div>
			<template #footer>
				<span class="dialog-footer">
				</span>
			</template>
		</el-dialog>
		<el-dialog v-model="addAlipayVisible" title="" width='522px' custom-class="pop-radius" @close="clearnData">
			<div class="text-center">
				<p class="ft24 font-weight700 c_333333">添加支付宝账号</p>
				<div class="ft18 c_737373 mt24 prl70">只能添加【{{realInfo.name}}】的支付宝</div>
				<div class="prl70 mt40 ">
					<el-input placeholder="请填写支付宝账号" class="real-inputd" v-model="alipayQuery.number"/>
					<el-button color="#0EB6FF" class="main-button mt20" @click="setWithdrawAli">确认提交</el-button>
				</div>
			</div>
			<template #footer>
				<span class="dialog-footer">
				</span>
			</template>
		</el-dialog>
		<el-dialog v-model="updateAlipayVisible" title="" width='522px' custom-class="pop-radius" @close="clearnData">
			<div class="text-center">
				<p class="ft24 font-weight700 c_333333">修改支付宝</p>
				<div class="ft18 c_737373 mt24 prl70">只能添加【{{realInfo.name}}】的支付宝</div>
				<div class="prl70 mt40 ">
					<el-input placeholder="请填写支付宝账号" class="real-inputd" v-model="alipayQuery.number"/>
					<el-button color="#0EB6FF" class="main-button mt20" @click="upWithdrawAli">确认提交</el-button>
				</div>
			</div>
			<template #footer>
				<span class="dialog-footer">
				</span>
			</template>
		</el-dialog>
		<el-dialog v-model="addBankVisible" title="" width='522px' custom-class="pop-radius" @close="clearnData">
			<div class="text-center">
				<p class="ft24 font-weight700 c_333333">添加银行卡</p>
				<div class="ft18 c_737373 mt24 prl70">只能添加【{{realInfo.name}}】的银行卡</div>
				<div class="prl70 mt40 ">
					<el-radio-group v-model="account_type" class="ml-4" v-if="userInfo.enterprise === 2">
						<el-radio :label="0" size="large">个人</el-radio>
						<el-radio :label="1" size="large">企业</el-radio>
					</el-radio-group>
					<div v-if="account_type == 0">
						<el-input placeholder="请填写银行卡卡号" class="real-inputd" v-model="bankQuery.number" @input="getBankName"/>
						<el-select v-model="bankQuery.bank_name" filterable placeholder="请选择开户行" class="mt24">
							<el-option
								v-for="item in options"
								:key="item"
								:label="item.bank_name"
								:value="item.bank_name"
							/>
						</el-select>
					</div>
					<div v-if="account_type == 1">
						<el-input placeholder="请填写银行卡卡号" class="real-inputd" v-model="bankQuery.number" />
						<el-input placeholder="请填写银行卡预留手机号" class="real-inputd" v-model="bankQuery.phone"/>
						<el-input placeholder="请填写开户银行" class="real-inputd" v-model="bankQuery.bank_name"/>
						<el-input placeholder="请填写开户支行名称" class="real-inputd" v-model="bankQuery.corporatebank"/>
						  <el-tree-select
							 class="mt24"
						    v-model="bankQuery.provinceName"
						    :data="cityInfo"
						    filterable
								placeholder="请选择省市"
						  />
					</div>
					<el-button color="#0EB6FF" class="main-button mt20" @click="setWithdrawBank">确认提交</el-button>
				</div>
			</div>
			<template #footer>
				<span class="dialog-footer">
				</span>
			</template>
		</el-dialog>
		<el-dialog v-model="updateBankVisible" title="" width='522px' custom-class="pop-radius" @close="clearnData">
			<div class="text-center">
				<p class="ft24 font-weight700 c_333333">修改银行卡</p>
				<div class="ft18 c_737373 mt24 prl70">只能添加【{{realInfo.name}}】的银行卡</div>
				<div class="prl70 mt40 ">
					<el-radio-group v-model="account_type" class="ml-4" v-if="userInfo.enterprise === 2">
						<el-radio :label="0" size="large">个人</el-radio>
						<el-radio :label="1" size="large">企业</el-radio>
					</el-radio-group>
					<div v-if="account_type == 0">
						<el-input placeholder="请填写银行卡卡号" class="real-inputd" v-model="bankQuery.number" @input="getBankName"/>
						<el-select v-model="bankQuery.bank_name" filterable placeholder="请选择开户行" class="mt24">
							<el-option
								v-for="item in options"
								:key="item"
								:label="item.bank_name"
								:value="item.bank_name"
							/>
						</el-select>
					</div>
					<div v-if="account_type == 1">
						<el-input placeholder="请填写银行卡卡号" class="real-inputd" v-model="bankQuery.number" />
						<el-input placeholder="请填写银行卡预留手机号" class="real-inputd" v-model="bankQuery.phone"/>
						<el-input placeholder="请填写开户银行" class="real-inputd" v-model="bankQuery.bank_name"/>
						<el-input placeholder="请填写开户支行名称" class="real-inputd" v-model="bankQuery.corporatebank"/>
						  <el-tree-select
							 class="mt24"
						    v-model="bankQuery.provinceName"
						    :data="cityInfo"
						    filterable
								placeholder="请选择省市"
						  />
					</div>
					<el-button color="#0EB6FF" class="main-button mt20" @click="upWithdrawBank">确认提交</el-button>
				</div>
			</div>
			<template #footer>
				<span class="dialog-footer">
				</span>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import { ElMessage, ElMessageBox } from 'element-plus'
	import config from '../../../../../config.js'
	import cityInfo from './cityInfo.js'
	export default{
		data(){
			return{
				addWxVisible:false,
				addAlipayVisible:false,
				updateAlipayVisible:false,
				addBankVisible:false,
				updateBankVisible:false,
				imgWx:"",
				alipayQuery:{
					number:""
				},
				account_type:0,
				bankQuery:{
					number:"",
					bank_name:"",
					phone:"",
					corporatebank:"",
					provinceName:""
				},
				options:[],
				userInfo:{},
				cityInfo:cityInfo.CityInfo,
			}
		},
		props:{
			realInfo:{
				type:Object,
				default:{}
			}
		},
		components:{

		},
		methods:{
			getUserInfowww(){
				return new Promise((resolve,reject) => {
					this.$api.userInfo()
					.then(result => {
						this.loading = false
						if(result.code === 1){
							this.userInfo = result.data
							resolve(result)
							localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
						}
					})
				})
			},
			upWithdrawBank(){
				if(!this.bankQuery.number){
					ElMessage({
						message: '请输入银行卡卡号',
						type: 'warning',
					})
					return
				}
				if(!this.bankQuery.bank_name){
					ElMessage({
						message: '请输入银行卡开户行',
						type: 'warning',
					})
					return
				}
				if(Number(this.account_type) === 1){
					if(!this.bankQuery.phone){
						ElMessage({
							message: '请输入预留手机号',
							type: 'warning',
						})
						return
					}
					if(!this.bankQuery.corporatebank){
						ElMessage({
							message: '请输入开户支行名称',
							type: 'warning',
						})
						return
					}
					if(!this.bankQuery.provinceName){
						ElMessage({
							message: '请选择开户行省市',
							type: 'warning',
						})
						return
					}
				}
				let c = this.getQyBankCity()
				this.$api.upWithdrawBank({
					id:this.bankQuery.id,
					account:this.bankQuery.number,
					bank_name:this.bankQuery.bank_name,
					phone: this.bankQuery.phone,
					corporatebank: this.bankQuery.corporatebank,
					province: Number(this.account_type) === 1?c.provinceName:'',
					city: Number(this.account_type) === 1?c.cityName:'',
					account_type:this.account_type
				})
				.then(result => {
					this.resultAli(result)
				})
			},
			setWithdrawBank(){
				if(!this.bankQuery.number){
					ElMessage({
						message: '请输入银行卡卡号',
						type: 'warning',
					})
					return
				}
				if(!this.bankQuery.bank_name){
					ElMessage({
						message: '请输入银行卡开户行',
						type: 'warning',
					})
					return
				}
				if(this.account_type === 1){
					if(!this.bankQuery.phone){
						ElMessage({
							message: '请输入预留手机号',
							type: 'warning',
						})
						return
					}
					if(!this.bankQuery.corporatebank){
						ElMessage({
							message: '请输入开户支行名称',
							type: 'warning',
						})
						return
					}
					if(!this.bankQuery.provinceName){
						ElMessage({
							message: '请选择开户行省市',
							type: 'warning',
						})
						return
					}
				}
				let c = this.getQyBankCity()
				this.$api.setWithdrawBank({
					account:this.bankQuery.number,
					bank_name:this.bankQuery.bank_name,
					phone: this.bankQuery.phone,
					corporatebank: this.bankQuery.corporatebank,
					province: this.account_type === 1?c.provinceName:'',
					city: this.account_type === 1?c.cityName:'',
					account_type:this.account_type
				})
				.then(result => {
					this.resultAli(result)
				})
			},
			getQyBankCity(){
				if(Number(this.account_type) != 1){
					return
				}
				let cqw = {
					provinceName:"",
					cityName:""
				}
				for(let i=0;i<this.cityInfo.length;i++){
					for(let j=0;j<this.cityInfo[i].children.length;j++){
						if(this.cityInfo[i].children[j].value === this.bankQuery.provinceName){
							cqw.provinceName = this.cityInfo[i].label
							cqw.cityName = this.cityInfo[i].children[j].label
							return cqw
						}
					}
				}
			},
			anewQyBankCity(item){
				for(let i=0;i<this.cityInfo.length;i++){
					for(let j=0;j<this.cityInfo[i].children.length;j++){
						if(this.cityInfo[i].children[j].label === item.city){
							return this.cityInfo[i].children[j].value
						}
					}
				}
			},
			getBankName(){
				this.$api.getBankName({
					account:this.bankQuery.number
				})
				.then(result => {
					if(result.code === 1){
						if(result.data){
							this.bankQuery.bank_name =  result.data
						}
					}
				})
			},
			getWithdrawAppId(){
				return new Promise((resolve,reject) => {
					this.$api.getWithdrawAppId()
					.then(result => {
						resolve(result.data)
					})
				})
			},
			bankListOne(){
				return new Promise((resolve,reject) => {
					this.$api.bankListOne({})
					.then(result => {
						let dict = result.data
						let list = []
						for(let i in dict){
							list.push(...dict[i])
						}
						this.options = list
						resolve(list)
					})
				})
			},
			clearnData(){
				this.alipayQuery.number = ''
				this.account_type = 0
				this.bankQuery = {
					number:"",
					bank_name:"",
					phone:"",
					corporatebank:"",
					provinceName:""
				}
			},
			resultAli(result){
				if(result.code === 1){
					ElMessage({
						message: result.msg,
						type: 'success',
					})
					this.clearnData() 
					setTimeout(() => {
						this.closeAlipay()
						this.closeBank()
						this.$emit('getWithdrawList')
					},1000)
				}else{
					ElMessage({
						message: result.msg,
						type: 'error',
					})
				}
			},
			upWithdrawAli(){
				if(!this.alipayQuery.number){
					ElMessage({
						message: '请输入支付宝账号',
						type: 'warning',
					})
					return
				}
				this.$api.upWithdrawAli({
					account:this.alipayQuery.number,
					id:this.alipayQuery.id,
				})
				.then(result => {
					this.resultAli(result)
				})
			},
			setWithdrawAli(){
				if(!this.alipayQuery.number){
					ElMessage({
						message: '请输入支付宝账号',
						type: 'warning',
					})
					return
				}
				this.$api.setWithdrawAli({
					account:this.alipayQuery.number,
				})
				.then(result => {
					this.resultAli(result)
				})
			},
			async openWx(){
				let appid = await this.getWithdrawAppId()
				const token = localStorage.getItem("token");
				let url = config.apiUrl + '/h5/#/pages/user/'+'setup/'+ 'bindaccount?appid='+appid + "&token="+token
				await this.getImg(url)
				this.addWxVisible = true
			},
			getImg(url){
				return new Promise((resolve,reject) => {
					this.$api.getJsonQrcode({
						data:encodeURIComponent(url)
					})
					.then(res => {
						this.imgWx = 'data:image/png;base64,' +res.data
						resolve(res)
					})
				})
			},
			closeBank(){
				this.addBankVisible = false
				this.updateBankVisible = false
			},
			closeAlipay(){
				this.addAlipayVisible = false
				this.updateAlipayVisible = false
			},
			openupdateAlipay(item){
				this.alipayQuery.number = item.account
				this.alipayQuery['id'] = item.id
				this.updateAlipayVisible = true
			},
			async openupdateBank(item){
				await this.bankListOne()
				await this.getUserInfowww()
				for(let i=0;i<this.cityInfo.length;i++){
					for(let j=0;j<this.cityInfo[i].children.length;j++){
						delete this.cityInfo[i].children[j].children
					}
				}
				this.account_type = item.account_type
				this.bankQuery.bank_name = item.bank_name
				this.bankQuery.number = item.account
				this.bankQuery.corporatebank = item.corporatebank
				this.bankQuery.phone = item.phone
				this.bankQuery['id'] = item.id
				if(this.account_type === 1){
					this.bankQuery.provinceName = this.anewQyBankCity(item)
				}
				this.updateBankVisible = true
			},
			async openBank(){
				await this.bankListOne()
				await this.getUserInfowww()
				for(let i=0;i<this.cityInfo.length;i++){
					for(let j=0;j<this.cityInfo[i].children.length;j++){
						delete this.cityInfo[i].children[j].children
					}
				}
				this.addBankVisible = true
			},
			openAlipay(){
				this.addAlipayVisible = true
			}
		}
	}
</script>

<style scoped>
	/deep/.pop-radius{
		border-radius: 24px;
	}
	/deep/.el-dialog__headerbtn .el-dialog__close{
		font-size: 20px;
	}
	.withdawfv{
		width: 194px;
		height: 194px;
	}
	/deep/ .real-inputd .el-input__wrapper{
		 background: #F7F9FC;
		 border-radius: 8px;
		 color: #B3B3B3;
		 height: 60px;
		 border-color: #F7F9FC;
	}
	.real-inputd + .real-inputd{
		 margin-top: 20px;
	}
	/deep/ .el-select .el-input{

		color: #B3B3B3;
		height: 60px;
		border-color: #F7F9FC;
		width: 342px;
	}
	/deep/ .el-select .el-input__wrapper{
		background: #F7F9FC;
		border-radius: 8px;
	}
</style>