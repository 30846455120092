<template>
	<div class="">
		<div class="text-center ft16 mt100" v-loading="loading">
			<div class="text-left" style="width:500px;margin:0 auto">
				<div class="">
					<span class="c_737373  user-datumf-sw ">登录账户</span>
					<span>{{userInfo.user_id}}</span>
				</div>
				<div class="mt26">
					<span class="c_737373 user-datumf-sw">真实姓名</span>
					<span v-if="realInfo.name">{{realInfo.name}}</span>
					<span v-else>未知，
						<a href="javascript:;" class="c_0EB6FF" @click="oepnReal">
							马上去实名认证
							<span class="icon-youjiantou iconfont"></span>
						</a>
					</span>
				</div>
				<div class="mt26">
					<span class="c_737373  user-datumf-sw ">手机绑定</span>
					<span v-if="userInfo.phone">{{userInfo.phone}}
						<a href="javascript:;" class="c_0EB6FF ml24" @click="oepnPhone">更换手机</a>
					</span>
					<span v-else>未绑定，
						<a href="javascript:;" class="c_0EB6FF" @click="oepnPhone">马上去绑定</a>
					</span>
				</div>
				<div class="mt26">
					<span class="c_737373  user-datumf-sw">邮箱设置</span>
					<span v-if="userInfo.email"></span>
					<span v-else>未设置，
						<a href="javascript:;" class="c_0EB6FF" @click="oepnEmail">马上去设置</a>
					</span>
				</div>
				<div class="mt26" v-if="userInfo.is_check != 1">
					<span class="c_737373  user-datumf-sw mr85">尽职调查</span>
					<span v-if="inquiry.status === 1">已填写</span>
					<span v-else>未填写，
						<a href="javascript:;" class="c_0EB6FF" @click="openSurvey">前往填写</a>
					</span>
				</div>
				<div class="mt26">
					<span class="c_737373  user-datumf-sw mr87">注册时间</span>
					<span>{{userInfo.create_time}}</span>
				</div>
			</div>
			<el-dialog v-model="dialogVisible" title="" width='522px' custom-class="pop-radius">
				<div class="text-center">
					<!-- <p class="ft24 font-weight700 c_333333">结算状态</p> -->
					<div v-if="inquiry.status === 0" class="alcenter flex-center">
						<el-icon class="waring-wds ft25"><InfoFilled /></el-icon>
						<span class="ft20 ml16">审核中</span>
					</div>
					<div v-if="inquiry.status === 1" class="alcenter flex-center">
						<el-icon class="success-wds ft25"><SuccessFilled /></el-icon>
						<span class="ft20 ml16">审核成功</span>
					</div>
					<div v-if="inquiry.status === 2 || inquiry.status === 3" class="alcenter flex-center">
						<el-icon class="error-wds ft25"><WarningFilled /></el-icon>
						<span class="ft20 ml16">{{inquiry.status === 2?'可重新提交':"审核驳回"}}</span>
					</div>
					<div v-if="inquiry.status === 4" class="alcenter flex-center">
						<el-icon class="error-wds ft25"><CircleCloseFilled /></el-icon>
						<span class="ft20 ml16">审核失败</span>
					</div>
					<div class="ft18 c_909399 mt24">
						<p v-if="inquiry.status === 3 || inquiry.status===2">{{inquiry.cause}}</p>
						<p v-else>{{inquiry.status === 0?'请耐心等待人工审核':inquiry.status===1?'已完成尽职调查':''}}</p>
					</div>
					<div class="prl70 mt40 ">
						<div v-if="inquiry.status === 2">
							<el-button color="#0EB6FF" class="main-button mt20" @click="oepnSurvey()">重新提交</el-button>
						</div>
						<el-button type="info"  class="main-button mt20" @click="dialogVisible = false;">关闭</el-button>
					</div>
				</div>
				<template #footer>
					<span class="dialog-footer">
					</span>
				</template>
			</el-dialog>
		</div>
	</div>
</template>

<script>

	export default{
		data(){
			return{
				loading:false,
				realInfo:{},
				userInfo:{},
				inquiry:{},
				dialogVisible:false
			}
		},
		components:{

		},
		created(){
			// this.getUserInfo()
			this.loadDetail1()
			if(this.userInfo.is_check != 1){
				this.loadDetail3()
			}
		},
		methods:{
			oepnReal(){
				this.$emit("handlePage",'2')
			},
			oepnEmail(){
				this.$emit("handlePage",'5')
			},
			oepnPhone(){
				this.$emit("handlePage",'4')
			},
			oepnSurvey(){
				this.$router.push({path:'/user/survey'})
			},
			getData(item){
				this.userInfo = item
			},
			async openSurvey(){
				this.inquiry = await this.loadDetail3()
				console.log(this.inquiry)
				if(this.inquiry.status === null || this.inquiry){
					this.oepnSurvey()
				}else{
					if(this.inquiry.status === 1){
						return
					}
					this.dialogVisible = true
				}
			},
			loadDetail3(){
				return new Promise((resolve,reject) => {
					let user_id = JSON.parse(localStorage.getItem("userInfo")).user_id
					this.$api.dueDiligenceStatus({
						user_id,
					})
					.then(result => {
						console.log(result)
						if(result.code === 1){
							this.inquiry = result.data
						}
						resolve(result.data)
					})
				})
			},
			loadDetail1() {
				return new Promise((resolve,reject) => {
					this.$api.getUserRealStatus({
						type:1
					})
					.then(result => {
						this.realInfo = result.data
						resolve(result.data)
					})
				})
			},
		}
	}
</script>

<style scoped>
	.user-datumf-sw{
		margin-right: 80px;
	}
	.mr87{
		margin-right:86px
	}
	.mr85{
		margin-right: 80px;
	}
</style>