<template>
	<div>
		<el-dialog v-model="realVisible" title="" width='522px' custom-class="pop-radius" @close='claswoyt' :close-on-click-modal="false">
			<div class="text-center">
				<p class="ft24 font-weight700 c_333333">请用{{type === 1?'支付宝':'微信'}}扫码下方二维码</p>
				<p class="c_737373 ft18 mt20 " style="">{{topMsg}}</p>
				<img :src="img_real"  class="mt30 withdawfv" />
			</div>
			<template #footer>
				<span class="dialog-footer">
				</span>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				realVisible:false
			}
		},
		methods:{
			openReal(){
				this.realVisible = true
			}
		}
	}
</script>

<style>
</style>