<template>
	<div style="" class="login-bottomwew" :class="loginPop?'popLoing-height':''">
		<loginHeader v-if="!loginPop"></loginHeader>
		<div class=" flex-center alcenter mt40" :class="{'login-main':!loginPop}">
			<div class="login-left">
				<el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
					<el-tab-pane label="账号密码登录" name="pwd"></el-tab-pane>
					<el-tab-pane label="验证码登录" name="code"></el-tab-pane>
				</el-tabs>
<!-- 				<wxlogin
				class="my-wx-code"
				:appid="'wxb4b6351be89f4301'"
				:scope="'snsapi_login'"
				:theme="'black'"
				:redirect_uri="'https://www.tuantuanshou.com/#/login'"
				:href="'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDI2MHB4OyAgIG1hcmdpbjogMCBhdXRvOyBwYWRkaW5nOiAwO30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLnN0YXR1c19pY29uIHtkaXNwbGF5OiBub25lOyB9Ci5pbXBvd2VyQm94IC5zdGF0dXMge3RleHQtYWxpZ246IGNlbnRlcjsgZGlzcGxheTogbm9uZTt9Ci5pbXBvd2VyQm94IHsgaGVpZ2h0OiAzNTBweDsgcGFkZGluZzogMDsgbWFyZ2luOiAwIGF1dG87IH0KaS5zdGF0dXNfaWNvbi5pY29uMzhfbXNnLnN1Y2MgewogIGRpc3BsYXk6IG5vbmU7Cn0KLmltcG93ZXJCb3ggLnN0YXR1c190eHQgewoJZGlzcGxheTogbm9uZTsKfQppLnN0YXR1c19pY29uLmljb24zOF9tc2cud2FybiB7CiAgZGlzcGxheTogbm9uZTsKfQ==' ">
				</wxlogin> -->
				<div class="mt80">
					<el-form
						ref="formRef"
						:model="numberValidateForm"
						class="demo-ruleForm"
					>
						<div v-if="activeName === 'pwd'">
							<el-form-item
								prop="phone"
								:rules="[
									{ required: true, message: '请输入账号' },
								]"
							>
								<el-input
									v-model="numberValidateForm.phone"
									type="text"
									autocomplete="off"
									placeholder="请输入您的账号"
								>
									<template #prefix>
										<el-icon><User /></el-icon>
									</template>
								</el-input>
							</el-form-item>
							<el-form-item
								prop="password"
								:rules="[
									{ required: true, message: '请输入密码' },
								]"
							>
								<el-input
									type="password"
									v-model="numberValidateForm.password"
									placeholder="请输入您的密码"
									autocomplete="off"
									@keyup.enter="submitForm('formRef')"
								>
									<template #prefix>
										<el-icon><Unlock /></el-icon>
									</template>
								</el-input>
							</el-form-item>
						</div>
						<div v-if="activeName === 'code'">
							<el-form-item
								prop="phone"
								:rules="[
									{ required: true, message: '请输入手机号' },
								]"
							>
								<el-input
									v-model="numberValidateForm.phone"
									type="text"
									autocomplete="off"
									placeholder="请输入您的手机号"
								>
									<template #prefix>
										<el-icon><Iphone /></el-icon>
									</template>
								</el-input>
							</el-form-item>
							<el-form-item
								prop="code"
								:rules="[
									{ required: true, message: '请输入验证码' },
								]"
							>
								<el-input
									v-model="numberValidateForm.code"
									placeholder="请输入您的验证码"
									type="text"
									autocomplete="off"
									@keyup.enter="submitForm('formRef')"
								>
									<template #prefix>
										<el-icon><Lock /></el-icon>
									</template>
									<template #suffix>
										<el-link :underline="false"  type="primary" @click="getCode" :disabled="time!=0">{{buttonText}}</el-link>
									</template>
								</el-input>
							</el-form-item>
						</div>
						<el-form-item>
							<el-button class="login-button" color="#0EB6FF" @click="submitForm('formRef')" >立即登录</el-button>
							<div class="flex alcenter ytrw c_737373">
								<div>其他登录方式：</div>
								<img src="../../assets/wx.png" @click="loginWx" />
								<!-- <img src="../../assets/alipay.png" @click="loginAlipay" /> -->
							</div>
							<!-- <el-button class="login-button" color="#01cf75" @click="loginWx" style="margin-top:10px;">微信登录</el-button> -->
						</el-form-item>
						<el-form-item>
							
						</el-form-item>
					</el-form>
				</div>
				<div class="freyhgf-ftg mt30 flex " v-if="!loginPop">
					<el-link :underline="false" class="flex-one feyggs" @click="$router.push({path:'/register'})">免费注册</el-link>
					<el-link :underline="false" class="flex-one feyggs" @click="$util.checkServe()">联系客服</el-link> 
					<el-link :underline="false" class="flex-one feyggs" @click="$router.push({path:'/notice/brief'})">关于我们</el-link>
				</div>
				<div class="mt24 c_737373 ft16" v-if="!loginPop">
					<p>登录遇到问题？</p>
					<p class="ft14">原登录方式不可用、密码无法找回、如已绑定手机账号，可直接前往修改。</p>
				</div>
			</div>
			<img v-if="!loginPop" class="login-right" @click="openCardRecy" src="../../assets/login/login-bg.png" />
			
			
		</div>
		<loginFooter v-if="!loginPop" style="position: absolute;bottom: 0;width: 100%;"></loginFooter>
	</div>
</template>

<script>
	import loginHeader from '../../components/header/login.vue'
	import loginFooter from '../../components/footer/user.vue'
	import { ElMessage, ElMessageBox } from 'element-plus'
	import config from '@/config'
	import wxlogin from 'vue-wxlogin'
	// import wdfew from '../login/wx_login'
	export default{
		props:{
			loginPop: {
				type:Boolean,
				default: false,
			}
		},
		data(){
			return{
				activeName:'pwd',
				numberValidateForm:{
					phone:"",
					password:"",
					code:''
				},
				time:0
			}
		},
		components:{
			loginHeader,
			loginFooter,
			wxlogin,
			
		},
		mounted() {
		  document.querySelector('body').setAttribute('style', 'background-color:#fff')
			window["getLogin"] = (params) => {
					this.getApageLists(params);
			};
		},
		beforeDestroy() {
			document.querySelector('body').removeAttribute('style')
		},
		computed:{
			buttonText() {
			  return this.time != 0 ? `${this.time}秒后重新获取` : "获取验证码";
			},
		},
		created() {
			
		},
		methods:{
			getApageLists(){
				this.$router.push({path:'/user'})
			},
			openCardRecy(){
				this.$router.push({path:'/card/huishou'})
			},
			delCodeandstate(to) { //函数作用：去除url中的code和state
			    let path = ""
			    for (let i in to.query) {
			        if (i != "code" && i != "state") {
			            path = path + "&" + i + "=" + to.query[i]
			        }
			    }
			    path = path == "" ? "" : path.substring(1, path.length)
			    path = path == "" ? "" : "/?" + path
			    return path;
			},
			loginWx(){
				// this.$api.signin_wx({
				// 	code:"081J1WGa1bJkxH0kZ8Ga1Q10cb2J1WGK"
				// })
				// .then(result => {
				// 	console.log(result)
				// })
				// return
				const page2 = this.$router.resolve({name:'wxLogin'});
				const page = window.open(page2.href,"TencentLogin", "width=500,height=400,menubar=0,scrollbars=1, resizable=1,status=1,titlebar=0,toolbar=0,location=1")
				return
				// this.$api.pc_signin()
				// .then(result => {
				// 	let dict = result.msg
				// 	let url = `${window.location.origin}/api/login/signin`
				// 	let redirect_uri = 'https://www.doudshou.com'
				// })
			},
			loginAlipay(){
				let appID = "wxb4b6351be89f4301"; 
				let redirectUrl = 'https://www.tuantuanshou.com'; 
				let authorizeUrl =
						`https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=${appID}&scope=auth_user&redirect_uri=` +
						`${encodeURIComponent(
								redirectUrl
						)}`+`&state=init`;
				window.location.href = authorizeUrl; // 跳转链接获取code
			},
			getCode(){
				if (this.time != 0) {
					return;
				}
				this.$api.sendSmsCaptcha({
					mobile:this.numberValidateForm.phone,
				})
				.then(result => {
					if(result.code === 1){
						ElMessage({
							message: result.msg,
							type: 'success',
						})
						this.time = 60
						const times = setInterval(() => {
							this.times; //递减
							if (this.times === 0) {
								// === 0 变成获取按钮
								clearInterval(times);
							}
						}, 1000);
					}else{
						ElMessage({
							message: result.msg,
							type: 'error',
						})
					}
				})
			},
			handleClick(e){
				
			},
			passLogin(){
				this.$api.phonePassLogin(this.numberValidateForm)
				.then(result => {
					if(result.code === 1){
						ElMessage({
							message: '登录成功',
							type: 'success',
						})
						localStorage.setItem("token",result.data.token);
						setTimeout(() => {
							if(this.loginPop){
								this.$emit('popLoinSuccess')
							}else{
								this.$router.push({path:'/'})
							}
						},1000)
					}else{
						ElMessage({
							message: result.msg,
							type: 'error',
						})
					}
				})
			},
			codeLogin(){
				
				this.$api.phoneLogin(this.numberValidateForm)
				.then(result => {
					if(result.code === 1){
						ElMessage({
							message: '登录成功',
							type: 'success',
						})
						localStorage.setItem("token",result.data);
						setTimeout(() => {
							if(this.loginPop){
								this.$emit('popLoinSuccess')
							}else{
								this.$router.push({path:'/'})
							}
						},1000)
					}else{
						ElMessage({
							message: result.msg,
							type: 'error',
						})
					}
				})
			},
			handLogin(){
				this.numberValidateForm['type'] = config.postType
				if(this.activeName === 'pwd'){
					this.passLogin()
				}else{
					this.codeLogin()
				}
			},
			submitForm(formName){
       this.$refs[formName].validate((valid) => {
          if (valid) {
						this.handLogin()
          } else {
            return false;
          }
        });
			}
		}
	}
</script>

<style scoped lang="less">
	.login-main{
		padding-bottom: 400px;
	}
	.mt80{
		margin-top: 80px;
	}
	.login-right{
		width: 515px;
		height: 600px;
		margin-left: 147px;
		cursor:pointer;
	}
	.login-left{
		width: 478px;
	}
	/deep/.el-tabs__nav{
		width: 100%;
	}
	/deep/.el-tabs__item{
		flex: 1;
		font-size: 24px;
		font-weight: 600;
	}
	/deep/.el-tabs__active-bar{
		height: 4px;
	}
	/deep/.el-tabs{
		--el-tabs-header-height: 70px;
	}
	/deep/.el-input{
		height: 52px;
		width: 382px;
		margin: 0 auto;
		font-size: 16px;
	}
	/deep/.el-form-item__error{
		left: 10%;
		padding-top: 10px;
		font-size: 14px;
	}
	/deep/.el-form-item{
		margin-bottom: 28px;
	}
	/deep/.el-input__wrapper{
		border-radius: 8px;
	}
	.login-button{
		width: 382px;
		height: 52px;
		color: #fff!important;
		margin: 0 auto;
		margin-top: 40px;
		margin-left:auto!important;
		border-radius: 8px;
	}
	.freyhgf-ftg{
		border-top: 1px dotted #E5E5E5;
		border-bottom: 1px dotted #E5E5E5;
		padding: 16px 0;
	}
	.feyggs{
		text-align: center;
		border-right: 1px solid #EDEDED;
	}
	.feyggs:last-child{
		border: none;
	}
	.ytrw{
		width: 382px;
		margin: 0 auto;
		margin-top: 30px;
	}
	.ytrw img{
		width: 30px;
		height: 30px;
		cursor:pointer;
	}
	.ytrw img + img{
		margin-left: 16px;
	}
	.popLoing-height{
		min-height: auto;
		padding-bottom:0;
	}
</style>