<template>
	<div class="thg  html">
		<cardHeader></cardHeader>
		<eshopbg type="card" @handleSearch="gainSearch"></eshopbg>
		<el-row :gutter="20"     
			v-loading="loading"
			element-loading-text="加载中..."
			class="wdsffdzcdtg  qwfgreyyhyuu" style="min-height: 100vh;padding-top: 16px;">
			<el-col :span="19" :offset="3">
				<div class="flex flex-between mb20" style="align-items: flex-start;">
					<div class="flex-one ">
						<div class="dqwgfdcar " v-if="searchList.length > 0">
							<div class="flex alcenter">
								<div class="hint-goods"></div>
								<div class="hint-title">搜索内容</div>
							</div>
							<div class="flex flex-wrap">
								<a href="javascript:;" v-for="(item,index) in searchList" :key="index"  class="goods-list-item "  @click="gainSearch(item)">
									<div class="goods-imgwd">
										<img :src="item.image.file_path" class="dqwfdsfyhxc" />
									</div>
									<div>{{item.goods_name}}</div>
								</a>
							</div>
						</div>
						<el-card shadow="never"  class="dsqw-card">
							<div class="dqwgfdcar bvgrr">
								<div class="flex alcenter">
									<div class="hint-goods"></div>
									<div class="hint-title">选择卡类</div>
								</div>
								<div class="flex flex-wrap">
									<a href="javascript:;" v-for="(item,index) in cardList" :key="index" :class="item.class" class="card-list-item flex-center alcenter" @click="gainCard(item)">
										<div class="card-list-item-active" v-if="item.cate_id === kindList.cate_id">
											<img src="../../assets/card/pair.png" class="text-center"   style="padding-left: 4px;"/>
										</div>
										<img :src="item.icon"/>
										<div>{{item.name}}</div>
									</a>
								</div>
							</div>
						</el-card>
						<el-card shadow="never" class="dsqw-card">
							<div class="dqwgfdcar bvgrr">
								<div class="flex alcenter">
									<div class="hint-goods"></div>
									<div class="hint-title">选择卡种</div>
								</div>
								<div class="flex flex-wrap">
									<a href="javascript:;" v-for="(item,index) in goodsList" :key="index"  class="goods-list-item " :class="{'goods-active':item.id === hoopList.id}"  @click="gainFace(item)">
										<div class="goods-list-item-active" v-if="item.id === hoopList.id">
											<img src="../../assets/card/pair.png" class="text-center"   />
										</div>
										<div class="goods-imgwd">
											<img :src="item.image.file_path" class="dqwfdsfyhxc" />
										</div>
										<div>{{item.goods_name}}</div>
									</a>
								</div>
							</div>
						</el-card>
						<el-card shadow="never" class="dsqw-card">
							<div class="dqwgfdcar bvgrr">
								<div class="flex alcenter">
									<div class="hint-goods"></div>
									<div class="hint-title">卡券面值</div>
								</div>
								<div class="flex flex-wrap">
									<a href="javascript:;" v-for="(item,index) in faceList" :key="index"  class="face-list-item " :class="{'goods-active':item.product_no === faceDetail.product_no}"  @click="gainMony(item)">
										<div class="face-list-item-tain face-list-item-sdw" v-if="hoopList.maintenance === 1 || item.status != 1 ">
											维护
										</div>
										<div class="face-list-item-dis face-list-item-sdw" v-else>
											{{item.api_discount}}折
										</div>
										<div class="goods-list-item-active" v-if="item.product_no === faceDetail.product_no">
											<img src="../../assets/card/pair.png" class="text-center"   />
										</div>
										<div v-if="item.face_value != '0.00'">
											<div class="ft20 c_333333">{{item.show?item.show:item.face_value}}</div>
											<div class="mt5">
												<span class="ft14 c_737373">回收</span>
												<span class="ft18 c_FF7544">￥{{discount_show(item.face_value * discount(item.api_discount))}}</span>
											</div>
										</div>
										<div v-else>
											<div class="ft20 c_333333 text-center" style="padding-top: 10px;" v-if="!custom">自定义面值</div>
											<div v-else>
												<div class="ft20 c_333333">{{custom}}</div>
												<div class="mt5">
													<span class="ft14 c_737373">回收</span>
													<span class="ft18 c_FF7544">￥{{discount_show(custom * discount(item.api_discount))}}</span>
												</div>
											</div>
										</div>
									</a>
								</div>
							</div>
						</el-card>
						<el-card shadow="never" class="dsqw-card">
							<div class="dqwgfdcar" v-if="Object.keys(faceDetail).length != 0">
								<div class="flex alcenter">
									<div class="hint-goods"></div>
									<div class="hint-title">卡券提交方式</div>
								</div>
								<div class="mt24">
									<el-radio-group v-model="tabIndex">
										<el-radio :label="1" size="large"
													v-if="
							            faceDetail.form_num === 1 ||
							            faceDetail.form_num === 2 ||
							            faceDetail.form_num === 3 ||
													faceDetail.form_num === 8
							          ">
											单卡提交
										</el-radio>
										<el-radio :label="2" size="large"
													v-if="
							            (faceDetail.form_num === 1 ||
							              faceDetail.form_num === 2 ||
							              faceDetail.form_num === 3 ||
														faceDetail.form_num === 8) &&
							            faceDetail.batch_submit != 2
										    ">
											批量提交
										</el-radio>
										<el-radio :label="3" size="large"
													v-if="
													faceDetail.form_num === 4 || faceDetail.form_num === 6
										    ">
											图片提交
										</el-radio>
										<el-radio :label="4" size="large"
													v-if="
													faceDetail.form_num === 5
										    ">
											文本提交
										</el-radio>
									</el-radio-group>
								</div>
								<div class="product-fze" v-if="faceDetail.product_desc">
									*{{faceDetail.product_desc}}
								</div>
								<div class="mt20" v-if="faceDetail.term != -1">
									<div class="col-xs-11">
										<el-date-picker
											v-model="data_time"
											type="date"
											placeholder="请选择有效期"
											format="YYYY/MM/DD"
											value-format="YYYY-MM-DD"
											@change="date_dasdq"
										>
										</el-date-picker>
									</div>
								</div>
								<div class="mt24">
									<el-collapse-transition>
										<div class="" v-if="tabIndex === 1">
											<div v-if="faceDetail.form_num === 1 || faceDetail.form_num === 2|| faceDetail.form_num === 7|| faceDetail.form_num === 9" class="fomr-input-main">
												<el-input
												 :maxlength="hoopList.goods_name === '京东e卡(全品类)'? faceDetail.card_length > 0?faceDetail.card_length + 3:99 : faceDetail.card_length > 0?faceDetail.card_length:99" 
												 v-model="card" 
												 placeholder="在此输入您的卡号" 
												 class="fomr-inputs"
												 ></el-input>
											</div>
											<div v-if="faceDetail.form_num === 1 || faceDetail.form_num === 3 || faceDetail.form_num === 8|| faceDetail.form_num === 7|| faceDetail.form_num === 9" class="fomr-input-main">
												<el-input
												 :maxlength="hoopList.goods_name === '京东e卡(全品类)'? faceDetail.pass_length > 0?faceDetail.pass_length + 3:99:faceDetail.pass_length > 0?faceDetail.pass_length:99" 
												 v-model="card_pass" 
												 placeholder="在此输入您的卡密" 
												 class="fomr-inputs"
												 ></el-input>
											</div>
											<div v-if="faceDetail.form_num === 9" class="fomr-input-main">
												<el-input
												 v-model="cvv_pass" 
												 placeholder="在此输入您的CVV码" 
												 class="fomr-inputs"
												 ></el-input>
											</div>
										</div>
									</el-collapse-transition>
									<el-collapse-transition>
										<div v-if="tabIndex === 2">
											<div style="position: relative;" class="fomr-textarea">
												<el-input
												  type="textarea"
												  placeholder="请填写卡号卡密"
													class=""
													v-model="cpVal" 
													@keyup="onInput"
												>
												</el-input>
												<div class="caution">
													<div class="flex alcenter mt60 fwefbhjk">
														<img  src="../../assets/card/below.png"/>
														<img  src="../../assets/card/below.png"/>
														<img  src="../../assets/card/below.png"/>
													</div>
													<div class="c_0EB6FF ft14 font-weight600 mt20">
														请按照如下规则填写：
													</div>
													<div class="c_737373 ft14 mt10">
														1、每次提交只能上传同一种类同一面值的充值卡卡密。
														<br>
														2、卡号卡密之间请用空格隔开。
														<br>
														3、每张卡输入完按“回车键”换行输入下一张。
													</div>
												</div>
											</div>
										</div>
									</el-collapse-transition>
									<el-collapse-transition>
										<div v-if="tabIndex === 3">
											<div style="position: relative;">
												  <div class="uploadimg-group clearfix">
												    <el-upload
												      :action="baseUrl + '/api/upload/image'"
												      list-type="picture-card"
												      :data="{ token: token }"
												      name="iFile"
												      :on-preview="handlePictureCardPreview"
												      :on-remove="handleRemove"
												      :on-success="handSuccess"
												      :on-exceed="handErr"
												      :multiple="true"
												      :limit="10"
												    >
												      <el-icon><Plus /></el-icon>
												    </el-upload>
												    <el-dialog :visible.sync="dialogVisiblel">
												      <img width="100%" :src="dialogImageUrl" alt="" />
												    </el-dialog>
												  </div>
												  <div class="help-block">
												    <p>
												      可多选，最多可同时提交
												      <strong class="text-red">10</strong>
												      张
												      <em class="upload-text"> 二维码或图片 </em>
												    </p>
												  </div>
											</div>
										</div>
									</el-collapse-transition>
									<el-collapse-transition>
										<div v-if="tabIndex === 4">
											<div style="position: relative;">
												<el-input
												  type="textarea"
												  placeholder="在次输入"
													class="fomr-textarea"
													v-model="text_tar" 
												>
												</el-input>
											</div>
										</div>
									</el-collapse-transition>
								</div>
							</div>
							<div class="present">
								<div>
									<el-checkbox v-model="check2">
										我已阅读，理解并接受<el-link @click="openMakeOver" :underline="false"  type="primary">「礼品卡转让协议」</el-link>和<el-link @click="openRecycle" :underline="false"  type="primary">「礼品卡转让说明」</el-link>
									</el-checkbox>
									<br>
									<el-checkbox v-model="check3">
										我已确认该卡号卡密来源合法，如有问题，本人愿意承担一切法律责任。
									</el-checkbox>
								</div>
								<el-button color="#0EB6FF" class="curr-button mt24" :loading="buttonLoging" @click="postSubmit" >确认面值无误并提交</el-button>
							</div>
						</el-card>
						<div class="flex mt16">
							<el-card shadow="never"  style="max-width: 500px;margin-right: 16px;">
								<div class="user-notice">
									<div class="flex alcenter mb20">
										<div class="hint-goods"></div>
										<div class="hint-title">用户须知</div>
									</div>
									
									<div v-html="HTMLDecode(faceDetail.user_notice?faceDetail.user_notice:userContent)" v-if="userContent || faceDetail.user_notice"></div>
									<div v-else>
										<div class="text-box2">
											1、<span class="heise" >请正确核对卡号与密码是否相对应，并保证仅在本平台进行转让</span> ，否则可能导致无法结算，给您带来损失
												
										</div>
										<div class="text-box2">
											2、请务必同时输入正确的卡号和密码，
											<span class="hongse">如果卡号错误，密码正确也可能给您带来损失</span>
												
										</div>
										<div class="text-box2">
											3、请保证选择的充值卡面值与实际面值一样，
											<span class="heise">面值选错将无法正常结算，给您带来损失</span>
											
										</div>
										<div class="text-box2">
											4、若上传的卡券已被使用或为错误券码，即便已拿到货款，后续也会被追责
											<span class="heise">，故请卖家认真核实！</span>
										</div>
										<div class="text-box2">
											5、为保证您的资金安全，请先进行实名认证再提交，实名认证通过后方可提现
											
										</div>
										<div class="text-box2">
											<span class="heise">6、回收速度可在提交页面选择卡种和面值后，参考输入框上方显示的最近回收时间和输入框下方的备注</span>
										</div>
									</div>
								</div>
							</el-card>
							<el-card shadow="never" class="flex-one" style="margin-top: 0;" > 
								<div class=" user-notice">
									<div class="flex alcenter ">
										<div class="hint-goods"></div>
										<div class="hint-title">相关文章</div>
									</div>
									<div class="flex flex-wrap">
										<template v-for="(item,index) in hotArticle" :key="index"  >
											<div class="hot-article-item" v-if="index<=5" @click="openArticle(item)" >
												<div class="qwdsfimg">
													<img :src="item.image.file_path" class="qwdsfimg" />
												</div>
												
												<div>
													<div class="hot-fasfqw two-ouddle">{{item.article_title}}</div>
												</div>
											</div>
										</template>
									</div>
								</div>
							</el-card>
						</div>
					</div>
				</div>
			</el-col>
		</el-row>
		<el-dialog
			v-model="dialogVisible"
			width="500px"
			draggable
			custom-class="pop-radius"
		>
			<div class="ft24 c_333333 font-weight600 text-center">
				请输入自定义卡面值
			</div>
			<div class="text-center">
				<el-input v-model="log" placeholder="请输入自定义金额" type="number" class="fwffsfctu-input" />
				<el-button color="#0EB6FF" class="curr-button mt40" @click="postCustom" >确认面值无误并提交</el-button>
			</div>
		</el-dialog>
		<el-dialog
			v-model="orderSuccess"
			width="500px"
			draggable
			custom-class="pop-radius"
		>
		<div class="text-center " style="color: #44d410;font-size: 100px;">
			<el-icon><SuccessFilled /></el-icon>
		</div>
			
			<div class="ft24 c_333333 font-weight600 text-center mt40">
				提交订单成功
			</div>
			<div class="ft14 c_ccc text-center mt20 ">
				<div>您的订单已经成功提交，平台将尽快处理！感谢您的支持！</div>
				<div>订单进度将通过微信公众号向您实时推送，</div>
				<div>敬请关注！</div>
				
			</div>
			
			<div class="text-center mt75" style="margin-top: 50px;">
				<el-button color="#0EB6FF" class="curr-button " @click="orderSuccess=false;clearnData()" >继续提交</el-button>
				<el-button type="" class=" mt10" style="margin-left: 0;    height: 60px !important;width: 316px !important;border-radius: 8px !important;" @click="openOrder" >查看订单</el-button>
			</div>
		</el-dialog>
		<el-dialog
		  v-model="makeOver"
		  width="500px"
			title="请阅读团团收转让协议"
			draggable
			style="min-height: 600px;"
		>
				<iframe :src="url" style="width: 100%;border: none;min-height: 600px;"></iframe>
		</el-dialog>
		<el-dialog
		  v-model="recycle"
		  width="500px"
			title="请阅读礼品卡回收说明"
			draggable
		>
				<recycle-list></recycle-list>
		</el-dialog>
		<el-dialog
			title="登录"
			v-model="loginEcycle"
			width="700px"
			:show-close="true"
			custom-class="pop-radius"
		>
			<div class="protocol">
				<login @popLoinSuccess="refresh" :loginPop="true"></login>
			</div>
		</el-dialog>
		<userBottom></userBottom>
	</div>
</template>

<script>
	import userBottom from '../../components/footer/user.vue'
	import cardHeader from '../../components/header/card.vue'
	import eshopbg from '../../components/eshopbg/index.vue'
	import config from '../../config.js'
	import { ElMessage, ElMessageBox } from 'element-plus'
	import makeOverList from '@/view/notice/protocol'
	import recycleList from '@/components/recycleList/index'
	import login from '@/view/login/index'
	export default{
		data(){
			return{
				searchValue:"",
				userContent:"",
				kindList:{},
				cardList:[],
				goodsList:[],
				hoopList:{},
				faceList:[],
				faceDetail:{},
				userList:{},
				cate_id:null,
				good_id:null,
				custom:"",
				log:"",
				fwdfgtrhy:{},
				dialogVisible:false,
				dialogVisiblel:false,
				dialogImageUrl:"",
				imgList:[],
				tabIndex:2,
				card:"",
				card_pass:"",
				cvv_pass:"",
				cpVal:"",
				text_tar:"",
				data_time: "",
				date_now:new Date().toISOString().slice(0, 10),
				time_number:"",
				check2:false,
				check3:false,
				loading:false,
				classList:[
					'card-list-bg1',
					'card-list-bg2',
					'card-list-bg3',
					'card-list-bg4',
					'card-list-bg5',
					'card-list-bg6',
					'card-list-bg7',
					'card-list-bg8',
					'card-list-bg9'
				],
				iconList:[
					require('../../assets/card/icon/phone.png'),
					require('../../assets/card/icon/oil.png'),
					require('../../assets/card/icon/game.png'),
					require('../../assets/card/icon/shop.png'),
					require('../../assets/card/icon/supmark.png'),
					require('../../assets/card/icon/trip.png'),
					require('../../assets/card/icon/cate.png'),
					require('../../assets/card/icon/movie.png'),
					require('../../assets/card/icon/integral.png'),
				],
				token:localStorage.getItem("token"),
				baseUrl:config.baseUrl,
				buttonLoging:false,
				searchList:[],
				makeOver:false,
				recycle:false,
				loginEcycle:false,
				url:"",
				orderSuccess:false,
				hotArticle:[]
			}
		},
		beforeCreate () {
		  document.querySelector('body').setAttribute('style', 'background-color:#fff;')
		},
		beforeDestroy () {
		  document.querySelector('body').removeAttribute('style')
		},
		components:{
			userBottom,
			cardHeader,
			eshopbg,
			makeOverList,
			recycleList,
			login
		},
		created() {
			if(this.$route.query.cate_id){
				this.gainSearch({id:this.$route.query.id,cate_id:this.$route.query.cate_id})
			}else{
				this.gainSearch({cate_id:4})
				// this.getShopData()
			}
			this.getNotice()
			this.agreementpc()
			
		},
		methods:{
			date_dasdq(e){
				console.log(this.data_time)
			},
			openArticle(item){
				this.$router.push({
					path:"/article/detail",
					query:{article_id:item.article_id}
				})
			},
			articleHotpc(){
				this.$api.relevantlist({
					card_id:this.hoopList.id
				})
				.then(result => {
					this.hotArticle = result.data.data
				})
			},
			openOrder(){
				this.$router.push({path:"/user/order"})
			},
			agreementpc(){
				this.$api.getContent({
					type:8
				})
				.then(result => {
					this.url = config.fronUrl + result.data.url
				})
			},
			refresh(){
				window.location.reload();
			},
			openMakeOver(){
				this.makeOver = true
			},
			openRecycle(){
				this.recycle = true
			},
			gainSearch(item){
				this.cate_id = Number(item.cate_id)
				this.good_id = Number(item.id)
				this.getShopData()
			},
			search(){
				if(!this.searchValue){
					ElMessage({
					  message: "请输入搜索关键词",
					  type: "warning",
					});
					return
				}
				this.$api.searchGoods({
					goods_name:this.searchValue
				})
				.then(result => {
					if(result.code === 1){
						if(result.data.length === 0){
							ElMessage({
							  message: "没有搜索到相关信息",
							  type: "warning",
							});
						}
						this.searchList = result.data
					}
				})
			},
			// 图片上传
			handleRemove(file, fileList) {
			  let imgid = file.response.data.file_id;
			  if (this.imgList.indexOf(imgid) != -1) {
			    this.imgList.splice(this.imgList.indexOf(imgid), 1);
			  }
			},
			handlePictureCardPreview(file) {
			  this.dialogImageUrl = file.url;
			  this.dialogVisiblel = true;
			},
			handSuccess(response, file, fileList) {
			  this.imgList.push(response.data.file_id);
			},
			handErr() {
			  ElMessage({
			    message: "最多上传10张图片",
			    type: "warning",
			  });
			},
			clearnData(){
				this.faceDetail = {}
				this.custom = ""
				this.fwdfgtrhy = {}
				this.tabIndex = null
			},
			handleClose(){
				this.log = ""
			},
			postCustom(){
				this.custom = this.log 
				this.dialogVisible = false
				this.faceDetail = this.fwdfgtrhy
				// this.gainMony(this.fwdfgtrhy)
			},
			discount_show(price) {
			  return price.toFixed(2);
			},
			discount(price) {
			  let bool = false;
			  let number = 0;
			  for (let i = 0; i < price.length; i++) {
			    if (price[i] == ".") {
			      bool = true;
			    }
			  }
			  return Number(price) / 100;
			},
			HTMLDecode(text) {
				var temp = document.createElement("div");
				temp.innerHTML = text;
				var output = temp.innerText || temp.textContent;
				temp = null;
				return output;
			},
			getNotice(){
				this.$api.getNotice({
					type:1
				})
				.then(result => {
					this.userContent = result.data.content;
				})
			},
			async gainCard(item){
				this.loading = true
				this.kindList = item
				await this.getGoodsList()
				this.loading = false
			},
			gainFace(item){
				this.clearnData()
				this.hoopList = item
				this.articleHotpc()
				this.seoMain()
				this.faceList = item.products;
				if(this.faceList.length > 0){
					this.faceDetail = this.faceList[0]
				}
			},
			gainMony(item){
				if(item.status != 1 || this.hoopList.maintenance === 1){
					ElMessage({
						message: '此面值暂时维护无法提交',
						type: 'warning',
					})
					return
				}
				if(item.face_value === '0.00'){
					this.fwdfgtrhy = item
					this.dialogVisible = true
					return
				}
				this.faceDetail = item
			},
			async getShopData(){
				this.loading = true
				if(this.token){
					await this.userInfo()
				}
				await this.getCategoryList()
				await this.getGoodsList()
				this.searchList = []
				this.cate_id = ""
				this.good_id = ""
				this.loading = false
			},
			userInfo(){
				return new Promise((resolve,reject) => {
					this.$api
					.userInfo({})
					.then(result => {
						this.userList = result.data
						resolve(true)
					})
				})
			},
			seoMain(){
				this.$api.getGoodsSystem({
					card_id:this.hoopList.id
				})
				.then(result => {
					
					console.log(result)
					this.seoAdd(result.data)
				})
			},
			seoConformity(property,title){
				const newMeta = document.createElement('meta');
			  if(document.querySelector(`meta[property="${property}"]`)){
			    document.querySelector(`meta[property="${property}"]`).setAttribute('content',title)
			    return false
			  }else{
			    newMeta.setAttribute('property', property);
			    newMeta.setAttribute('content', title);
			    return newMeta
			  }
			},
			seoAdd(detail){
				let title = detail.card_seo_title
				let Keywords = detail.card_seo_keywords
				let Description = detail.card_seo_description
				document.title = title
				let seoMain = [
					{
						property:'og:type',
						value:'webpage',
					},
					{
						property:'og:title',
						value:title,
						fixation:false
					},
					{
						property:'og:site_name',
						value:'团团收',
						fixation:true
					},
					{
						property:'og:url',
						value:window.location.href,
						fixation:true
					},
				]
				let titles = ""
				let bswv = ""
				for(let i=0;i<seoMain.length;i++){
					titles = seoMain[i].value
				
				  bswv = this.seoConformity(seoMain[i].property,titles)
				  if(bswv){
				    document.head.appendChild(bswv);
				  }
				}
				
				
				let meta_web = document.createElement('meta');
				if(document.querySelector('meta[name="keywords"]')){
				  document.querySelector('meta[name="keywords"]').setAttribute('content',Keywords)
				}else{
				  meta_web.setAttribute('name','keywords')
				  meta_web.setAttribute('content',Keywords)
				  document.getElementsByTagName('head')[0].appendChild(meta_web)
				}
				let meta_description = document.createElement('meta');
				if(document.querySelector('meta[name="description"]')){
				  document.querySelector('meta[name="description"]').setAttribute('content',Description)
				}else{
				  meta_description.setAttribute('name','description')
				  meta_description.setAttribute('content',Description)
				  document.getElementsByTagName('head')[0].appendChild(meta_description)
				}
			},
			getGoodsList() {
				return new Promise((resolve,reject) => {
					this.clearnData()
					this.$api
					  .getGoodsList({
					    cate_id: this.kindList.cate_id,
					    account: this.userList ? this.userList.user_id : "",
					  })
					  .then((res) => {
					    if (res.code === 1) {
					      this.goodsList = res.data;
					      for (let i = 0; i < this.goodsList.length; i++) {
					        if (this.good_id === this.goodsList[i].id) {
					          this.faceList = this.goodsList[i].products;
					          this.hoopList = this.goodsList[i];
					        }
					      }
								if (!this.good_id) {
								  this.faceList = this.goodsList[0].products;
								  this.hoopList = this.goodsList[0];
								}
								this.faceDetail = {}
								for(let i=0;i<this.faceList.length;i++){
									if(this.faceList[i].face_value != '0.00' && (this.hoopList.maintenance != 1 && this.faceList[i].status == 1 )){
										this.faceDetail = this.faceList[i]
										break
									}
								}
								if(this.faceDetail.form_num  === 1 || this.faceDetail.form_num  === 2 || this.faceDetail.form_num === 3 || this.faceDetail.form_num === 8){
									this.tabIndex = 2
								}
								if(this.faceDetail.form_num  === 4 || this.faceDetail.form_num  === 6 ){
									this.tabIndex = 3
								}
								if(this.faceDetail.form_num  === 5 ){
									this.tabIndex = 4
								}
								this.articleHotpc()
								this.seoMain()
					    }
							resolve(true)
					  });
				})
			},
			getCategoryList(){
				return new Promise((resolve,reject) => {
					this.$api.list()
					.then(res => {
						if(res.code === 1){
							console.log(res)
							for (let i = 0; i < res.data.length; i++) {
							  if (res.data[i].cate_id === this.cate_id) {
							    this.kindList = res.data[i];
							  }
							  res.data[i].class = this.classList[i];
							  res.data[i].icon = this.iconList[i];
							}
							if (!this.cate_id) {
							  this.kindList = res.data[0];
							}
							this.cardList = res.data;
						}
						resolve(true)
					})
				})
			},
			postSubmit(){
				if(!this.token){
					// ElMessage({
					//   message: "请先登录后在提交卡券",
					//   type: "warning",
					// });
					this.loginEcycle = true
					setTimeout(() => {
						
						// this.$router.push({path:"/login"})
					},1000)
					return
				}
				if(!this.userList.phone){
					ElMessage({
					  message: "提交卡券请先绑定手机号",
					  type: "warning",
					});
					setTimeout(() => {
						this.$router.push( {path:'/user/userDatum',query:{type:4}})
					},1000)
					return
				}
				if(this.faceDetail.face_value === "0.00"){
					if(!this.custom){
						ElMessage({
						  message: "请选择面值",
						  type: "warning",
						});
						return
					}
				}
				if(Object.keys(this.faceDetail).length == 0){
					ElMessage({
					  message: "请选择面值",
					  type: "warning",
					});
					return
				}
				if(!this.check2){
					ElMessage({
					  message: "请阅读并同意团团收转让协议以及礼品卡回收说明",
					  type: "warning",
					});
					return
				}
				if(!this.check3){
					ElMessage({
					  message: "请确认卡券来源合法",
					  type: "warning",
					});
					return
				}
				
				if(this.tabIndex === 1 || this.tabIndex === 4){
					this.oncePostOrder()
				}else{
					this.bathPostOrder()
				}
			},
			bathPostOrder(){
				if(this.tabIndex === 2){
					if(!this.cpVal){
						ElMessage({
						  message: "请输入内容",
						  type: "warning",
						});
					}
					let list = this.cpVal.split('\n')
					for(let i=0;i<list.length;i++){
						if(list[i] === '' || list[i] === " "){
							list.splice(i,1)
						}
					}
					this.cpVal = list.join('\n')
					this.new_baty = []
					for(let i=0;i<list.length;i++){
						if(this.faceDetail.form_num === 1){
							let list1 = list[i].split(' ')
							if(this.hoopList.goods_name === "京东e卡(全品类)"){
								list1[0] = list1[0].replace(/[-]/g,'')
								list1[1] = list1[1].replace(/[-]/g,'')
							}
							if(!this.bathfaceForm_num1(list1[0],list1[1],i+1)){
								return
							}
						}else if(this.faceDetail.form_num === 2){
							if(!this.bathfaceForm_num2(list[i],i+1)){
								return
							}
						}else if(this.faceDetail.form_num === 3){
							if(!this.bathfaceForm_num3(list[i],i+1)){
								return
							}
						}else if(this.faceDetail.form_num === 8){
							list[i] = list[i].replace(/[-]/g,'')
							if(!this.bathfaceForm_num3(list[i],i+1)){
								return
							}
							console.log(this.new_baty)
							this.new_baty[i] = list[i] + ' ' + list[i]
						}
					}
				}else if(this.tabIndex === 3){
					if(this.imgList.length < 1){
						ElMessage({
						  message: "请选择图片",
						  type: "warning",
						});
						return
					}
					if(this.imgList.length > 10){
						ElMessage({
						  message: "一次最多提交10张图片",
						  type: "warning",
						});
						return
					}
				}else{
			
				}
				if(this.faceDetail.term > 0){
					if(this.data_time){
						this.day_time2(this.data_time,this.date_now)
					}else{
						ElMessage({
						  message: "请输入有效期",
						  type: "warning",
						});
					}
				}
				this.bathOrder()
			},
			oncePostOrder(){
				if(this.hoopList.goods_name === "京东e卡(全品类)"){
					this.card = this.card.replace(/[-]/g,'')
					this.card_pass = this.card_pass.replace(/[-]/g,'')
				}
				if(this.faceDetail.form_num === 1){
					if(!this.faceForm_num1()){
						return
					}
				}else if(this.faceDetail.form_num === 2){
					if(!this.faceForm_num2()){
						return
					}
				}else if(this.faceDetail.form_num === 3){
					if(!this.faceForm_num3()){
						return
					}
				}else if(this.faceDetail.form_num === 8){
					this.card_pass = this.card_pass.replace(/[-]/g,'')
					if(!this.faceForm_num3()){
						return
					}
					this.card = this.card_pass
				}else if (this.faceDetail.form_num === 5){
					if(!this.text_tar){
						ElMessage({
						  message: "请输入文本",
						  type: "warning",
						});
						return
					}
				}
				if(this.faceDetail.term > 0){
					if(this.data_time){
						this.day_time2(this.data_time,this.date_now)
					}else{
						ElMessage({
						  message: "请输入有效期",
						  type: "warning",
						});
					}
				}
				this.onceOrder()
			},
			clearnData(){
				this.time_number = ""
				this.card = ""
				this.card_pass = ""
				this.text_tar = ""
				this.cpVal = ""
				this.imgList = []
			},
			bathOrder(){
				this.buttonLoging = true
				let jdw = this.new_baty?this.new_baty.join(','):""
				let objStr = this.cpVal
				if(this.hoopList.goods_name === "京东e卡(全品类)"){
					objStr = objStr.replace(/[-]/g,'')
				}
				this.$api.batchPostOrder({
					product_no:this.faceDetail.product_no,
					batchBuy: this.faceDetail.form_num === 8?jdw:this.faceDetail.form_num  === 4|| this.faceDetail.form_num  === 6?this.imgList.join(','):objStr.split('\n').join(','),
					form_face:this.custom?this.custom:"",
					card_date: this.time_number,
					type:config.postType
				}).then(res =>{
					if(res.code == 1){
							this.orderSuccess = true
					}else{
						ElMessage({
						  message: res.msg,
						  type: "error",
						});
					}
					this.buttonLoging = false
				})
			},
			onceOrder(){
				this.buttonLoging = true
				let validity = this.faceDetail.form_num === 9?this.cvv_pass:""
				this.$api.postOrder({
					product_no:this.faceDetail.product_no,
					card: this.card,
					card_pass: this.card_pass,
					card_date: this.time_number,
					images : "",
					texts:this.text_tar?this.text_tar:"",
					form_face:this.custom?this.custom:"",
					validity:validity,
					type:config.postType
				}).then(res => {
					if(res.code === 1){
							this.orderSuccess = true
					}else{
						ElMessage({
							message: res.msg,
							type: "error",
						});
					}
					this.buttonLoging = false
				})
			},
			bathfaceForm_num1(card,card_pass,i){
				if(!card){
					ElMessage({
					  message: "请检查第"+i+"行是否输入卡号",
					  type: "warning",
					});
					return false
				}
				if(!card_pass){
					ElMessage({
					  message: "请检查第"+i+"行是否输入卡密	",
					  type: "warning",
					});
					return false
				}
				if(!this.judegType(card,1,i)){
					return false
				}
				if(!this.judegType(card_pass,2,i)){
			
					return false
				}
				if(!this.judgeLength(card,1,i)){
			
					return false
				}
				if(!this.judgeLength(card_pass,2,i)){
			
					return false
				}
				return true
			},
			bathfaceForm_num2(card,i){
				if(!card){
					ElMessage({
					  message: "请检查第"+i+"行是否输入卡号",
					  type: "warning",
					});
					return false
				}
				if(!this.judegType(card,1,i)){
					return false
				}
				if(!this.judgeLength(card,1,i)){
			
					return false
				}
				return true
			},
			bathfaceForm_num3(card_pass,i){
				if(!card_pass){
					ElMessage({
					  message: "请检查第"+i+"行是否输入卡密	",
					  type: "warning",
					});
					return false
				}
			
				if(!this.judegType(card_pass,2,i)){
			
					return false
				}
			
				if(!this.judgeLength(card_pass,2,i)){
			
					return false
				}
				return true
			},
			faceForm_num1(){
				if(!this.card){
					ElMessage({
					  message: "请输入卡号",
					  type: "warning",
					});
					return false
				}
				if(!this.card_pass){
					ElMessage({
					  message: "请输入卡密",
					  type: "warning",
					});
					return false
				}
				if(!this.judegType(this.card,1)){
					return false
				}
				if(!this.judegType(this.card_pass,2)){
					return false
				}
				if(!this.judgeLength(this.card,1)){
					return false
				}
				if(!this.judgeLength(this.card_pass,2)){
					return false
				}
			
				return true
			},
			faceForm_num2(){
				if(!this.card){
					ElMessage({
					  message: "请输入卡号",
					  type: "warning",
					});
					return false
				}
				if(!this.judegType(this.card,1)){
					return false
				}
				if(!this.judgeLength(this.card,1)){
					return false
				}
			
				return true
			},
			faceForm_num3(){
				if(!this.card_pass){
					ElMessage({
					  message: "请输入卡密",
					  type: "warning",
					});
					return false
				}
				if(!this.judegType(this.card_pass,2)){
					return false
				}
				if(!this.judgeLength(this.card_pass,2)){
					return false
				}
				return true
			},
			once_digit(str,type){
						if(type === 1){
							if((this.faceDetail.card_short<=str.length && str.length<=this.faceDetail.card_length)){
								return true
							}else{
								return false
							}
						}else{
							if((this.faceDetail.pass_short<=str.length && str.length<=this.faceDetail.pass_length)){
								return true
							}else{
								return false
							}
						}
			
			},
			judgeLength(str,type,i){
				if(i){
					if(type === 1){
						if(this.faceDetail.card_length === 0){
							return true
						}
						let num = this.once_digit(str,type)
						if(!num){
							ElMessage({
							  message: '请检查第'+i+"行卡号位数"+'卡号应为'+this.faceDetail.card_short + '位~' + this.faceDetail.card_length + '位',
							  type: "warning",
							});
							return false
						}
						return true
					}else{
						if(this.faceDetail.pass_length === 0){
							return true
						}
						let num = this.once_digit(str,type)
						if(!num){
							ElMessage({
							  message: '请检查第'+i+"行卡密位数"+'卡密应为'+this.faceDetail.pass_short + '位~' + this.faceDetail.pass_length + '位',
							  type: "warning",
							});
							return false
						}
						return true
					}
				}else{
					if(type === 1){
						if(this.faceDetail.card_length === 0){
							return true
						}
						let num = this.once_digit(str,type)
						if(!num){
							ElMessage({
							  message: '卡号应为'+this.faceDetail.card_short + '位~' + this.faceDetail.card_length + '位',
							  type: "warning",
							});
							return false
						}
						return true
					}else{
						if(this.faceDetail.pass_length === 0){
							return true
						}
						let num = this.once_digit(str,type)
						if(!num){
							ElMessage({
							  message: '卡密应为'+this.faceDetail.pass_short + '位~' + this.faceDetail.pass_length + '位',
							  type: "warning",
							});
							return false
						}
						return true
					}
				}
			
			},
			judegType(str,type,i){
				if(i){
					if(type === 1){
						let num =  this.card_re(str)
						if(num){
							let msg = num === 2?'纯字母':num === 3?"纯数字":num === 4?"字母加数字":"判断错误请联系客服"
							ElMessage({
							  message: "请检查第"+i+"行是否为"+msg + '的卡号',
							  type: "warning",
							});
							return false
						}
						return true
					}else{
						let num =  this.card_pass_re(str)
						if(num){
							let msg = num === 2?'纯字母':num === 3?"纯数字":num === 4?"字母加数字":"判断错误请联系客服"
							ElMessage({
							  message: "请检查第"+i+"行是否为"+msg + '的卡密',
							  type: "warning",
							});
							return false
						}
						return true
					}
				}else{
					if(type === 1){
						let num =  this.card_re(str)
						if(num){
							let msg = num === 2?'纯字母':num === 3?"纯数字":num === 4?"字母加数字":"判断错误请联系客服"
							ElMessage({
							  message: "请输入"+msg + '的卡号',
							  type: "warning",
							});
							return false
						}
						return true
					}else{
						let num =  this.card_pass_re(str)
						if(num){
							let msg = num === 2?'纯字母':num === 3?"纯数字":num === 4?"字母加数字":"判断错误请联系客服"
							ElMessage({
							  message: "请输入"+msg + '的卡密',
							  type: "warning",
							});
							return false
						}
						return true
					}
				}
			},
			// 批量卡号卡密正则判断
			card_re(card){
				if (this.faceDetail.card_g_type == 1) {
					//1不需要规则
			
				} else if (this.faceDetail.card_g_type == 2) {
					//2纯字母
					let patrn = /^[a-zA-Z]*$/;
					if (!patrn.test(card)) {
						return 2
					}
				} else if (this.faceDetail.card_g_type == 3) {
					//3纯数字
					let patrn = /^[0-9]*$/;
					if (!patrn.test(card)) {
						return 3
					}
				} else if (this.faceDetail.card_g_type == 4) {
					//4字母加数字
					let patrn = /^(?![^a-zA-Z]+$)(?!\D+$).{1,}$/
					if (!patrn.test(card)) {
						return 4
					}
				}
			},
			card_pass_re(card){
				if (this.faceDetail.pass_g_type == 1) {
					//1不需要规则
			
				} else if (this.faceDetail.pass_g_type == 2) {
					//2纯字母
					let patrn = /^[a-zA-Z]*$/;
					if (!patrn.test(card)) {
						return 2
					}
				} else if (this.faceDetail.pass_g_type == 3) {
					//3纯数字
					let patrn = /^[0-9]*$/;
					if (!patrn.test(card)) {
						return 3
					}
				} else if (this.faceDetail.pass_g_type == 4) {
					//4字母加数字
					let patrn = /^(?![^a-zA-Z]+$)(?!\D+$).{1,}$/
					if (!patrn.test(card)) {
						return 4
					}
				}
			},
			day_time2(day_inp,day_now){
				let a = day_inp.replace(/\-/g, "/") ;
				let b = day_now.replace(/\-/g, "/") ;
				a = a.split('/')
				b = b.split("/")
				let num = 0
				let num1 = 0
				let num2 = 0
				let day = 0
				if(a[0] === b[0]){
	
				}else{
					num = a[0]-b[0]
				}
				if(a[1] === b[1]){
	
				}else{
					 num1 = a[1] - b[1]
				}
				if(a[2] === b[2]){
	
				}else{
					 num2 = a[2] - b[2]
				}
				if(num != 0){
					day += 365*num
				}
				if(num1 !=0 ){
					day += num1 * 30
				}
				if(num2 != 0){
					day += num2
				}
				this.time_number = day
			},
			onInput() {
			  if (this.faceDetail["form_num"] === 1) {
			    this.checkText1(this.cpVal);
			  }
			  if (this.faceDetail["form_num"] === 2) {
			    if (this.faceDetail["card_length"] > 0) {
			      this.checkText2(this.cpVal);
			    }else{
						this.cpVal =  this.cpVal.replace(/\s/g, "\n")
					}
			  }
			  if (this.faceDetail["form_num"] === 3 || this.faceDetail["form_num"] === 8) {
			    if (this.faceDetail["pass_length"] > 0) {
			      this.checkText3(this.cpVal);
			    }else{
						this.cpVal =  this.cpVal.replace(/\s/g, "\n")
					}
			
			  }
			},
			checkText1(str) {
			  let rowstrs = str
			    .replace(/\n/g, "")
			    .replace(/\s+/g, "")
			    .replace(/([^\u0000-\u00FF])/g, "")
			    .replace(/-/g, "");
			  let newarrys = "";
			  if (
			    this.faceDetail["card_length"] > 0 &&
			    this.faceDetail["pass_length"] > 0 &&
					this.faceDetail["card_length"] == this.faceDetail['card_short'] &&
					this.faceDetail['pass_length'] == this.faceDetail['pass_short']
			  ) {
			    while (rowstrs.length > 0) {
			      if (rowstrs.length >= this.faceDetail["card_length"]) {
			        let cNo = rowstrs.substring(0, this.faceDetail["card_length"]);
			        let cPwd = "";
			        rowstrs = rowstrs.substring(this.faceDetail["card_length"]);
			        if (rowstrs.length >= this.faceDetail["pass_length"]) {
			          cPwd = rowstrs.substring(0, this.faceDetail["pass_length"]);
			          rowstrs = rowstrs.substring(this.faceDetail["pass_length"]);
			        } else {
			          cPwd = rowstrs;
			          rowstrs = "";
			        }
			        if (cPwd.length === this.faceDetail["pass_length"]) {
			          newarrys += cNo + (" " + cPwd + "\n");
			        } else {
			          newarrys += cNo + (" " + cPwd + "");
			        }
			      } else {
			        newarrys += rowstrs;
			        rowstrs = "";
			      }
			    }
			    this.cpVal = newarrys;
			  }
			},
			checkText2(str) {
			  let rowstrs = str
			    .replace(/\n/g, "")
			    .replace(/\s+/g, "")
			    .replace(/([^\u0000-\u00FF])/g, "")
			    .replace(/-/g, "");
			  let newarrys = "";
			  if (this.faceDetail["card_length"] > 0) {
			    while (rowstrs.length > 0) {
			      if (rowstrs.length >= this.faceDetail["card_length"]) {
			        let cNo = rowstrs.substring(0, this.faceDetail["card_length"]);
			        rowstrs = rowstrs.substring(this.faceDetail["card_length"]);
			        if (cNo.length === this.faceDetail["card_length"]) {
			          newarrys += cNo + "\n";
			        } else {
			          newarrys += cNo + "";
			        }
			      } else {
			        newarrys += rowstrs;
			        rowstrs = "";
			      }
			    }
			    this.cpVal = newarrys;
			  }
			},
			checkText3(str) {
			  let rowstrs = str
			    .replace(/\n/g, "")
			    .replace(/\s+/g, "")
			    .replace(/([^\u0000-\u00FF])/g, "")
			    .replace(/-/g, "");
			  let newarrys = "";
			  if (this.faceDetail["pass_length"] > 0) {
			    while (rowstrs.length > 0) {
			      if (rowstrs.length >= this.faceDetail["pass_length"]) {
			        let cPwd = rowstrs.substring(0, this.faceDetail["pass_length"]);
			        rowstrs = rowstrs.substring(this.faceDetail["pass_length"]);
			        if (cPwd.length === this.faceDetail["pass_length"]) {
			          newarrys += cPwd + "\n";
			        } else {
			          newarrys += cPwd + "";
			        }
			      } else {
			        newarrys += rowstrs;
			        rowstrs = "";
			      }
			    }
			    this.cpVal = newarrys;
			  }
			},
		}
	}
</script>

<style scoped>
	.qwfgreyyhyuu{
		background-color: #f7f7f7;
	}
	.elCard-qwddd{
		border: none;
	}
	.el-card + .el-card{
		margin-top: 16px;
	}
	/deep/.pop-radius{
		border-radius: 16px;
	}
	.mt60{
		margin-top: 60px;
	}
	.mt5{
		margin-top: 5px;
	}
	.eshop-top-bg{
		background-image: url('../../assets/card/top-bg.png');
		width: 100%;
		padding: 50px 0;
	}
	.eshop-top-bg-item{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.qfdsqw-input{
		width: 472px;
		height: 40px;
	}
	.wedazx-button{
		background: linear-gradient(180deg, #FF4D4D 0%, #FF9052 100%);
		border-radius: 8px;
		color:#fff!important;
		margin-left: 12px;
		min-width: 64px;
		height: 40px;
	}
	.user-notice{
		border-radius: 16px;
		/* border: 1px dashed #E5E5E5; */
		padding: 24px;
		/* width: 401px; */
	}
	.hint-goods{
		background-color: #0EB6FF;
		width: 4px;
		height: 18px;
		margin-right: 16px;
	}
	.hint-title{
		color: #333;
		font-size: 20px;
	}
	.card-list-bg1{
		background: #0EB6FF;
	}
	.card-list-bg2{
		background: #F95378;
	}
	.card-list-bg3{
		background: #FFA841;
	}
	.card-list-bg4{
		background: #CC87FF;
	}
	.card-list-bg5{
		background: #43DB8E;
	}
	.card-list-bg6{
		background: #FE7144;
	}
	.card-list-bg7{
		background: #B2E03A;
	}
	.card-list-bg8{
		background: #FFA841;
	}
	.card-list-bg9{
		background: #FE7144;
	}
	.card-list-item{
		border-radius: 12px;
		padding: 15px 0;
		width: 180px;
		width: calc(100%/8 - 16px);
		margin-right: 16px;
		margin-top:16px;
		color: #fff;
		font-size: 18px;
		transition: .3s;
		position: relative;
		box-shadow: 0px 0px 12px rgba(0, 0, 0, .12);;
	}
	.card-list-item img{
		margin-right: 14px;
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
	}
	.card-list-item:hover{
		opacity: .8;
	}
	.card-list-item:hover img {
		-webkit-animation-name: swing;
		animation-name: swing;
	}
	.card-list-item-active{
		position: absolute;
		right: 1px;
		bottom: 0;
		width: 18px;
		height: 16px;
		background-image: url('../../assets/card/lefa.png');
		font-size: 10px;
		text-align: center;
		line-height: 16px;
	}
	.goods-list-item-active{
		position: absolute;
		right: -2px;
		bottom: 0;
		width: 18px;
		height: 16px;
		background-image: url('../../assets/card/lefa-red.png');
		font-size: 10px;
		text-align: center;
		line-height: 16px;
	}

	.dqwgfdcar + .dqwgfdcar{
		margin-top:40px;
	}
	.goods-list-item{
		border-radius: 8px;
		border: 2px solid #E9F1FF;
		background: #FBFDFF;
		width: 150px;
		height: 90px;
		font-size: 16px;
		color: #333;
		text-align: center;
		padding-top: 15px;
		transition: .3s;
		position: relative;
		margin-right: 16px;
		margin-top:16px;
	}
	.goods-list-item .dqwfdsfyhxc{
		width: 32px;
		height: 32px;
	}
	.goods-imgwd{
		border-radius: 8px;
		background: #FFF;
		width: 40px;
		height: 40px;
		margin: 0 auto;
		line-height: 40px;
		margin-bottom: 8px;
	}
	.goods-active{
		border-color: #FF7544!important;
		background: #F8FAFC!important;
	}
	.goods-list-item:hover{
		border-color: #FF7544;
	}
	.face-list-item{
		border-radius: 8px;
		border: 2px solid #E9F1FF;
		background: #FBFDFF;
		width: 150px;
		font-size: 16px;
		color: #333;
		padding: 11px 12px;
		transition: .3s;
		position: relative;
		margin-right: 16px;
		margin-top:16px;
	}
	.face-list-item:hover{
		border-color: #FF7544;
	}
	.face-list-item-sdw{
		width: 50px;
		height: 16px;
		border-radius: 4px;
		border-top-right-radius: 8px;
		text-align: center;
		font-size: 12px;
		position: absolute;
		top: 0;
		right: 0;
	}
	.face-list-item-dis{
		background-color: #FFF4D8;
		color: #F9A72E;
	}
	.face-list-item-tain{
		background-color: #F4F4F4;
		color: #333;
	}
	.fwffsfctu-input{
		font-size: 18px;
		
		height: 40px;
		width: 280px;
		margin: 0 auto;
		margin-top: 33px;
	}
	.curr-button{
		background: #0EB6FF;
		border-radius: 8px!important;
		color: #fff!important;
		height: 60px!important;
		width: 316px!important;
	}
	/deep/.el-radio.el-radio--large .el-radio__input .el-radio__inner{
		width: 20px;
		height: 20px;
	}
	/deep/.el-radio.el-radio--large .el-radio__input .el-radio__inner::after{
		width: 8px;
		height: 8px;
	}
	/deep/.el-radio.el-radio--large .el-radio__label{
		font-size: 18px;
	}
	.product-fze{
		margin-top: 24px;
		color: #737373;
		font-size: 16px;
	}
	.fomr-inputs{
		width: 316px;
		height: 52px;
	}
	.fomr-input-main + .fomr-input-main{
		margin-top: 16px;
	}
	.fomr-textarea{
		/* width: 940px; */
		height: 265px;
	}
	/deep/.fomr-textarea textarea{
		/* width: 940px; */
		height: 265px;
		line-height: 24px;
		background-image: linear-gradient(transparent 50%, transparent, #fcfcfc 0);
		/* background-image: linear-gradient(transparent 98%,transparent,#EEE 0); */
		background-size: 50px 50px;
	}
	.caution{
		position: absolute;
		right: 0;
		width: 30%;
		height: 265px;
		background-color: rgba(14, 182, 255, 0.05);
		top: 0;
		padding: 0 28px;
	}
	.fwefbhjk img{
		width: 12px;
		height: 23px;
		margin-right: 16px;
	}
	.fwefbhjk img:last-child{
		margin-right: 0;
	}
	.present{
		margin-top: 40px;
		border-top: 1px dashed #D9D9D9;
		padding-top: 28px;
	}
	
	/deep/.el-checkbox__inner{
		width: 15px;
		height: 15px;
	}
	/deep/.el-checkbox__inner::after{
		width: 3px;
		height: 9px;
		left: 6px;
	}
	/deep/.el-checkbox__label{
		font-size: 16px;
	}
	/deep/.el-checkbox+.el-checkbox{
		margin-top: 20px;
	}
	.protocol{
		max-height: 600px;
		overflow-x: auto;
	}
	.card-hide{
		background-color: #f7f7f7;
		width: 96%;
		height: 5px;
		margin: 20px  0;
	}
	.dsqw-card{
		/* min-width: 600px; */
	}
	.hot-article-item{
		/* text-align: center; */
	}
		.hot-article-item:hover > .qwdsfimg{
			transform: scale(1.1); /* 鼠标悬停时，图片放大1.1倍 */
		}
		.hot-article-item:hover > .hot-fasfqw{
			color: #0EB6FF;
		}
		.hot-article-item .qwdsfimg{
			width: 100%;
			height: 168px;
			transition: transform 0.3s ease; /* 平滑变化 */
		}
		.hot-article-item:nth-child(3n){
			margin-right: 0;
		}
		.hot-fasfqw{
			margin-top: 20px;
			font-size: 18px;
			color: #333333;
			transition: 0.3s;
		}
		.hot-article-item{
			margin-top: 20px;
			margin-right: 5%;
			width: calc(100% / 3 - 5%);
			cursor: pointer;
		}
</style>