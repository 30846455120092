<template>
	<div class="user-bg-swg html-two">
		<div>
			<uerHeader class="bg_fff" type=""></uerHeader>
			<div class="">
				<el-row :gutter="24" class=" wdsffdzcdtg mt40">
					<el-col :span="4" :offset="2">
						<userLeft></userLeft>
					</el-col>
					<el-col :span="16" >
						<div class="m-content fix">
							<div class="user-main" >
								<div class="bg_fff  rounded rfwwd" v-loading="loading">
									<div class="pd24">
										<div class="withdraw-type">
											<router-link class="active"   :to="{path:'/user/withdraw'}">提现</router-link>
											<router-link :to="{path:'/user/withdraw_record'}">提现记录</router-link>
										</div>
										<div class="withdraw-balance flex alcenter" style="margin: 0 auto;width:700px;margin-top: 40px;">
											<img  src="../../assets/user/withdraw/balance.png"  />
											<div class="ml16">
												<div class="c_737373 ft14">账户余额</div>
												<div class="mt10 ft28">{{userInfo.balance}}</div>
											</div>
										</div>
										<div style="margin: 0 auto;width:700px">
											<div class="mt50">
												<el-form :model="form" label-width="120px" class="mt50" label-position="left" size="large">
													<el-form-item label="提现方式">
														<el-radio-group v-model="form.tage" class='uyezh'  @change="tabClick">
															<template v-for="(item,index) in tabList" :key="index">
																<el-radio :label="item.tage"   v-if="item.whether">
																	<div class="withdraw-account flex alcenter">
																		<img :src="item.img" />
																		<div class="ml16">{{item.name}}</div>
																	</div>
																</el-radio>
															</template>
														</el-radio-group>
													</el-form-item>
													<el-form-item label="提现账号">
														<el-select v-model="form.wid" placeholder="请选择提现账号" v-if="withdrawAllList.length > 0">
															<el-option :label="form.tage === 1?item.account:form.tage === 3?item.nickName:item.bank_name +'-'+ item.account" :value="item.id"  v-for="(item,index) in withdrawAllList" :key="index"  />
														</el-select>
														<router-link :to="{path:'/user/userDatum',query:{type:6}}" class="dwqdasc" v-else >
															添加/管理账号
														</router-link>
													</el-form-item>
													<el-form-item label="提现金额">
														<el-input v-model="form.money" type="number"  class="sdqwff-input"/>
													</el-form-item>
												</el-form>
											</div>
											<el-button color="#0EB6FF"  class="withdraw-buttfona mt40" @click='withdrawPrice'>提现</el-button>
											<div class="mt20 " style="font-size:18px;font-weight: 600;">提现规则</div>
											<div class="mt10" v-html="face_remark"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<userBottom></userBottom>
		<el-dialog v-model="dialogVisible" title="" width='522px' custom-class="pop-radius">
			<div class="text-center">
				<p class="ft24 font-weight700 c_333333">为了保障您的权限，请实名签署</p>
				<div class="ft18 c_737373 mt24 prl70">为了保障您的权益和团团收收卡正常合法经营，需实名认证并且签署承诺书，才能使用销卡服务及提现操作，请您仔细阅读并签署！</div>
				<div class="prl70 mt40 ">
					<el-input placeholder="请填写真实姓名" class="real-inputd" />
					<el-input  placeholder="请输入身份证号" class="real-inputd" />
					<el-button color="#0EB6FF" class="main-button mt20">确认提交</el-button>
				</div>
			</div>
			<template #footer>
				<span class="dialog-footer">
				</span>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import uerHeader from '../../components/header/card.vue'
	import userLeft from '../../components/left/index.vue'
	import userBottom from '../../components/footer/user.vue'
	import { ElMessage, ElMessageBox } from 'element-plus'
	export default{
		data(){
			return{
				tage:1,
				dialogVisible:false,
				tabList:[
					{
						"name":"支付宝账号",
						'whether':false,
						"tage":1,
						"img":require("../../assets/user/withdraw/alipay.png")
					},
					{
						"name":"微信零钱",
						"tage":3,
						'whether':false,
						"img":require("../../assets/user/withdraw/wx.png")
					},
					{
						"name":"银行卡",
						"tage":2,
						'whether':false,
						"img":require("../../assets/user/withdraw/ubank.png")
				}],
				face_remark:"",
				money:"",
				userInfo:{},
				withdrawId:null,
				withdrawAllList:[],
				loading:false,
				form:{
					money:"",
					wid:"",
					tage:1
				}
			}
		},
		components:{
			uerHeader,
			userLeft,
			userBottom
		},
		created() {
			this.getData()
		},
		methods:{
			// 提现接口
			withdrawPrice(){
				let _this = this
				let wid = this.form.wid?this.form.wid:""
				if(!wid){
					ElMessage({
						message: '请先选择提现账号',
						type: 'warning',
					})
					return
				}
				this.$api.withdrawPrice({
						prices: this.form.money,
						prices_type: this.form.tage,
						w_id:wid,
					})
					.then(result => {
						if(!result.msg || result.msg === ''){
							ElMessage({
								message: '系统错误请联系客服',
								type: 'error',
							})
							return
						}
						if(result.code === 1){
							ElMessage({
								message: result.msg,
								type: 'success',
							})
						}else{
							ElMessage({
								message: result.msg,
								type: 'error',
							})
						}

						if(result.data === 'err'){
							setTimeout(() => {
								this.$router.push('/')
							},1000)
							return
						}
						if(result.code === 1){
							setTimeout(() => {
								this.getData()
							},1000)
						}
					})
			},
			tabClick(){
				this.loading = true
				this.getWithdrawNotice()
				this.withdrawList()
				this.form.wid = ""
			},
			HTMLDecode(text) {
				var temp = document.createElement("div");
				temp.innerHTML = text;
				var output = temp.innerText || temp.textContent;
				temp = null;
				return output;
			},
			async getData(){
				this.loading = true
				await this.getUserInfo()
				if(await !this.loadDetail1()){
					return
				}
				if(!this.userInfo.phone){
					ElMessageBox.confirm(
							'请先绑定手机号',
							'警告',
							{
								confirmButtonText: '确认',
								cancelButtonText: '关闭',
								type: 'warning',
							}
						)
						.then(() => {
							this.$router.push( {path:'/user/userDatum',query:{type:4}})
						})
						.catch(() => {
							this.$router.push('/')
						})
				}
				this.getWithdrawType()
			},
			getWithdrawType(){
				this.$api.getWithdrawType({
				}).then(res => {
					if(res.code === 1){
						if(res.data.aliPay === 1){
							this.tabList[0].whether = true
						}
						if(res.data.wxPay === 1){
							this.tabList[1].whether = true
						}
						if(res.data.bankPay === 1){
							this.tabList[2].whether = true
						}
						for(let i=0;i<this.tabList.length;i++){
							if(this.tabList[i].whether){
								this.form.tage = this.tabList[i].tage
								break
							}
						}

						this.getWithdrawNotice()
						this.withdrawList()
					}
				})
			},
			withdrawList(){
				this.$api.getWithdrawList({
					type:this.form.tage
				}).then(res => {
					console.log(res)
					if(res.code === 1){
						this.withdraw = {
							id:null
						}
						this.withdrawAllList = res.data
						if(res.data.length > 0){
							this.withdrawId = res.data[0].id
						}

						this.loading = false
					}
				})
			},
			getWithdrawNotice(){
				this.$api.getWithdrawNotice({
					type:this.form.tage
				}).then(res => {
					this.face_remark = this.HTMLDecode(res.data.content)
				})
			},
			loadDetail1() {
				return new Promise((resolve,reject) => {
					this.$api.getUserRealStatus({
						type:1
					})
					.then(result => {
						if(!result.data.name){
							this.dialogVisible = true
						}
						resolve(!result.data.name?false:true)
					})
				})
			},
			getUserInfo(){
				return new Promise((resolve,reject) => {
					this.$api.userInfo()
					.then(result => {
						if(result.code === 1){
							this.userInfo = result.data
							localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
						}
						resolve(true)
					})
				})

			}
		}
	}
</script>

<style scoped>
 a{
	 text-decoration:none;
 }
/deep/.pop-radius{
	border-radius: 24px;
}
 .withdraw-balance{
	 margin-top:40px;
	 border-bottom: 1px dashed #E5E5E5;
	 padding-bottom:36px;
 }
 .withdraw-balance img{
	 width: 40px;
	 height: 40px;
 }
 .withdraw-account img{
	 width: 40px;
	 height: 40px;
 }
 .uyezh label {
	 margin-right: 60px;
 }
 .uyezh label:last-child{
	 margin-right:0;
 }
 .withdrwa-form-main{

 }
 .withdrwa-form-main .witg{
	 width: 180px;
 }
 .withdrwa-form-main input{
	 
 }
 .sdqwff-input{
	 width: 230px;
 }
 .withdraw-buttfona{
	 width: 316px;
	 height: 60px;
	 border-radius: 8px;
	 color: #ffffff!important;
	 font-size: 18px;
 }
 /deep/ .real-inputd .el-input__wrapper{
	 background: #F7F9FC;
	 border-radius: 8px;
	 color: #B3B3B3;
	 height: 60px;
	 border-color: #F7F9FC;
 }
 .real-inputd + .real-inputd{
	 margin-top: 20px;
 }
 .dwqdasc{
	 color: #0EB6FF;
	 font-size: 14px;
 }
</style>