<template>
	<div>
		<div class="login-edxaet">
			<div class="login-mian alcenter">
				<el-link :underline='false' href="/" class="logi-ewr flex alcenter">
					<img  src="../../assets/card/logo-hear.png" style="height:38px;width: 114px;"/>
					<div class="c_b3b3b3 ft16 ml24">省心的卡券回收平台</div>
				</el-link>
				<div class="ft14 c_b3b3b3 freyh">
					<el-link :underline="false"  @click="openUrl('/notice/platform')">官方公告</el-link>
					<el-link :underline="false"  @click="openUrl('/notice/question')">帮助中心</el-link>
					<el-link :underline="false" @click="$router.push({path:'/problem'})">问题反馈</el-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				
			}
		},
		created() {
			
		},
		methods:{
			openUrl(url){
				this.$router.push({
					path:url
				})
			}
		}
	}
</script>

<style scoped lang="less">
	.login-edxaet{
		width: 100%;
		min-width:1440px;
		border-bottom: 1px solid  #e7e7e7;
		padding: 16px 0px;
		// line-height: 80px;
	}
	.login-mian{
		width: 1440px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
	}
	.logi-ewr{
		
	}
	.logi-ewr img{
		width: 130px;
		height: 48px;
		border-right:1px solid #EDEDED ;
		padding-right:11px;
	}
	.freyh a + a{
		margin-left: 40px;
	}
	.freyh a{
		font-size: 16px;
	}
</style>