<template>
  <div style="background: #fff" class="html" id="dwqad">
    <div class="session session1 "  >
      <div class="container">
        <cardHeader type="home"></cardHeader>
        <div class="introduction" >
					<div class="home-top-asdw-ttile">
						<div class="tagline text-left wow fadeInLeft" data-wow-duration="1s">专业卡券回收</div>
						<div class="tagline text-right  wow fadeInRight" data-wow-duration="1s">我选择团团收</div>
					</div>
          <!-- <div >专业卡券回收,     </div> -->
          <div class="search-container wow fadeInLeft"  data-wow-duration="1s">
            <div class="search-input-wrap">
							<el-select
								v-model="searchValue"
								filterable
								remote
								reserve-keyword
								placeholder="输入您想找、想出售的东西"
								:remote-method="remoteMethod"
								:loading="loading"
								class="search-input"
							>
								<el-option
									v-for="item in options"
									:key="item.id"
									:label="item.goods_name"
									:value="item.id"
								/>
							</el-select>
              <button class="search-button" @click="search">
                <img src="@/assets/home/search.png" alt="Search Icon" />
              </button>
            </div>
            <div class="search-suggestions">搜索: 
							<span  v-for="(item,index) in hotCard" :key="index">
								<span class="opoend-shop" v-if="index <= 4" @click="openHotShop(item)">
									{{item.goods_alias?item.goods_alias:item.goods_name}}
								</span>
								<span v-if="index <= 3">、</span>
							</span>
						</div>

          </div>
        </div>
<!--        <div class="swiper1">

        </div> -->
      </div>
    </div>
		<div class="swiper1-dasdasda qfsxchgh wow fadeInDown" >
			<div class="swiper-item-test" @click="openCardRecy({})">
				<div class="flex-center">
					<img src="@/assets/home/swipers/recycle.png" />
				</div>
				<div class="title">官方网站</div>
				<el-button color="#4cbff2" style="color:#fff">立即回收</el-button>
			</div>
			<div class="swiper-item-test" >
				<div class="flex-center">
					<img src="@/assets/home/swipers/app1.png" />
				</div>
				<div class="title" >APP</div>
				  <el-popover
				    placement="bottom"
				    :width="175"
				    trigger="hover"
						
				    content="this is content, this is content, this is content"
				  >
				    <template #reference>
				      <el-button class="m-2">立即下载</el-button>
				    </template>
						<template #default>
							<div class="dasdqw">
								<img src="../../assets/home/swipers/appdown.png" class="home-swiper-img" />
							</div>
						</template>
				  </el-popover>
			</div>
			<div class="swiper-item-test" >
				<div class="flex-center">
					<img src="@/assets/home/swipers/wx.png" />
				</div>
				<div class="title" >公众号</div>
				<el-popover
				  placement="bottom"
				  :width="175"
				  trigger="hover"
					
				  content="this is content, this is content, this is content"
				>
				  <template #reference>
				    <el-button class="m-2">扫码关注</el-button>
				  </template>
					<template #default>
						<div class="dasdqw">
							<img :src="systemsettings&&systemsettings.wxmp_img?systemsettings.wxmp_img.file_path:''" class="home-swiper-img" />
						</div>
					</template>
				</el-popover>
			</div>
			<div class="swiper-item-test" >
				<div class="flex-center">
					<img src="@/assets/home/swipers/cx1.png" />
				</div>
				<div class="title" >小程序</div>
				<el-popover
				  placement="bottom"
				  :width="175"
				  trigger="hover"
					
				  content="this is content, this is content, this is content"
				>
				  <template #reference>
				    <el-button class="m-2">立即使用</el-button>
				  </template>
					<template #default>
						<div class="dasdqw">
							<img src="@/assets/home/swipers/opencx.jpg" class="home-swiper-img" />
						</div>
					</template>
				</el-popover>
			</div>
<!-- 			<img
			  src="@/assets/home/Left.png"
			  class="prev"
			  @click="swiper1_prev"
				@mouseenter="swiper1_log_prev"
				@mouseout="swichBool"
			/>
			<swiper
			  :slides-per-view="5"
			  :centeredSlides="true"
				:speed="1000"
			  :loop="true"
				:delay="500"
				:watchSlidesProgress="true"
			  ref="swiper1_dwq"
				class="swiperc1"
				@slideChange="slideChange"
			>
			  <swiper-slide v-for="(item,index) in swiperClick"  :key="index" >
			    <div class="swiper-item" :style="{width:item.widthObj?item.widthObj+'!important':'240px!important'}" style="margin: 0 auto!important;" >
			      <div class="icons">
							<img :src="imgItem" v-for="(imgItem,imgIndex) in item.img" :key="imgIndex" />
			        <img src="@/assets/home/1692.png" />
			      </div>
			      <div class="title">{{item.title}}</div>
			      <a href="javascript:void(0);" @click="openCardRecy(item)">立即销卡</a>
			    </div>
			  </swiper-slide>
			</swiper>
			<img
			  src="@/assets/home/Right.png"
			  class="next"
			  @click="swiper1_next"
				@mouseenter="swiper1_log"
				@mouseout="swichBool"
			/> -->
		</div>
    <div class="session session2 wow fadeInRight" data-wow-duration="1s" id="flow">
      <div class="container">
        <div class="session-title">团团收专业回收流程</div>
        <div class="session-subtitle">省心的卡券回收平台</div>
        <div class="login-options-container">
          <div class="login-option">
            <div class="option-number">
              <span>1</span>
              <img class="option-icon" src="@/assets/home/1681.png" />
            </div>
            <div class="option-title">登录团团收的账号</div>
            <div class="option-description">
              可选择微信、手机号码等登录方式
            </div>
          </div>
          <div class="login-option">
            <div class="option-number">
              <span>2</span>
              <img class="option-icon" src="@/assets/home/1682.png" />
            </div>
            <div class="option-title">实名认证身份</div>
            <div class="option-description">
              根据国家法律法规，实名后方提现
            </div>
          </div>
          <div class="login-option">
            <div class="option-number">
              <span>3</span>
              <img class="option-icon" src="@/assets/home/1392.png" />
            </div>
            <div class="option-title">开始卡券转让</div>
            <div class="option-description">
              将闲置的卡券转让给平台，等待即可自动回收
            </div>
          </div>
          <div class="login-option">
            <div class="option-number">
              <span>4</span>
              <img class="option-icon" src="@/assets/home/1683.png" />
            </div>
            <div class="option-title">平台已验卡成功</div>
            <div class="option-description">
              在回收您的卡券后，会按照约定的价格返给您
            </div>
          </div>
          <div class="login-option">
            <div class="option-number">
              <span>5</span>
              <img class="option-icon" src="@/assets/home/1393.png" />
            </div>
            <div class="option-title">提现至您的账户</div>
            <div class="option-description">
               可选择微信、支付宝、银行卡多种方式
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="session session3 wow fadeInLeft" data-wow-duration="1s" >
      <div class="container">
        <div class="session-title">为什么要选择团团收</div>
        <div class="session-subtitle">灵活的回收策略，让您纵享无忧销卡</div>
        <div class="feature-container">
          <div class="feature-item">
            <img
              class="feature-icon"
              src="@/assets/home/jsyk.png"
              alt="极速验卡图标"
            />
            <div class="feature-title">极速验卡</div>
            <div class="feature-description">
              只需10秒时间，快速安全的验卡。点对点直接帮助用户回收卡。
            </div>
          </div>
          <div class="feature-item">
            <img
              class="feature-icon"
              src="@/assets/home/zjbz.png"
              alt="极速验卡图标"
            />
            <div class="feature-title">资金保障</div>
            <div class="feature-description">
              为广大用户提供安全可靠、简易操作的回收卡服务，平台实名认证更有保障。
            </div>
          </div>
          <div class="feature-item">
            <img
              class="feature-icon"
              src="@/assets/home/jydy.png"
              alt="极速验卡图标"
            />
            <div class="feature-title">交易多样</div>
            <div class="feature-description">
              提供线上回收、API接口等多种交易方式，自由定制实现交易多样化方式。
            </div>
          </div>
          <div class="feature-item">
            <img
              class="feature-icon"
              src="@/assets/home/yxfw.png"
              alt="极速验卡图标"
            />
            <div class="feature-title">用心服务</div>
            <div class="feature-description">
              我们会及时给与您关于使用过中的回复，免除您的后顾之忧。
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="session session4 ">
      <div class="container wow fadeInDown"  data-wow-duration="1s">
        <div class="session-title">支持百余种卡券回收</div>
        <div class="session-subtitle">放着也是放着，卡券回收即是传递价值</div>
        <div style="position: relative">
<!--          <div class="c-center">
            <img src="@/assets/home/c-pic01.png" />
          </div> -->
          <swiper
            :slides-per-view="9"
            :space-between="80"
            :modules="modules"
            :speed="2000"
            :autoplay="{
              delay: 0,
              disableOnInteraction: false,
            }"
            :loop="true"
          >
						<swiper-slide v-for="(item,index) in swiperTop" :key="index">
              <div class="dsadasd" :style="{'background-position':item.place}">
              </div>
              <div class="c-title">{{item.title}}</div>
            </swiper-slide>
          </swiper>
          <swiper
            :slides-per-view="9"
            :space-between="80"
            :modules="modules"
            :speed="2000"
            :autoplay="{
              delay: 0,
              disableOnInteraction: false,
              reverseDirection: true,
            }"
            :loop="true"
          >
						<swiper-slide v-for="(item,index) in swiperBottom" :key="index">
							<div class="dsadasd" :style="{'background-position':item.place}">
							</div>
							<div class="c-title">{{item.title}}</div>
						</swiper-slide>

          </swiper>
        </div>
      </div>
    </div>
    <div class="session session5 mt40" >
      <div class="container wow fadeInDown" data-wow-duration="1s">
        <div class="session-title">卡券回收 最新资讯</div>
        <div class="session-subtitle">好消息需分享，你知我知大家知</div>
        <div class="news">
          <div class="rows">
            <div class="news-item" v-for="(item,index) in articleList" :key="index" @click="openDetail(item)">
              <a class="article-thumbnail" href="javascript:void(0);">
                <img
                  class="thumbnail-image"
                  :src="item.image?item.image.file_path:''"
                  alt="新闻缩略图"
                />
              </a>
              <div class="article-content">
                <h3 class="article-title">
                  <a href="javascript:void(0);">{{item.article_title}}</a>
                </h3>
                <p class="article-summary">
									{{item.abstract}}
                </p>
                <div class="article-meta">
                  <span class="source"
                    >来自: <a href="javascript:void(0);">团团收最新速报</a></span
                  >
                  <span class="date"
                    ><img
                      class="calendar-icon"
                      src="@/assets/home/1550.png"
                      alt="日历图标"
                    />{{item.view_time}}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="session session6 " >
      <div class="container wow fadeIn" data-wow-duration="1s">
        <div class="session-title">您可能遇到的问题都在这里</div>
        <div class="button-group">
          <button @click="openFlow">回收流程</button>
          <!-- <button >帮助中心</button> -->
          <button @click="openFirm">商家合作</button>
          <button @click="openPrivacy">隐私政策</button>
          <button @click="openProtocol">转让协议</button>
          <!-- <button>回收说明</button> -->
        </div>
        <div class="faq">
          <div class="faq-question-answer" v-for="(item,index) in problemList" :key="index">
            <div class="faq-question">{{item.article_title}}</div>
            <div class="faq-answer" v-html="HTMLDecode(item.article_content)">
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="session session7 " >
      <div class="container wow fadeIn" data-wow-duration="1s">
        <div>
					团团收致力于以 “极速核销” 的方式，为每一位回收的客户提供更高价、更便捷、更齐全的平台。
        </div>
        <el-button type="primary" href="javascript:void(0);" @click="openCardRecy">我要销卡</el-button>
      </div>
    </div>
		<userFooter></userFooter>
  </div>
</template>
<script >
	import { ref, onMounted } from "vue";
	import WOW from "wow.js";
	import { Swiper, SwiperSlide } from "swiper/vue";
	import SwiperCore, { Autoplay } from "swiper";
	import cardHeader from '../../components/header/card.vue'
	import userFooter from '../../components/footer/user.vue'
	import { ElMessage, ElMessageBox } from 'element-plus'
	import * as Api from '@/api/notice'
	SwiperCore.use([Autoplay]);
	export default{
		components:{
			Swiper,
			SwiperSlide,
			cardHeader
		},
		data(){
			return{
				loading:false,
				options:[],
				data:[],
				searchValue:"",
				problemList:[],
				
				swiperTop:[
					{
						title:"中国移动",
						place:'-67px -67px'
					},
					{
						title:"中国电信",
						place:'-245px -67px'
					},
					// 178 177
					{
						title:"中国联通",
						place:'-423px -67px'
					},
					{
						title:"Q币卡",
						place:'-779px -67px'
					},
					{
						title:"盒马鲜生",
						place:'-957px -67px'
					},
					{
						title:"瑞祥商联卡",
						place:'-957px -422px'
					},
					{
						title:"大润发",
						place:'-67px -599px'
					},
					{
						title:"华润万家",
						place:'-245px -599px'
					},
					{
						title:"永辉超市",
						place:'-601px -599px'
					},
					{
						title:"天虹",
						place:'-601px -422px'
					},
					{
						title:"京东",
						place:'-423px -599px'
					},
				],
				swiperBottom:[
					{
						title:"苹果充值卡",
						place:'-67px -244px'
					},
					{
						title:"畅游",
						place:'-245px -244px'
					},
					{
						title:"久游",
						place:'-423px -244px'
					},
					{
						title:"喜茶",
						place:'-601px -244px'
					},
					{
						title:"美团",
						place:'-779px -244px'
					},
					{
						title:"E充电",
						place:'-957px -244px'
					},
					{
						title:"携程",
						place:'-601px -67px'
					},
					{
						title:"苏果超市",
						place:'-67px -422px'
					},
					{
						title:"瑞祥白金卡",
						place:'-779px -422px'
					},
					{
						title:"百联OK卡",
						place:'-245px -422px'
					},
					{
						title:"沃尔玛",
						place:'-423px -422px'
					}
				],
				swiperTest:[],
				swiperClick:[
					{
						title:"话费充值卡",
						img:[
							require('@/assets/home/1689.png'),
							require('@/assets/home/1690.png'),
							require('@/assets/home/1691.png'),
						],
						icon:[
							'-115px -123px',

						],
						classWidth:"cent-swiper",
						cate_id:1
					},
					{
						title:"加油充值卡",
						img:[
							require('@/assets/swiperClick/oil1.png'),
							require('@/assets/swiperClick/oil2.png'),
							require('@/assets/swiperClick/oil3.png'),
						],
						classWidth:"two-swiper",
						cate_id:2
					},
					{
						title:"游戏点卡",
						img:[
							require('@/assets/swiperClick/game1.png'),
							require('@/assets/swiperClick/game2.png'),
							require('@/assets/swiperClick/game3.png'),
						],
						classWidth:"three-swiper",
						cate_id:3
					},
					{
						title:"电商卡",
						img:[
							require('@/assets/swiperClick/shop1.png'),
							require('@/assets/swiperClick/shop2.png'),
							require('@/assets/swiperClick/shop3.png'),
						],
						cate_id:4
					},
					{
						title:"商超卡",
						img:[
							require('@/assets/swiperClick/mark1.png'),
							require('@/assets/swiperClick/mark2.png'),
							require('@/assets/swiperClick/mark3.png'),
						],
						cate_id:5,
					},
					{
						title:"出行券",
						img:[
							require('@/assets/swiperClick/trip1.png'),
							require('@/assets/swiperClick/trip2.png'),
							require('@/assets/swiperClick/trip1.png'),
						],
						cate_id:6
					},
					{
						title:"美食优惠券",
						img:[
							require('@/assets/swiperClick/foot1.png'),
							require('@/assets/swiperClick/foot2.png'),
							require('@/assets/swiperClick/foot3.png'),
						],
						classWidth:"three-swiper",
						cate_id:7
					},
					{
						title:"影视音乐券",
						img:[
							require('@/assets/swiperClick/video1.png'),
							require('@/assets/swiperClick/video2.png'),
							require('@/assets/swiperClick/video3.png'),
						],
						classWidth:"two-swiper",
						cate_id:8
					}
				],
				swichMouse:false,
				times:null,
				articleList:[],
				objIndex:0,
				hotCard:[],
				systemsettings:{}
			}
		},
		mounted(){
			const wow = new WOW({
				boxClass: "wow", // animated element css class (default is wow)
				animateClass: "animated", // animation css class (default is animated)
				offset: 0, // distance to the element when triggering the animation (default is 0)
				mobile: true, // trigger animations on mobile devices (default is true)
				live: true, // act on asynchronously loaded content (default is true)
				callback: function () {
					// the callback is fired every time an animation is started
					// the argument that is passed in is the DOM node being animated
				},
				scrollContainer: null, // optional scroll container selector, otherwise use window,
				resetAnimation: true, // reset animation on end (default is true)
			});
			wow.init();
		},
		created(){
			this.systemsettings = JSON.parse(localStorage.getItem("systemsettings"))
			this.swiperTest = this.swiperClick
			this.getList()
			this.news_api()
			this.getHotProduct()
		},
		methods:{
			getHotProduct(){
				this.$api.getHotProduct()
				.then(result => {
					this.hotCard = result.data
				})
			},
			openDetail(item){
				this.$router.push({
					path:"/article/detail",
					query:{article_id:item.article_id}
				})
			},
			news_api(){
				this.$api.news_api({
					page:1,
					category_id:""
				})
				.then(result => {
					let num = 6
					let list = result.data.data
					let articleList = []
					for(let i=0;i<list.length;i++){
						if(i === num){
							break
						}
						articleList.push(list[i])
					}
					this.articleList = articleList
				})
			},
			swiper1_log(){
				this.$nextTick(() => {
					this.times = setInterval(() => {
						this.swiper1_next()
					}, 300);
				})
			},
			swiper1_log_prev(){
				this.$nextTick(() => {
					this.times = setInterval(() => {
						this.swiper1_prev()
					}, 300);
				})
			},
			swichBool(){
				clearInterval(this.times);
			},
			slideChange(e){
				this.$nextTick(() => {
					let activeIndex =  this.$refs.swiper1_dwq.$el.swiper.activeIndex
					let centerIndex = activeIndex - 5
					let smallWdith = '210px'
					let lagWidth = '230px'
					let bigWidth = '250px'
					let list = this.swiperClick.slice(centerIndex-2)
					let list_h = this.swiperClick.slice(centerIndex+1)
					if(list.length < 2){
						list.push(this.swiperTest[0])
					}
					for(let i=0;i<list.length;i++){
						if(i > 1){
							break
						}
						if(i == 0){
							list[i].widthObj = smallWdith
							// list[i].leftwww = true
						}
						if(i == 1){
							list[i].widthObj = lagWidth
						}
					}
					for(let i=0;i<list_h.length;i++){
						if(i > 1){
							break
						}
						if(i == 0){
							list_h[i].widthObj = lagWidth
						}
						if(i == 1){
							list_h[i].widthObj = smallWdith
							// list[i].rightwww = true
						}
					}
					this.swiperClick[centerIndex]['widthObj'] = bigWidth
					let listObj = [
						this.swiperClick[centerIndex],
						list[0],
						list[1],
						list_h[0],
						list_h[1]
					]
					this.swiperClick = this.swiperTest
					for(let i=0;i<listObj;i++){
						for(let j=0;j<this.swiperClick.length;j++){
							if(listObj[i].title === this.swiperClick[j].title){
								this.swiperClick[j]['widthObj'] = listObj[i].widthObj
							}
						}
					}
				})

			},
			openQuestion(){
				this.$router.push({path:'/notice/question'})
			},
			openStatement(){
				this.$router.push({path:'/notice/statement'})
			},
			openProtocol(){
				this.$router.push({path:'/notice/protocol'})
			},
			openPrivacy(){
				this.$router.push({path:'/notice/privacy'})
			},
			openFirm(){
				this.$router.push({path:'/firm/index'})
			},
			openFlow(){
				document.getElementById('flow').scrollIntoView({ behavior: 'smooth' })
			},
			remoteMethod(query){
				if(query){
					this.loading = true
					this.$api.searchGoods({
						goods_name: query
					})
					.then(result => {
						this.options = result.data
						this.data = this.options
						this.loading = false
					})
				}else{
					this.options = []
				}
			},
			search(){
				if(this.searchValue){
					let dict = []
					for(let i=0;i<this.data.length;i++){
						if(this.data[i].id === this.searchValue){
							dict = this.data[i]
						}
					}
					this.$router.push({
						path:"/card/huishou",
						query:{id:dict.id,cate_id:dict.cate_id}
					})
					
				}else{
					ElMessage({
					  message: "请输入正确的搜索内容",
					  type: "warning",
					});
				}
			},
			openNotice(){
				this.$router.push({path:'/notice/platform'})
			},
			openArticle(){
				this.$router.push({path:'/article/index'})
			},
			openHotShop(item){
				this.$router.push({path:'/card/huishou',query:{id:item.id,cate_id:item.cate_id}})
			},
			openCardRecy(item){
				this.$router.push({path:'/card/huishou',query:{cate_id:item.cate_id}})
			},
			openOfficial(){
				this.$router.push({path:'/official/index'})
			},
			swiper1_prev(){
				this.$refs.swiper1_dwq.$el.swiper.slidePrev();
			},
			swiper1_next(){
				this.$refs.swiper1_dwq.$el.swiper.slideNext();
			},
			HTMLDecode(text) {
				var temp = document.createElement("div");
				temp.innerHTML = text;
				var output = temp.innerText || temp.textContent;
				temp = null;
				return output;
			},
			getList(){
				Api.getProblem()
				.then(result => {
					this.problemList = result.data
				})
			}
		}
	}

</script>
<script setup>
	import "swiper/css";
	import "swiper/css/navigation";
</script>

<style scoped lang="less">
.home-top-asdw-ttile{
	width: 60%;
	margin: 0 auto;
}
.dsadasd{
	background-image: url('../../assets/swiperTop/test.png');
	background-repeat: no-repeat;
	width:110px;
	height: 110px;
}
.toswtg-zz{
	background-image: url('../../assets/swiperClick/test.png');
	background-repeat: no-repeat;
	// width:110px;
	width: 25%;
	height:50px;
}
.container {
  width: 1440px;
  margin: 0 auto;
}
.cent-swiper{
	width: 300px!important;
}
.two-swiper{
	width: 240px!important;
}
.three-swiper{
	width: 200px!important;
}
.opoend-shop:hover{
	color: #fff;
	cursor: pointer;
}
.opoend-shop{
	transition: 0.3s;
}
.session-title {
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-weight: 500;
  font-size: 32px;
  color: #333333;
  line-height: 48px;
  text-align: center;
}
.session-subtitle {
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-weight: 400;
  font-size: 20px;
  color: #b3b3b3;
  line-height: 30px;
  text-align: center;
  margin: 12px 0 44px 0;
}
.footer-container {
  padding-top: 40px;
  background: #fff;
  .footer-copyright {
    display: flex;
    font-family: Gilroy-Regular, Gilroy-Regular;
    font-weight: normal;
    font-size: 14px;
    color: #b3b3b3;
    line-height: 17px;
    padding-bottom: 27px;
    > div {
      margin-right: 54px;
    }
  }
  .contact-info {
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
    margin-bottom: 12px;
    border-bottom: 1px solid #ededed;
    .qr-code {
      img {
        width: 108px;
        display: block;
        margin-bottom: 8px;
      }
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 400;
      font-size: 14px;
      color: #b3b3b3;
      line-height: 21px;
    }
    .hotline {
      font-family: Gilroy-Bold, Gilroy-Bold;
      font-weight: normal;
      font-size: 40px;
      color: #0eb6ff;
      line-height: 50px;
    }
    .hotline-description {
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 400;
      font-size: 16px;
      color: #b3b3b3;
      line-height: 19px;
      margin-bottom: 28px;
    }
    .footer-links {
      .line {
        width: 1px;
        height: 11px;
        border-radius: 0px 0px 0px 0px;
        border: 1px solid #e6e6e6;
        margin: 0 8px;
      }
      a {
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        font-size: 16px;
        color: #737373;
        line-height: 40px;
      }
    }
  }
}
.qfsxchgh{
	    width: 1440px;
	    margin: 0 auto;
}
.session7 {
  background: #f9f9f9;
  padding: 88px 0 100px 0;
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  div {
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 500;
    font-size: 28px;
    color: #333333;
    width: 827px;
    line-height: 42px;
    margin-bottom: 60px;
    text-align: center;
  }
  button {
    width: 300px;
    height: 68px;
    // background: #0eb6ff;
    border-radius: 12px 12px 12px 12px;
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 500;
    font-size: 20px;
    // color: #ffffff;
    line-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.session6 {
  padding: 0px 0 76px 0;
  // background: #f7f7f7;
	background-color: #fff;
  .faq {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .faq-question-answer {
      width: 49%;
      background: #ffffff;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
      border-radius: 8px 8px 8px 8px;
      padding: 24px;
      box-sizing: border-box;
      margin-bottom: 24px;
      .faq-question {
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        font-size: 24px;
        color: #333333;
        line-height: 36px;
        margin-bottom: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .faq-answer {
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        font-size: 16px;
        color: #737373;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
  .button-group {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 52px;
    button {
      width: 120px;
      height: 52px;
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #ededed;
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 400;
      font-size: 18px;
      color: #333333;
      line-height: 27px;
      background: transparent;
      margin: 0 6px;
      cursor: pointer;
			transition: 0.3s;
    }
		a{
			width: 120px;
			height: 52px;
			border-radius: 8px 8px 8px 8px;
			border: 1px solid #ededed;
			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: 400;
			font-size: 18px;
			color: #333333;
			line-height: 27px;
			background: transparent;
			margin: 0 6px;
			cursor: pointer;
			transition: 0.3s;
		}
  }
	.button-group button:hover{
		background-color: #ecf5ff;
		border-color: #a0cfff;
		color: #0eb6ff;
	}
	.button-group a:hover{
		background-color: #ecf5ff;
		border-color: #a0cfff;
		color: #0eb6ff;
	}
}
.session5 {
  background: #fff;
  padding-bottom: 88px;
  .session-subtitle {
    margin-bottom: 64px;
  }
  .news {
    .rows {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
		.news-item:hover{
			background-color: #f7f7f7;
		}
    .news-item {
      display: flex;
      flex: 0 0 48%;
			height: 140px;
			position: relative;
			transition: 0.3s;
      margin-bottom: 32px;
			box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
      .article-thumbnail {
        margin-right: 24px;
        img {
          // width: 180px;
					width: 200px;
          height: 140px;
					
          border-radius: 8px 8px 8px 8px;
        }
      }
      .article-content {
				position: relative;
				flex: 1;
        .article-title {
          margin: 0;
          margin-bottom: 12px;
          a {
            font-family: Source Han Sans CN, Source Han Sans CN;
            font-weight: 500;
            font-size: 24px;
            color: #333333;
            line-height: 36px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 470px;
            display: block;
          }
        }
        .article-summary {
          font-family: Source Han Sans CN, Source Han Sans CN;
          font-weight: 400;
          font-size: 18px;
          color: #b3b3b3;
          line-height: 27px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 470px;
        }
        .article-meta {
					position: absolute;
					bottom:10px;
					width: 100%;
          // margin-top: 72px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .source {
            font-family: Source Han Sans CN, Source Han Sans CN;
            font-weight: 400;
            font-size: 14px;
            color: #737373;
            line-height: 21px;
            a {
              color: #0eb6ff;
            }
          }
          .date {
            display: flex;
            align-items: center;
            img {
              width: 14px;
              margin-right: 8px;
            }
            font-family: Gilroy-Regular, Gilroy-Regular;
            font-weight: normal;
            font-size: 14px;
            color: #b3b3b3;
            line-height: 17px;
						margin-right: 10px;
          }
        }
      }
    }
  }
}
.session4 {
  padding-top: 80px;
  background: #f7f7f7;
  height: 700px;
  .session-subtitle {
    margin-bottom: 100px;
  }
  .c-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
  .c-item {
    // width: 100%;
		width: 100px;
		height: 100px;
    padding: 21px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px 8px 8px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    img {
      width: 58px;
			// height: 58px;
    }
  }
  .c-title {
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    font-size: 16px;
    color: #b3b3b3;
    line-height: 24px;
    margin-bottom: 46px;
    text-align: center;
		margin-left: 4px;
  }
  /deep/ .swiper-wrapper {
    color: #000;
    transition-timing-function: linear !important; //想好具体位置放到哪，得能替换！
  }
}
.session3 {
  margin-bottom: 100px;
  .session-subtitle {
    margin-bottom: 81px;
  }
  .feature-container {
    display: flex;
    justify-content: space-between;
    .feature-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 0 0 288px;
      .feature-icon {
        width: 65px;
        margin-bottom: 36px;
        display: block;
				transition: .5s;
      }
      .feature-title {
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 500;
        font-size: 24px;
        color: #333333;
        line-height: 36px;
      }
      .feature-description {
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        font-size: 16px;
        color: #737373;
        line-height: 24px;
        margin-top: 12px;
        text-align: center;
      }
    }
  }
}
.session2 {
  padding: 100px 0;

  .login-options-container {
    display: flex;
    justify-content: space-between;
    .login-option {
      padding: 27px 31px 46px 37px;
      width: 260px;
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #e7e7e7;
      box-sizing: border-box;
      .option-number {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        font-family: Gilroy, Gilroy;
        font-weight: 500;
        font-size: 40px;
        color: #d8d8d8;
        line-height: 47px;
        margin-bottom: 36px;
        span {
          margin-top: 18px;
        }
      }
      .option-title {
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 500;
        font-size: 20px;
        color: #333333;
        line-height: 30px;
        margin-bottom: 11px;
      }
      .option-description {
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        font-size: 16px;
        color: #737373;
        line-height: 24px;
      }
    }
  }
}
.swiper1{
	
	  position: relative;
		z-index: 2;
		margin-top:-100px;
	  > img {
	    width: 40px;
	    top: 50%;
	    position: absolute;
	    cursor: pointer;
	  }
	
	  .prev {
	    left: 0;
	  }
	
	  .next {
	    right: 0;
	  }
	
	  .swiper {
	    width: 90%;
	    margin: 0 auto;
			padding-bottom:20px;
	  }
	  .swiper-slide {
	    .swiper-item {
	      width: 100%;
	      background: #ffffff;
	      box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.05);
	      border-radius: 24px 24px 24px 24px;
	      font-size: 18px;
	      background: #fff;
	      transition: 300ms;
	      padding: 30px 24px 24px 24px;
	      box-sizing: border-box;
	      display: flex;
	      flex-direction: column;
	      align-items: center;
	      .icons {
	        width: 100%;
	        display: flex;
	        align-items: center;
	        justify-content: space-between;
	        margin-bottom: 27px;
	        img {
	          width: 20%;
	        }
	      }
	      .title {
	        font-family: Source Han Sans CN, Source Han Sans CN;
	        font-weight: 500;
	        font-size: 24px;
	        color: #333333;
	        line-height: 36px;
	      }
	      > a {
	        width: 116px;
	        height: 40px;
	        border-radius: 200px 200px 200px 200px;
	        border: 1px solid #ededed;
	        font-family: Source Han Sans CN, Source Han Sans CN;
	        font-weight: 400;
	        font-size: 16px;
	        color: #737373;
	        line-height: 24px;
	        padding: 8px 0x;
	        box-sizing: border-box;
	        display: block;
	        display: flex;
	        justify-content: center;
	        align-items: center;
	        margin-top: 16px;
	      }
	    }
	  }
	
}
.session1 {
  width: 100%;
  background: url("@/assets/home/bg.png") no-repeat;
  background-size: 100% 100%;
	position: relative;
	padding-bottom: 200px;
	z-index: 1;
  .container {
    .swiper1 {}
    .introduction {
      margin-top: 140px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .heading {
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        font-size: 40px;
        color: #ffffff;
        line-height: 60px;
        margin-bottom: 16px;
      }
      .tagline {
        font-family: YouSheBiaoTiHei-Regular;
        font-weight: 500;
        font-size: 90px;
        color: #ffffff;
        line-height: 90px;
      }
			.tagline + .tagline{
				margin-bottom: 102px;
			}
      .search-container {
        .search-input-wrap {
          display: flex;
          align-items: center;
          margin-bottom: 22px;
          input {
            width: 604px;
            height: 40px;
            background: #ffffff;
            border-radius: 8px 8px 8px 8px;
            outline: none;
            box-shadow: none;
            border: none;
            margin-right: 12px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #b3b3b3;
            line-height: 20px;
            padding: 0 24px;
          }
					.search-input{
						width: 604px;
						height: 40px;
						background: #ffffff;
						border-radius: 8px 8px 8px 8px;
						outline: none;
						box-shadow: none;
						border: none;
						margin-right: 12px;
						font-family: PingFang SC, PingFang SC;
						font-weight: 400;
						font-size: 14px;
						color: #b3b3b3;
						/deep/ .el-input__wrapper{
							height: 40px;
						}
						// line-height: 20px;
						// padding: 0 24px;
					}
          button {
            width: 54px;
            height: 40px;
            background: linear-gradient(180deg, #ff4d4d 0%, #ff9052 100%);
            border-radius: 8px 8px 8px 8px;
            outline: none;
            box-shadow: none;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
						cursor: pointer;
          }
        }
        .search-suggestions {
          font-family: Source Han Sans CN, Source Han Sans CN;
          font-weight: 400;
          font-size: 14px;
          color: rgb(255 255 255 / 50%);
          line-height: 21px;
          margin-left: 5px;
          margin-bottom: 51px;
        }
      }
    }
    
  }
}
.login-option:hover > .option-number > .option-icon{ 
	-webkit-animation-name: swing; 
	animation-name: swing;
	animation-duration: .5s;
}
.login-option:hover{
	box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}
.feature-icon:hover{ -webkit-transform: rotate(180deg); transform: rotate(180deg)};
.thumbnail-image{
	transition: transform 0.3s ease; /* 平滑变化 */
}
.article-thumbnail:hover > .thumbnail-image{
transform: scale(1.1); /* 鼠标悬停时，图片放大1.1倍 */
}
.swiper-item > a:hover{
	background-color: #ecf5ff;
	border-color: #a0cfff!important;
	color: #409eff!important;
}

.swiper1-dasdasda{
	display: flex;
	align-items: flex-end;
	justify-content: center;
	position: relative;
	z-index: 1;
	margin-top: -80px;
}
.swiper-item-test {
	width:200px;
	background: #ffffff;
	box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.05);
	border-radius: 24px 24px 24px 24px;
	font-size: 18px;
	background: #fff;
	transition: 300ms;
	padding: 30px 24px 24px 24px;
	text-align: center;
	margin:0 10px;
	height: 180px;
	// box-sizing: border-box;
	// display: flex;
	// flex-direction: column;
	// align-items: center;
	img{
		width: 60px;
		height: 60px;
		margin: 0 auto;
	}
	.title{
		font-size: 24px;
		color: #333333;
		line-height: 36px;
		margin-top: 20px;
	}
	button{
		border-radius: 200px 200px 200px 200px;
		width: 110px;
		height: 40px;
		margin-top: 16px;
	}
}
.dasdqw{
	
	    justify-content: center;
	    align-items: center;
}
.home-swiper-img{
	width:150px;
	height:150px;
}
</style>
