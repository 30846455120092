<template>
	<div class="user-l">
		<div class="">
			<div class="text-center ">
				<div class="wdfggjiyui">
					<el-avatar :size="70" class="avatarUlr-wf" :src="userInfo && userInfo.avatarUrl?userInfo.avatarUrl:require('../../assets/user/avatar.png')" />
					<p class="ft18">{{userInfo && userInfo.nickName?userInfo.nickName:'-'}}</p>
					<p class="8687a7 ft14">用户ID：{{userInfo && userInfo.user_id?userInfo.user_id:'-'}}</p>
				</div>
			</div>
			<div class="user-dsw">
				<template  v-for="(item,index) in Data" :key="index">
					<!-- @mousedown='clickButton(index)' -->
					<a href="javascript:void(0)" @click="openLink(item.url)" :to="{path:item.url}" :replace="true" :class="{active:tabStr === item.url,'dqwdwqdfff':item.down}" class="button-ripple"  >
						<img :src="item.img" />
						<span>{{item.title}}</span>
						<!-- <div  class="waves-ripple"></div> -->
						<!-- <span class="icon-youjiantou1 iconfont wdssgyu"></span> -->
					</a >
					<div class="hint" v-if="(index+1) % 3 === 0 && index !=0"></div>
				</template>
			</div>
			<div class="server-button" >
				<el-button color="#0EB6FF" @click="$util.checkServe()">在线客服</el-button>
			</div>
			<div class="text-center mt40">
				<p class="c_0EB6FF ft16">客服热线</p>
				<p class="c_0EB6FF ft24 mb20">{{getContactUs?getContactUs.mobile:"4000-242-456"}}</p>
			</div>

		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				tabStr:"",
				mobile:"",
				getContactUs:JSON.parse(localStorage.getItem("getContactUs")),
				Data:[
					{
						title:"账户概览",
						url:"/user",
						url1:"",
						img:require("../../assets/user/left/account_l.png"),
						down:false
					},
					{
						title:"我要提现",
						url:"/user/withdraw",
						url1:"",
						img:require("../../assets/user/left/wallet.png"),
						down:false
					},
					{
						title:"提现记录",
						url:"/user/withdraw_record",
						url1:"",
						img:require("../../assets/user/left/withdraw.png"),
						down:false
					},
					{
						title:"我的订单",
						url:"/user/order",
						url1:"/user/settle",
						img:require("../../assets/user/left/order.png"),
						down:false
					},
					{
						title:"资金明细",
						url:"/user/capital",
						url1:"",
						img:require("../../assets/user/left/capital.png"),
						down:false
					},
					{
						title:"统计记录",
						url:"/user/statistics",
						url1:"",
						img:require("../../assets/user/left/statis.png"),
						down:false
					},
					{
						title:"资料管理",
						url:"/user/userDatum",
						url1:"",
						img:require("../../assets/user/left/dataum.png"),
						down:false
					},
					{
						title:"操作日志",
						url:"/user/log",
						url1:"",
						img:require("../../assets/user/left/log.png"),
						down:false
					}
				],
				userInfo:JSON.parse(localStorage.getItem("userInfo"))
			}
		},
		created(){
			this.tabStr = window.location.hash.replace(/[#]/g, '')
			if(this.tabStr.indexOf('?') != -1){
				if(this.tabStr.split('?')[0] == '/user/detail'){
					this.tabStr = '/user/order'
				}

			}
			this.userInfo = JSON.parse(localStorage.getItem("userInfo"))
			this.getContactUs = JSON.parse(localStorage.getItem("getContactUs"))
			// this.mobile = JSON.parse(localStorage.getItem('datum')).mobile
		},
		methods:{
			clickButton(index){
				this.Data[index].down = true
			},
			openLink(url){
				if(url === this.tabStr){
					console.log(4564654654)
					this.$router.go(0)
				}else{
					this.$router.push({path:url})
				}
				console.log()
				
			},
			fqwfghgg(index){
				
			}
		}
	}
</script>

<style scoped lang="less">
	.user-l{
		min-height: 765px;
		/* width: 16.9%; */
    background-color: #fff;
		float: left;
		// margin-right: 24px;
		// margin-top:-80px;
		border-radius: 7px;
		box-shadow: 0 0.75rem 1.5rem rgba(18,38,63,.03);
		// width: 254px;
		width: 100%;
		// min-width: 200px;
		padding: 20px 0px;
		// max-width: 204px;
	}
	.user-dsw{
		padding: 29px 0px 0;
		padding-bottom: 27.5px;
	} 
	.user-dsw a:hover{
		color: #333; 
	}
	.user-dsw a img{
		width: 19px;
		height: 20.12px;
		margin-right: 16px;
	}
	.user-dsw a{
		font-size: 18px;
		display: flex;
		align-items: center;
		color: #333;
		text-decoration:none;
		position: relative;
		padding: 0.625rem 1.5rem;
		
		
	}
	.user-dsw a + a{
		// margin-top: 10px;
	}
	.user-dsw .active{
		// color: #333;
		font-weight: 600;
	}
	.hint{
		height: 1px;
		width: 125px;
		margin: 27px auto 24px auto;
		background-color: #F1F1F1;
	}
	.server-button{
		text-align: center;
	}
	.server-button button{
		border-radius: 12px;
		width: 142px;
		height: 60px;
		color: #fff;
		font-size: 18px;
		margin-top: 23px;
	}
	.button-ripple {
	  overflow: hidden;
	  position: relative;
	  transition: background-color 0.3s linear, border 0.3s linear;
		justify-content: center;
		transition: 0.5s;
	  // &:after {
	  //   content: "";
	  //   display: block;
	  //   position: absolute;
	  //   width: 100%;
	  //   height: 100%;
	  //   top: 0;
	  //   left: 0;
	  //   pointer-events: none;
	  //   background-image: radial-gradient(
	  //     circle,
	  //     #000 10%,
	  //     rgba(0, 0, 0, 0) 10.01%
	  //   ); 
	  //   background-repeat: no-repeat;
	  //   background-position: 100%;
	  //   transform: scale(10);
	  //   opacity: 0;
	  //   transition: transform 0.5s, opacity 1s;
	  // }
	  // &:active:after {
	  //   transform: scale(0);
	  //   opacity: 0.2;
	  //   transition: 0s;
	  // }

	}
	.button-ripple:hover {
		// background-image: radial-gradient(
		// 	circle,
		// 	#000 10%,
		// 	rgba(0, 0, 0, 0) 10.01%
		// ); 
		background-color: rgba(163, 163, 163, 0.1);
		// font-weight: 600;
		// opacity: 0.2;
	}
	@keyframes wave-animation {
	  0% {
	    transform: scale(0);
	  }
	  100% {
	   transform: scale(10);
	  }
	}
	// .button-ripple:hover{
	//   background-position: 50% 100%;
	// }
	.wdssgyu{
		position: absolute;
		right: 13px;
		font-size: 13px;
	}
	/deep/.avatarUlr-wf{
		// border: 2px dashed #0EB6FF;
		border-radius: 100%;
	}
	.wdfggjiyui{
		// margin-top: 1.5rem;
		font-size: 18px;
	}
</style>