import { createApp } from 'vue'
// import 'element-plus/lib/theme-chalk/base.css'
import App from './App.vue'
import router from './router'
import Vue from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'


import { ElCollapseTransition } from 'element-plus'
import 'dayjs/locale/zh-cn';
import locale from 'element-plus/es/locale/lang/zh-cn';
	import "swiper/css";
	import "swiper/css/navigation";
// 网络请求
import * as Api from './api/user.js'
import util from './utils/util.js'
const app = createApp(App)
app.use(router)
app.use(ElementPlus,{locale})
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.component(ElCollapseTransition.name, ElCollapseTransition)
app.config.globalProperties.$api = Api;
app.config.globalProperties.$util = util;
app.mount('#app')
// createApp(App).mount('#app')
