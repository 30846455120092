<template>
	<div>

	</div>
</template>

<script>
	import config from '@/config'
	export default{
		data(){
			return{
				lretv:"",
				dialogVisible:false
			}
		},
		created() {
			// setTimeout(() => {
			// 	console.log(window.opener)
			// 		if (window.opener && window.opener.getLogin) {
			// 				window.opener.getLogin();
			// 		} else {
			// 				//window.opener.frames[0] 获取到的window对象
			// 				window.opener.frames[0].getLogin();
			// 		}
			// 	// window.opener.location.reload()
			// 	window.close()
			// },3000)
			this.wxLogin()
		},
		methods:{
			wxLogin(){
				let code = this.$route.query.code
				if(code){
					this.$api.signin_wx({
						code,
						type:config.postType
					})
					.then(result => {
						if(result.code === 1){
							localStorage.setItem("token",result.data.token);
							// 使用父页面方法
							if (window.opener && window.opener.getLogin) {
								window.opener.getLogin();
							} else {
									//window.opener.frames[0] 获取到的window对象
								window.opener.frames[0].getLogin();
							}
							window.close() //关闭当前页面
						}
					})
					// // 使用父页面方法
					// if (window.opener && window.opener.getLogin) {
					// 	window.opener.getLogin();
					// } else {
					// 		//window.opener.frames[0] 获取到的window对象
					// 	window.opener.frames[0].getLogin();
					// }
					// window.opener.location.reload() //强制刷新父页面
					// window.close() //关闭当前页面
				}else{
					this.$api.pc_signin()
					.then(result => {
						let dict = result.msg
						let url = window.location.href
						let redirect_uri = encodeURIComponent(url)
						let openUrl =`https://open.weixin.qq.com/connect/qrconnect?appid=${dict.appid}&redirect_uri=${redirect_uri}&response_type=code&scope=${dict.scope}&state=${dict.state}`
						window.location.href = openUrl
					})
				}
			}
		}
	}
</script>

<style scoped>
	/deep/.pop-radius{
		border-radius: 16px;
	}
</style>