<template>
	<div class="bg_fff html" >
		<cardHeader :type="'wx'"></cardHeader>
		<div class="wx-bg-main">
			<div class="flex alcenter flex-center">
				<div class="wx-lefyt wow fadeInLeftBig" data-wow-duration="1s">
					<div>关注团团收专业一站式兑换服务</div>
					<div class="hints">随时随地，快速销卡</div>
					<div class="flex alcenter " style="margin-top: 130px;">
						<div class="c_b3b3b3 ft16 font-weight400 text-center flex-one">
							<img src="@/assets/wx/bottom1.png" class="bottom-wx-img" />
							<div class="mt16">专属首页一应俱全</div>
						</div>
						<div class="c_b3b3b3 ft16 font-weight400 text-center flex-one">
							<img src="@/assets/wx/bottom2.png" class="bottom-wx-img" />
							<div class="mt16">详细数据一目了然</div>
						</div>
						<div class="c_b3b3b3 ft16 font-weight400 text-center flex-one">
							<img src="@/assets/wx/bottom3.png" class="bottom-wx-img" />
							<div class="mt16">及时通知了解动态</div>
						</div>
					</div>
				</div>
				<div class="wqdqwrth wow fadeInRightBig" data-wow-duration="1s">
					<!-- <img src="@/assets/wx/phone.png" class="wx-img"  > -->
					<img :src="systemsettings && systemsettings.wxmp_img?systemsettings.wxmp_img.file_path:''" class="wft-code-img" />
				</div>

				
			</div>
			
		</div>
		<userBottom></userBottom>
	</div>
</template>

<script>
	import cardHeader from '../../components/header/card.vue'
	import userBottom from '../../components/footer/user.vue'
	import WOW from "wow.js";
	export default{
		components:{
			cardHeader,
			userBottom
		},
		data(){
			return{
				systemsettings:{}
			}
		},
		created(){
			this.systemsettings = JSON.parse(localStorage.getItem("systemsettings"))
		},
		mounted(){
			const wow = new WOW({
				boxClass: "wow", // animated element css class (default is wow)
				animateClass: "animated", // animation css class (default is animated)
				offset: 0, // distance to the element when triggering the animation (default is 0)
				mobile: true, // trigger animations on mobile devices (default is true)
				live: true, // act on asynchronously loaded content (default is true)
				callback: function () {
					// the callback is fired every time an animation is started
					// the argument that is passed in is the DOM node being animated
				},
				scrollContainer: null, // optional scroll container selector, otherwise use window,
				resetAnimation: true, // reset animation on end (default is true)
			});
			wow.init();
		},
	}
</script>

<style lang="less" scoped>
	page{
		background-color: #fff!important;
		position: relative;
	}
	.wx-bg-main{
		background: linear-gradient( 180deg, #FFFFFF 0%, #E9EEF4 100%);
		// min-height: 81vh;
		// position: absolute;
		// height: calc(80vh - 121px);
		// height: 100%;
		position: relative;
		padding: 0 12.5%;
		padding-top: 120px;
		padding-bottom: 78px;
	}
	.wx-lefyt{
		margin-right: 264px;
		font-weight: bold;
		font-size: 40px;
		color: #333333;
	}
	.wx-lefyt .hints{
		font-weight: 400;
		font-size: 24px;
		color: #737373;
		margin-top: 24px;
	}
	.wx-img{
		width: 400px;
		// max-height: 723px;
	}
	.bottom-wx-img{
		width: 80px;
		height: 80px;
	}
	.tex-bttom{
		// position: absolute;
		// bottom: 0;
		border-top: 1px solid #EDEDED;
		padding-top: 12px;
		bottom: 0;
		width: 1440px;
		font-weight: 400;
		font-size: 14px;
		color: #B3B3B3;
		left: 0;
		right: 0;
		margin: 0 auto;
		margin-top: 80px;
	}
	.mr54{
		margin-right: 54px;
	}
	.wft-code-img{
		position: absolute;
		// right: 18%;
		// top: 37%;
		// right: 750px;
		// top:470px;
		// right: 80px;
		top: 350px;
		width: 200px;
		height: 200px;
		margin: 0 auto;
		left: 0;
		right: 0;
	}
	.wqdqwrth{
		position: relative;
		background-image: url('@/assets/wx/phone.png');
		background-size: 100% 100%;
		width: 400px;
		    height: 700px;
	}
</style>