<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { onMounted } from "vue";
export default {
  name: "App",
  components: {},
  created() {
		// localStorage.clear()
		this.systemsettings()
		this.getContactUs()
	},
  onMounted() {
		
    document.body.style.setProperty("--el-color-primary", "#000000");
  },
  mounted() {
    const debounce = (fn, delay) => {
      let timer = null;
      return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
          fn.apply(context, args);
        }, delay);
      };
    };

    const _ResizeObserver = window.ResizeObserver;
    window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
      constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
      }
    };
    // console.log(document.getElementsByClassName('el-table__body-wrapper'))
    // const overHg = document.getElementsByClassName('el-table__body')[0].clientWidth + 'px';
    // const wrapper = document.getElementsByClassName('el-table__body-wrapper')[0];
    // wrapper.style.maxWidth = overHg;
  },
	methods:{
		getContactUs(){
			this.$api.getContactUs()
			.then(result => {
				localStorage.setItem("getContactUs",JSON.stringify(result.data));
			})
		},
		systemsettings(){
			this.$api.systemsettings()
			.then(result => {
				localStorage.setItem("systemsettings",JSON.stringify(result));
			})
		}
	}
};
</script>

<style>
@import "./assets/css/main.css";
@import "./assets/css/iconfont.css";
@import "./assets/css/element-variarbles.scss";
@import "./assets/css/animate.css";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
}
.html{
	min-width: 1440px;
}
.html-two{
	min-width: 1540px;
}
html {
  height: 100%;
  /* background-color: #f3f5f7 !important; */
}
body {
  color: #333;
  font-size: 14px;
  font-family: "Microsoft Yahei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}
a {
  text-decoration: none;
}
p {
  margin-top: 0;
  margin-bottom: 0;
}
ul {
  padding: 0;
  list-style-type: none;
  margin: 0;
}
.wdsffdzcdtg {
  margin-left: 0 !important;
  margin-right: 0 !important;
	
}
/deep/.el-table--striped .el-table__body tr.el-table__row--striped td {
  background: #f6f6f6 !important;
}
@font-face {
	font-family: 'YouSheBiaoTiHei-Regular';
	src: url('@/assets/fontFamliy/YouSheBiaoTiHei-2.ttf');
	font-weight: normal;
	font-style: normal;
}
</style>
