/**
 * 工具类
 */
function checkServe(){
	let userId = JSON.parse(localStorage.getItem('userInfo'))?JSON.parse(localStorage.getItem('userInfo')).user_id:""
	let id = userId?"ID："+userId:"未登录"
	var api = $53.createApi();
	api.push('cmd','member');
	api.push('name',id);
	api.query();
	api.push('cmd', 'kfclient');
	api.push('type', 'popup');
	api.query()
}


module.exports = {
	checkServe:checkServe,
}