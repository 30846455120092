import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '@/view/home/index'
import User from '@/view/user/index'
import Withdraw from '@/view/user/withdraw'
import WithdrawRecord from '@/view/user/withdraw_record'
import Order from '@/view/user/order'
import Statistics from '@/view/user/statistics'
import Log from '@/view/user/log'
import UserDatum from '@/view/user/user_datum'
import Capital from '@/view/user/capital'
import Detail from '@/view/user/detail'
import Settle from '@/view/user/settle'
import Survey from '@/view/user/survey'
import Login from '@/view/login/index'
import Register from '@/view/login/register'
import Problem from '@/view/login/problem'
import Eshop from '@/view/card/eshop'
import Official from '@/view/official/index'
import Platform from '@/view/notice/platform'
import Brief from '@/view/notice/brief'
import Question from '@/view/notice/question'
import Agreement from '@/view/notice/agreement'
import Privacy from '@/view/notice/privacy'
import Protocol from '@/view/notice/protocol'
import Statement from '@/view/notice/statement'
import ArticleIndex from '@/view/article/index'
import ArticleDetail from '@/view/article/detail'
import Firm from '@/view/firm/index'
import WxLogin from '@/view/login/wx_login'
// 
const routerHistory = createWebHashHistory()


const routes = [
  { path: '/', name: 'home', component: Home, meta: { title: '团团收' } },
  { path: '/user', name: 'user', component: User, meta: { title: '个人中心' } },
  { path: '/user/withdraw', name: 'withdraw', component: Withdraw, meta: { title: '我要提现' } },
  { path: '/user/withdraw_record', name: 'withdrawRecord', component: WithdrawRecord, meta: { title: '提现记录' } },
  { path: '/user/order', name: 'order', component: Order, meta: { title: '我的订单' } },
  { path: '/user/statistics', name: 'statistics', component: Statistics, meta: { title: '统计记录' } },
  { path: '/user/log', name: 'log', component: Log, meta: { title: '操作日志' } },
  { path: '/user/userDatum', name: 'userDatum', component: UserDatum, meta: { title: '操作日志' } },
  { path: '/user/capital', name: 'capital', component: Capital, meta: { title: '资金明细' } },
  { path: '/user/detail', name: 'detail', component: Detail, meta: { title: '订单详情' } },
  { path: '/user/settle', name: 'settle', component: Settle, meta: { title: '快速结算' } },
  { path: '/user/survey', name: 'survey', component: Survey, meta: { title: '尽职调查' } },
  { path: '/login', name: 'login', component: Login, meta: { title: '登录' } },
  { path: '/register', name: 'register', component: Register, meta: { title: '注册' } },
  { path: '/problem', name: 'problem', component: Problem, meta: { title: '意见反馈' } },
  { path: '/card/huishou', name: 'eshop', component: Eshop, meta: { title: '卡券回收' } },
	{ path: '/official/index', name: 'official', component: Official, meta: { title: '公众号' } },
	{ path: '/notice/platform', name: 'platform', component: Platform, meta: { title: '官方公告' }},
	{ path: '/notice/brief', name: 'brief', component: Brief, meta: { title: '公司简介' }},
	{ path: '/notice/question', name: 'question', component: Question, meta: { title: '常见问题' }},
	{ path: '/notice/agreement', name: 'agreement', component: Agreement, meta: { title: '用户协议' }},
	{ path: '/notice/privacy', name: 'privacy', component: Privacy, meta: { title: '隐私保护' }},
	{ path: '/notice/protocol', name: 'protocol', component: Protocol, meta: { title: '转让协议' }},
	{ path: '/notice/statement', name: 'statement', component: Statement, meta: { title: '回收说明' }},
	{ path: '/article/index', name: 'articleIndex', component: ArticleIndex, meta: { title: '行业资讯' }},
	{ path: '/article/detail', name: 'articleDetail', component: ArticleDetail, meta: { title: '资讯详情' }},
	{ path: '/firm/index', name: 'firm', component: Firm, meta: { title: '企业回收' }},
	{ path: '/login/wxLogin', name: 'wxLogin', component: WxLogin, meta: { title: '微信授权登录' }}
]
const router = createRouter({
  history: routerHistory,
  routes,
})
function GetUrlParam(paraName) {
  var url = window.location.toString();
  var arrObj = url.split("?");
  if (arrObj.length > 1) {
    var arrPara = arrObj[1].split("&");
    var arr;
    for (var i = 0; i < arrPara.length; i++) {
      arr = arrPara[i].split("=");
      if (arr != null && arr[0] == paraName) {
        return arr[1];
      }
    }
    return "";
  } else {
    return "";
  }
}
function checkPageExists(path) {
  // 这里应该是你的逻辑来判断页面是否存在
  // 比如你可以检查路由配置中是否有对应的组件
  return !!routes.getMatchedComponents(path).length;
}
router.beforeEach((to, from, next) => {

	if(to.matched.length === 0){
		next('./');
	}
	let settingPc = JSON.parse(localStorage.getItem('systemsettings'))
	if(Object.keys(to.meta).length>0 && to.matched.length>0){
	  let head = document.getElementsByTagName('head');
	
	  let meta_keyword=document.createElement('meta');
	  if(document.querySelector('meta[name="keywords"]')){
	    document.querySelector('meta[name="keywords"]').setAttribute('content',to.name == 'home' ? settingPc ? settingPc.keywords : '' :settingPc?settingPc.seo_keywords:'')
	  }else{
	    meta_keyword.setAttribute('name','keywords')
	    meta_keyword.setAttribute('content',to.name == 'home' ? settingPc ? settingPc.keywords : '' :settingPc?settingPc.seo_keywords:'')
	    head[0].appendChild(meta_keyword)
	  }
	  let meta_description=document.createElement('meta');
	  if(document.querySelector('meta[name="description"]')){
	    document.querySelector('meta[name="description"]').setAttribute('content',to.name === 'home' ? settingPc?settingPc.description:''  :settingPc?settingPc.seo_description:'')
	  }else{
	    meta_description.setAttribute('name','description')
	    meta_description.setAttribute('content',to.name === 'home' ? settingPc?settingPc.description:''  :settingPc?settingPc.seo_description:'')
	    head[0].appendChild(meta_description)
	  }
	}

	let link = settingPc && settingPc.icon_img.file_path?settingPc.icon_img.file_path:''
	let $favicon = document.querySelector('link[rel="icon"]');
	if ($favicon !== null) {
	  $favicon.href = link;
	} else {
	  $favicon = document.createElement("link");
	  $favicon.rel = "icon";
	  $favicon.href = link;
	  document.head.appendChild($favicon);
	}
	if(to.name === 'home'){
		document.title = settingPc?settingPc.name+ ' - ' +settingPc.title:to.meta.title
	}else{
		if (to.meta.title) {
		  document.title = to.meta.title
		}
	}

  let token = GetUrlParam("token")
  if (token) {
    if (token.indexOf('#') != -1) {
      token = token.slice(0, token.indexOf('#'))
    }
    localStorage.setItem("token", token);
  }
  next();
  // if (to.query.code == undefined) {
  //     const token = window.sessionStorage.getItem('X-Access-Token')
  //     if (token) { //如果有就直接到首页咯
  //         next();
  //     } else {
  //         if (to.path == '/login') { //如果是登录页面路径，就直接next()
  //             next();
  //         } else { //不然就跳转到登录;
  //             next('/login');
  //         }
  //     }
  // } else {
  //     let code = to.query.code
  //     callBack(code).then(res => {
  //         window.sessionStorage.setItem('X-Access-Token', res.data.token)
  //         next('./');
  //     })
  // }
});
export default router
