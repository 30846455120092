<template>
	<div class="user-bg-swg html" >
		<div class="bg_fff">
			<cardHeader :type="'message'"></cardHeader>
		</div>
		<eshopbg></eshopbg>
		<div>


		</div>
		<el-row :gutter="20"
			
			element-loading-text="加载中..."
			class="wdsffdzcdtg mt24" style="min-height: 100vh;">
			<el-col :span="15" :offset="3" >
					<div  v-loading="loading">
						<div class="flex alcenter card-top bg_fff">
							<div  class="card-top-item" :class="{'card-active':cate_id == ''}" @click="tabCate({category_id:''})">
								全部资讯
							</div>
							<div v-for="(item,index) in cardList" :key="index" class="card-top-item" :class="{'card-active':cate_id == item.category_id}" @click="tabCate(item)">
								{{item.name}}
							</div>
						</div>
						<div class="bg_fff article-main">
							<div class="article-main-title flex-between alcenter">
								<div>相关文章</div>
								<div class="refush" v-if="labelId" @click="handleRefush">重置</div>
							</div>
							<div class="mt13">
								<template v-if="articleList.length > 0">
									<div class="flex alcenter article-main-item" v-for="(item,index) in articleList" @click="openDetail(item)" :key="index">
										<div class="article-main-img">
											<img :src="item.image.file_path" class="article-main-img" />
										</div>
										<div  class="ml24 flex-one">
											<div class="article-main-swfgh">{{item.article_title}}</div>
											<div class="article-main-brief two-ouddle">{{item.abstract}}</div>
											<div class="flex-between alcenter mt30">
												<div class="flex alcenter">
													<img src="@/assets/article/time.png" class="time-img mr8" />
													<div class="ft16 c_737373">{{item.create_time}}</div>
												</div>
												<div class="flex alcenter c_737373 ft16">
													<div class="iconfont icon-Icon-guankanliang mr8"></div>
													<div>{{item.actual_views?item.actual_views:item.virtual_views}}</div>
												</div>
											</div>
										</div>
									</div>
									<div class="mt24">
										<el-pagination
											v-if="articleList.length > 0"
											:current-page="articleCurrent_page"
											:page-size="articlePer_page"
											layout="prev, pager, next, "
											:total="articleTotal"
											background
											@current-change="handleCurrentChange"
										/>
									</div>
								</template>
								<template v-else>
									<el-empty description="description" />
								</template>
							</div>
						</div>
						
					</div>
			</el-col>
			<el-col :span="4" >
				<articleRight @handleLabel="handleLabel"></articleRight>
			</el-col>
		</el-row>
		<div style="margin-bottom:20px;"></div>
		<userBottom></userBottom>
	</div>
</template>

<script>
	import cardHeader from '../../components/header/card.vue'
	import userBottom from '../../components/footer/user.vue'
	import eshopbg from '../../components/eshopbg/index.vue'
	import articleRight from './components/articleRight'
	export default{
		components:{
			cardHeader,
			userBottom,
			eshopbg,
			articleRight
		},
		data(){
			return{
				loading:false,
				hotLoading:false,
				cardList:[
				],
				cate_id:"",
				articleList:[],
				articleCurrent_page:1,
				articlePer_page:15,
				articleTotal:15,
				hotArticle:[],
				labelId:""
			}
		},
		async created() {
			this.getCategoryList()
			this.quryData()
		},
		methods:{
			handleRefush(){
				this.handleLabel({id:""})
			},
			handleLabel(item){
				this.labelId = item.id
				this.tabCate({category_id:""})
			},
			openDetail(item){
				this.$router.push({
					path:"/article/detail",
					query:{article_id:item.article_id}
				})
			},
			handleCurrentChange(e){
				this.articleCurrent_page = e
				this.news_api()
			},
			quryData(){
				let cate_id =  this.$route.query.cate_id
				let labelId = this.$route.query.labelId
				this.cate_id = cate_id?cate_id:''
				this.labelId = labelId
				this.news_api()
			},
			news_api(){
				this.loading = true
				this.$api.news_api({
					category_id:this.cate_id,
					page:this.articleCurrent_page,
					label_id:this.labelId
				})
				.then(result => {
					this.articleCurrent_page = result.data.current_page
					this.articlePer_page = result.data.per_page
					this.articleTotal = result.data.total
					this.articleList = result.data.data
					this.loading = false
				})
			},

			tabCate(item){
				this.cate_id = item.category_id
				this.articleCurrent_page = 1
				this.news_api()
			},
			getCategoryList(){
				return new Promise((resolve,reject) => {
					this.$api.categorypc()
					.then(res => {
						let list = res.data
						this.cardList = list
						// this.cate_id = this.cardList[0].category_id
						resolve()
					})
				})
			},
		}
	}
</script>

<style scoped lang="less">
	.user-bg-swg{
		background-color: #f7f7f7;
	}

	.article-main{
		padding: 21px 42px 60px 30px;
		margin-top: 19px;
	}
	.article-main-title{
		font-weight: 500;
		font-size: 20px;
		color: #333333;
	}
	.article-main-img{
		width: 244px;
		height: 150px;
		border-radius: 8px;
		transition: transform 0.3s ease; /* 平滑变化 */
	}
	.article-main-swfgh{
		font-weight: 400;
		font-size: 24px;
		color: #333333;
		transition: 0.3s;
	}
	.article-main-brief{
		font-weight: 400;
		font-size: 14px;
		color: #737373;
		line-height: 19px;

		margin-top: 12px;
	}

	.time-img{
		width: 18px;
		height: 18px;
	}
	.article-main-item{
		cursor: pointer;
	}
	.article-main-item:hover{
		background-color: #f4f7f9;
	}
	.article-main-item:hover .article-main-swfgh{
		color: #0EB6FF!important;
	}
	.article-main-item:hover > .article-main-img{
	transform: scale(1.1); /* 鼠标悬停时，图片放大1.1倍 */
	}
	.article-main-item + .article-main-item{
		margin-top: 40px;
	}

	/deep/ .el-pagination{
		float: right;
	}
	.refush{
		color: #0EB6FF;
		cursor: pointer;
		font-size: 16px;
	}
</style>