<template>
	<div class="login-bottomwew">
		<loginHeader></loginHeader>
		<div class="login-main mt40">
			<div class="flex alend text-left" style="width: 100%;">
				<div class="goLerfy" @click="getBack">
					<img src="../../assets/user/left.png" class="wefjtuyj-img" />
					<span class="ft20 font-weight500 ml24">登录已有账号</span>
				</div>
				<div class="ft40 font-weight600 c_333333">注册团团收账户</div>
			</div>
			<div class="">
				<div class="mt100">
					<el-form
						ref="formRef"
						:model="numberValidateForm"
						class="demo-ruleForm"
					>
							<el-form-item
								prop="phone"
								:rules="[
									{ required: true, message: '请输入手机号' },
								]"
							>
								<el-input
									v-model="numberValidateForm.phone"
									type="text"
									autocomplete="off"
									placeholder="请输入您的手机号"
								>
									<template #prefix>
										<el-icon><Iphone /></el-icon>
									</template>
								</el-input>
							</el-form-item>
							<el-form-item

							>
								<div style="width: 100%;"><slider ref="slider"></slider></div>
							</el-form-item>
							<el-form-item
								prop="code"
								:rules="[
									{ required: true, message: '请输入验证码' },
								]"
							>
								<el-input
									v-model="numberValidateForm.code"
									placeholder="请输入您的验证码"
									type="text"
									class="qwfregdfhg"
									autocomplete="off"
								>
									<template #prefix>
										<el-icon><Lock /></el-icon>
									</template>
								</el-input>
								<el-button class="getds-code" @click="getCode" :disabled="time!=0">{{buttonText}}</el-button>
							</el-form-item>
							<div class="mt50">
								<el-form-item
									prop="password"
									:rules="[
										{ required: true, message: '请输入密码' },
									]"
								>
									<el-input
										type="password"
										v-model="numberValidateForm.password"
										placeholder="在此输入您的密码"
										autocomplete="off"
									>
										<template #prefix>
											<el-icon><Unlock /></el-icon>
										</template>
									</el-input>
								</el-form-item>
								<el-form-item
									prop="password2"
									:rules="[
										{ required: true, message: '请输入密码' },
									]"
								>
									<el-input
										type="password"
										v-model="numberValidateForm.password2"
										placeholder="确认密码"
										autocomplete="off"
									>
										<template #prefix>
											<el-icon><Unlock /></el-icon>
										</template>
									</el-input>
								</el-form-item>
								<el-form-item
									prop="yq"
								>
									<el-input
										type="password"
										v-model="numberValidateForm.yq"
										placeholder="邀请码(选填)"
										autocomplete="off"
									>
										<template #prefix>
											<el-icon><Present /></el-icon>
										</template>
									</el-input>
								</el-form-item>
							</div>
							<div class="flex-center alcenter">
								<el-checkbox v-model="checked2" size="large" class="qfgd4etr"></el-checkbox>
								<div class="ml16 c_737373 ft16">
									<a href="javascript:void(0)" style="color: #737373;" @click="checked2 = !checked2">我已阅读并同意</a>
									<a class="ft16"  href="javascript:void(0)" style="color: #0EB6FF;" @click="$router.push({path:'/notice/agreement'})">《用户服务协议》 </a>
									<a href="javascript:void(0)" style="color: #737373;" @click="checked2 = !checked2">和</a>
									<a class="ft16" href="javascript:void(0)" style="color: #0EB6FF;"  @click="$router.push({path:'/notice/privacy'})">《用户隐私协议》</a>
								</div>
							</div>
							
						<el-form-item>
							<el-button class="login-button" color="#0EB6FF" @click="submitForm('formRef')">注册</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
		<loginFooter style="position: absolute;bottom: 0;width: 100%;"></loginFooter>
	</div>
</template>

<script>
	import loginHeader from '../../components/header/login.vue'
	import loginFooter from '../../components/footer/user.vue'
	import { ElMessage, ElMessageBox } from 'element-plus'
	import slider from '@/components/slider/index'
	import config from '@/config'
	export default {
		data(){
			return{
				numberValidateForm:{
					password:"",
					password2:"",
					phone:"",
					code:"",
					yq:""
				},
				checked2:false,
				time:0
			}
		},
		components:{
			loginHeader,
			loginFooter,
			slider
		},
		mounted() {
		  document.querySelector('body').setAttribute('style', 'background-color:#fff')
		},
		beforeDestroy() {
			document.querySelector('body').removeAttribute('style')
		},
		computed:{
			buttonText() {
			  return this.time != 0 ? `${this.time}秒后重新获取` : "获取验证码";
			},
		},
		methods:{
			getBack(){
				this.$router.push({path:'/login'})
			},
			getCode(){
				if(!this.$refs.slider.confirmSuccess){
					ElMessage({
						message: '请先滑块',
						type: 'error',
					})
					return
				}
				if (this.time != 0) {
					return;
				}
				this.$api.sendMessageRegister({
					mobile:this.numberValidateForm.phone,
				})
				.then(result => {
					if(result.code === 1){
						ElMessage({
							message: result.msg,
							type: 'success',
						})
						this.time = 60
						const times = setInterval(() => {
							this.times; //递减
							if (this.times === 0) {
								// === 0 变成获取按钮
								clearInterval(times);
							}
						}, 1000);
					}else{
						ElMessage({
							message: result.msg,
							type: 'error',
						})
					}
				})
			},
			handRegister(){
				if(!this.checked2){
					ElMessage({
						message: '请先阅读并同意团团收服务协议以及团团收隐私声明',
						type: 'error',
					})
					return
				}
				this.numberValidateForm['type'] = config.postType
				this.$api.register(this.numberValidateForm)
				.then(result => {
					if(result.code === 1){
						ElMessage({
							message: result.msg,
							type: 'success',
						})
						setTimeout(() => {
							this.getBack()
						},1000)
					}else{
						ElMessage({
							message: result.msg,
							type: 'error',
						})
					}
				})
			},
			submitForm(formName){
				this.$refs[formName].validate((valid) => {
				   if (valid) {
						this.handRegister()
				   } else {
				    return false;
				   }
				 });
			}
		}
	}
</script>

<style scoped lang="less">
	.login-main{
		width: 1440px;
		margin: 0 auto;
		margin-top: 80px;
		padding-bottom: 400px;
	}
	.mt80{
		margin-top: 80px;
	}
	.login-right{
		width: 515px;
		height: 600px;
		margin-left: 147px;
	}
	.login-left{
		width: 478px;
	}
	/deep/.el-tabs__nav{
		width: 100%;
	}
	/deep/.el-tabs__item{
		flex: 1;
		font-size: 24px;
		font-weight: 600;
	}
	/deep/.el-tabs__active-bar{
		height: 4px;
	}
	/deep/.el-tabs{
		--el-tabs-header-height: 70px;
	}
	/deep/.el-input{
				height: 52px;
	}
	/deep/.el-form-item__content{

	}
	.qwfregdfhg{
		width: 250px!important;
	}
	/deep/.el-form-item__error{
		padding-top: 10px;
		font-size: 14px;
	}
	/deep/.el-form-item{
		width: 382px;
		margin: 0 auto;
		font-size: 16px;
		margin-bottom: 28px;
	}
	/deep/.el-input__wrapper{
		border-radius: 8px;
	}
	.login-button{
		width: 382px;
		height: 52px;
		color: #fff!important;
		margin: 0 auto;
		margin-top: 40px;
		border-radius: 8px;
	}
	.freyhgf-ftg{
		border-top: 1px dotted #E5E5E5;
		border-bottom: 1px dotted #E5E5E5;
		padding: 16px 0;
	}
	.feyggs{
		text-align: center;
		border-right: 1px solid #EDEDED;
	}
	.feyggs:last-child{
		border: none;
	}
	.wefjtuyj-img{
		width: 6px;
		height: 12px;
	}
	.goLerfy{
		flex: 0.4;
		cursor:pointer;
		padding-left: 180px;
		
	}
	.getds-code{
		width: 113px;
		height: 52px;
		color: #0EB6FF;
		border-radius: 8px;
		margin-left: 16px;
	}
	.ft16{
		font-size: 16px!important;
	}
	.qfgd4etr{
		margin-left: 60px;
	}
</style>