import { get,post } from "../utils/https.js"
const api = {
	userInfo: '/api/user/getUserInfo',//获取用户信息
	getUserRealStatus:'/api/user/getUserRealStatus',//获取实名信息
	getWithdrawType:'/api/user/getWithdrawType',//提现方式
	getWithdrawNotice:'/api/settings/getWithdrawNotice',
	getWithdrawList:"/api/user/getWithdrawList",//提现列表
	withdrawPrice:"/api/user/withdrawPrice", //余额提现
	WithdrawList:'/api/withdraw/getWithdrawList',//提现记录
	list: '/api/category.category/getCategoryList',//
	orderList:'/api/order/getOrderList',//订单列表
	getEamilCode:'/api/user/getEamilCode',//获取验证码邮箱	
	sendBindMessage:'/api/message.Phone_message/sendBindMessage',//获取修改手机号验证码
	bindPhone:'/api/settings/bindPhone',//修改绑定手机号
	sendBindMobileMessage:'/api/message.Phone_message/sendBindMobileMessage',//首次绑定手机号验证码
	bind:'/api/settings/bind',//首次绑定手机号
	newPhoneMessage:'/api/message.Phone_message/newPhoneMessage',//修改密码的验证码
	upPassword:'/api/settings/upPassword',//修改密码
	realUser:"/api/user/realUser",//初级认证
	aliAuthentication: '/api/user/aliAuthentication', //支付宝实名认证
	wxAuthentication: '/api/user/wxAuthentication', //微信实名认证
	getJsonQrcode:'/api/index/getJsonQrcode',//生成二维码接口
	setWithdrawAli:"/api/user/setWithdrawAli",//添加支付宝
	upWithdrawAli:'/api/user/upWithdrawAli',//修改支付宝账号
	delWithdrawAli:"/api/user/delWithdrawAli",//删除支付宝账号
	getWithdrawAppId:'/api/user/getWithdrawAppId',//获取微信提现的appid
	delWithdrawWx:"/api/user/delWithdrawWx",//删除微信账户
	bankListOne:'/api/Withdraw/bankListOne',//获取银行
	getBankName:'/api/Withdraw/getBankName',//实时获取银行名称
	setWithdrawBank:"/api/user/setWithdrawBank",//添加银行卡
	delWithdrawBank:'/api/user/delWithdrawBank',//删除银行卡
	upWithdrawBank:'/api/user/upWithdrawBank',//修改银行卡号
	getCardList:'/api/Statistics/getCardList',//统计
	getBalanceLog:'/api/Statistics/getBalanceLog',//资金明细
	surveyList:'/api/Survey/surveyList',//快速结算问题列表
	surveydesc:'/api/Survey/surveydesc',//快结算问题详情
	surveysubmitl:'/api/Survey/surveysubmit',//提交问题
	Log:'api/Log/index',//登录日志
	settlementsubmit:'/api/Survey/settlementSubmit',//提交结算数据
	approvalStatus:'/api/Survey/approvalStatus',//查看结算状态
	duediligence:'/api/Survey/dueDiligence',//尽职调查
	dueDiligenceStatus:'/api/Survey/dueDiligenceStatus',//查看尽职调查状态
	sendNotf:'/api/order/sendNotf',//补发通知
	sendSmsCaptcha: '/index.php?s=/api/message.Phone_message/sendMessage',//获取登录验证码
	phonePassLogin:'/index.php?s=/api/login/phonePassLogin',//密码登录
	phoneLogin:'/api/Login/phoneLogin',//手机验证码登录
	sendMessageRegister:'/api/message.Phone_message/sendMessageRegister',//获取注册验证码
	register:'/api/login/phoneRegister',//用户注册
	submitFeedback:"/api/feed_back/index", //意见返款
	today_statistics:'/api/Cardstatistics/today_statistics',
	cardlist:'/api/Cardstatistics/cardlist',
	detect_account_risks:'/api/user/detect_account_risks',//检测账号风险
	card_cancellation:'/api/Cardstatistics/card_cancellation',//销卡统计
	pc_signin:'/index/index/pc_signin',
	
	getNotice:'/api/Content/getNotice',//用户需知
	getGoodsList: '/api/goods.goods/getGoodsList',//卡券分类详情
	postOrder:'/index.php?s=/api/order/buyCart',//单卡提交
	batchPostOrder:'/index.php?s=/api/order/batchBuyCart',//批量提交
	searchGoods:'/index.php?s=/api/goods.goods/getGoodsList',//卡券搜索
	systemsettings:'/api/my/systemsettings',//平台设置
	getContactUs:'/api/my/getContactUs',//关于我们
	
	articleHotpc:'/index/api.article/hotpc',//热门资讯
	categorypc:'/index/api.article/categorypc',//文章分类
	noticepc:'/index/api.article/noticepc',//平台公告
	agreementpc:'/index/api.article/agreementpc',//各类协议
	news_api:'/api/Content/Articlelist',//资讯列表
	
	signin_wx:'/api/login/signin_wx',//pc授权登录
	getHotProduct:"/api/index/getHotProduct",//热门回收
	getContent:'/api/Content/getContent',//
	
	randomlist:'/api/content/randomlist',//随机文章
	relevantlist:'/api/content/relevantlist',//相关文章（都不传值是最新文章）
	labellist:'/api/content/labellist',//标签列表
	getGoodsSystem:'/api/goods.goods/getGoodsSystem',//卡券提交页seo
	Articledetail:'/api/Content/Articledetail',//文章详情
}
//热门回收
export const userInfo = param => post(api.userInfo, param);
// 获取实名信息
export const getUserRealStatus = param => get(api.getUserRealStatus, param);

export const getWithdrawType = param => get(api.getWithdrawType, param);

export const getWithdrawNotice = param => get(api.getWithdrawNotice, param);

export const getWithdrawList = param => get(api.getWithdrawList, param);

export const withdrawPrice = param => post(api.withdrawPrice, param);

export const WithdrawList = param => post(api.WithdrawList, param);

export const list = param => get(api.list, param);

export const orderList = param => get(api.orderList, param);

export const getEamilCode = param => post(api.getEamilCode, param);

export const sendBindMessage = param => post(api.sendBindMessage, param);

export const bindPhone = param => post(api.bindPhone, param);

export const sendBindMobileMessage = param => post(api.sendBindMobileMessage, param);

export const bind = param => post(api.bind, param);

export const newPhoneMessage = param => post(api.newPhoneMessage, param);

export const upPassword = param => post(api.upPassword, param);

export const realUser = param => post(api.realUser, param);

export const aliAuthentication = param => get(api.aliAuthentication, param);

export const wxAuthentication = param => get(api.wxAuthentication, param);

export const getJsonQrcode = param => get(api.getJsonQrcode, param);

export const setWithdrawAli = param => post(api.setWithdrawAli, param);

export const upWithdrawAli = param => post(api.upWithdrawAli, param);

export const delWithdrawAli = param => post(api.delWithdrawAli, param);

export const getWithdrawAppId = param => get(api.getWithdrawAppId, param);

export const delWithdrawWx = param => post(api.delWithdrawWx, param);

export const bankListOne = param => get(api.bankListOne, param);

export const getBankName = param => post(api.getBankName, param);

export const setWithdrawBank = param => post(api.setWithdrawBank, param);

export const delWithdrawBank = param => post(api.delWithdrawBank, param);

export const upWithdrawBank = param => post(api.upWithdrawBank, param);

export const getCardList = param => get(api.getCardList, param);

export const getBalanceLog = param => get(api.getBalanceLog, param);

export const Log = param => get(api.Log, param);

export const surveyList = param => get(api.surveyList, param);

export const surveydesc = param => post(api.surveydesc, param);

export const surveysubmitl = param => post(api.surveysubmitl, param);

export const settlementsubmit = param => post(api.settlementsubmit, param);

export const approvalStatus = param => get(api.approvalStatus, param);

export const duediligence = param => post(api.duediligence, param);

export const dueDiligenceStatus = param => get(api.dueDiligenceStatus, param);

export const sendNotf = param => post(api.sendNotf, param);

export const sendSmsCaptcha = param => post(api.sendSmsCaptcha, param);

export const phonePassLogin = param => post(api.phonePassLogin, param);

export const phoneLogin = param => post(api.phoneLogin, param);

export const sendMessageRegister = param => post(api.sendMessageRegister, param);

export const register = param => post(api.register, param);

export const submitFeedback = param => post(api.submitFeedback, param);

export const today_statistics = param => get(api.today_statistics, param);

export const cardlist = param => get(api.cardlist, param);

export const detect_account_risks = param => get(api.detect_account_risks, param);

export const card_cancellation = param => get(api.card_cancellation, param);

export const pc_signin = param => get(api.pc_signin, param);

export const getNotice = param => get(api.getNotice, param);

export const getGoodsList = param => get(api.getGoodsList, param);

export const postOrder = param => post(api.postOrder, param);

export const batchPostOrder = param => post(api.batchPostOrder, param);

export const searchGoods = param => post(api.searchGoods, param);

export const systemsettings = param => get(api.systemsettings, param);

export const getContactUs = param => get(api.getContactUs, param);

export const articleHotpc = param => get(api.articleHotpc, param);

export const categorypc = param => get(api.categorypc, param);

export const noticepc = param => get(api.noticepc, param);

export const agreementpc = param => get(api.agreementpc, param);

export const news_api = param => get(api.news_api, param);

export const signin_wx = param => post(api.signin_wx, param);

export const getHotProduct = param => get(api.getHotProduct, param);

export const getContent = param => get(api.getContent, param);

export const randomlist = param => get(api.randomlist, param);

export const relevantlist = param => get(api.relevantlist, param);

export const labellist = param => get(api.labellist, param);

export const getGoodsSystem = param => get(api.getGoodsSystem, param);

export const Articledetail = param => get(api.Articledetail, param);