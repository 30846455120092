<template>
	<div class="user-l">
		<div class="">
			<div class="">
				<template  v-for="(item,index) in Data" :key="index">
					<div :to="{path:item.url}"   class="button-ripple flex " :class="{'text-main':tabStr === item.url}" @click="openUrl(item)" >
						<div v-if="tabStr === item.url" class="active"></div>
						<div class="ml24">{{item.title}}</div>
					</div >
					<!-- <div class="hint" v-if="(index+1) % 3 === 0 && index !=0"></div> -->
				</template>
			</div>

		</div>
	</div>
</template>

<script>
	export default{
		props:{
			tabStr:{
				type:String,
				default:"/notice/platform"
			}
		},
		data(){
			return{
				mobile:"",
				Data:[
					{
						title:"官方公告",
						url:"/notice/platform",
						url1:"",
						down:false
					},
					{
						title:"关于我们",
						url:"/notice/brief",
						url1:"",
						down:false
					},
					{
						title:"常见问题",
						url:"/notice/question",
						url1:"",
						down:false
					},
					{
						title:"服务协议",
						url:"/notice/agreement",
						url1:"",
						down:false
					},
					{
						title:"隐私协议",
						url:"/notice/privacy",
						url1:"",
						down:false
					},
					{
						title:"回收协议",
						url:"/notice/protocol",
						url1:"",
						down:false
					},
					{
						title:"回收说明",
						url:"/notice/statement",
						url1:"",
						down:false
					}
				],
				userInfo:JSON.parse(localStorage.getItem("userInfo"))
			}
		},
		created(){
			// this.mobile = JSON.parse(localStorage.getItem('datum')).mobile
		},
		methods:{
			openUrl(item){
				this.$router.push({
					path:item.url
				})
			},
		}
	}
</script>

<style scoped lang="less">
	.user-l{
		min-height: 765px;
		/* width: 16.9%; */
		float: left;
		margin-right: 24px;
		border-radius: 7px;
		// min-width: 280px;
	}
	.user-dsw{
		padding: 29px 0px 0;
		padding-bottom: 27.5px;
	} 
	.user-dsw a:hover{
		color: #333; 
	}
	.user-dsw a img{
		width: 19px;
		height: 20.12px;
		margin-right: 16px;
	}
	.user-dsw a{
		font-size: 18px;
		display: flex;
		align-items: center;
		color: #333;
		text-decoration:none;
		position: relative;

		
	}
	.user-dsw a + a{
		margin-top: 27px;
	}
	.button-ripple .active{
		// color: #333;
		background-color: #0EB6FF;
		width: 6px;
		height: 6px;
		border-radius: 100%;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		margin: auto 0;
	}
	.button-ripple{
		position: relative;
		line-height: 21px;
		font-weight: 400;
		font-size: 18px;
		cursor: pointer;
		transition:  .3s; /* 平滑变化 */
	}
	.button-ripple:hover{
		color: #0EB6FF;
		
	}
	.button-ripple + .button-ripple{
		margin-top: 40px;
	}
	.text-main{
		color: #0EB6FF;
	}
</style>