<template>
	<div class="user-bg-swg html-two">
		<uerHeader  type="" class="bg_fff"></uerHeader>
		<div >
			<el-row :gutter="24" class=" wdsffdzcdtg mt40">
				<el-col :span="4" :offset="2">
					<userLeft></userLeft>
				</el-col>
				<el-col :span="16" >
					<div class="m-content fix">
						<div class="user-main">
							<div v-loading="loading">
								<div>
									<el-config-provider :locale="zhCn">
										<el-date-picker
											v-model="dateCreate"
											type="date"
											placeholder="请选择起始时间"
											size="large"
											:prefix-icon="false"
										/>
										<span class="mlr8">-</span>
										<el-date-picker
											v-model="dateLast"
											type="date"
											placeholder="请选择截止时间"
											size="large"
										/>
									</el-config-provider>
									<el-button type="primary" color="#0EB6FF" class="statistics-buttom" @click="search">确定</el-button>
								</div>
								<div class="bg_fff pd24 mt16 rounded">
									<div class="flex alcenter">
										<a class="wdszxty " @click="handleData(-1)" :class="{'card-type-active':time_id === -1}">全部</a>
										<a v-for="(item,index) in dataType" class="wdszxty" :key="index" @click="handleData(item.value)" :class="{'card-type-active':time_id === item.value}">
											{{item.name}}
										</a>
									</div>
								</div>
								<div class="bg_fff mt24 pt24 rounded">
									<el-table :data="tableData" stripe style="width: 100%"
											:cell-style="{ textAlign: 'center' ,height:'64px','color':'#737373','font-size':'16px'}"
											:header-cell-style="{ 'text-align': 'center','color':'#333333','font-size':'18px'}"
												 >
										<el-table-column prop="time" label="日期" ></el-table-column>
										<el-table-column prop="number_cards_success" label="成功卡数" >
											<template #default="scope,index">
												<p class='c_43DB8E'>{{scope.row.number_cards_success }}</p>
											</template>
										</el-table-column>
										<el-table-column prop="recycle_price_success" label="成功金额" >
											<template #default="scope,index">
												<p class='c_43DB8E'>{{scope.row.recycle_price_success}}</p>
											</template>
										</el-table-column>
										<el-table-column prop="rebate_price_success" label="获得佣金" >
											<template #default="scope,index">
												<p class="c_FF5BA0">{{scope.row.rebate_price_success}}</p>
											</template>
										</el-table-column>
										<el-table-column prop="number_cards_error" label="失败卡数" >
											<template #default="scope,index">
												<p class="c_FF7544">{{scope.row.number_cards_error}}</p>
											</template>
										</el-table-column>
										<el-table-column prop="number_withdraw" label="提现次数" >
											<template #default="scope,index">
												<p class="c_FF7544">{{scope.row.number_withdraw}}</p>
											</template>
										</el-table-column>
										<el-table-column prop="real_price_success" label="提现金额" >
											<template #default="scope,index">
												<p class="c_FF7544">{{scope.row.real_price_success}}</p>
											</template>
										</el-table-column>
										<el-table-column prop="number_finance" label="补款笔数" >
											<template #default="scope,index">
												<p class="c_333333">￥{{scope.row.number_finance}}</p>
											</template>
										</el-table-column>
										<el-table-column prop="finance_money" label="补款金额" >
											<template #default="scope,index">
												<p class="c_333333">￥{{scope.row.finance_money}}</p>
											</template>
										</el-table-column>
									</el-table>
									<div class="flex-center mt24 pb25 bottom-pagin">
										<el-pagination
											v-model:current-page="currentPage3"
											:background="true"
											layout="prev, pager, next, jumper"
											:total="total"
											@current-change="handleCurrentChange"
										/>
									</div>
								</div>
							</div>
					
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<userBottom></userBottom>
	</div>
</template>

<script>
	import uerHeader from '../../components/header/card.vue'
	import userLeft from '../../components/left/index.vue'
	import userBottom from '../../components/footer/user.vue'
	import zhCn from'element-plus/lib/locale/lang/zh-cn'
	export default{
		data(){
			return{
				dateCreate:"",
				dateLast:"",
				zhCn,
				dataType:[
					{
						name:'今天',
						value:1
					},
					{
						name:'近七天',
						value:7
					},
					{
						name:'一个月',
						value:10
					},
					{
						name:'三个月',
						value:30
					}
				],
				tableData:[],
				currentPage3:1,
				total:100,
				loading:false,
				time_id:-1,
				queryParam:{}
			}
		},
		components:{
			uerHeader,
			userLeft,
			userBottom
		},
		created() {
			this.getData()
		},
		methods:{
			search(){
				this.time_id = -1
				this.getData()
			},
			handleData(time_id){
				this.time_id = time_id
				this.dateCreate = ''
				this.dateLast = ''
				this.getData()
			},
			handleCurrentChange(e){
				this.currentPage = e
				this.getData()
			},
			getData(){
				let queryParam = {}
				if(this.time_id != -1){
					queryParam['time'] = this.time_id
				}
				if(this.dateCreate){
					queryParam['timeon'] = this.dateCreate
				}
				if(this.dateLast){
					queryParam['timeend'] = this.dateLast
				}
				this.$api.card_cancellation({
					page:this.currentPage3,
					...queryParam
				})
				.then(result => {
					console.log(result)
					this.tableData = result.data.data
					this.currentPage3 = result.data.current_page
					this.total = result.data.total
				})
			}
		}
	}
</script>

<style scoped>
	/deep/ .el-input__prefix-inner{
		display:none;
	}
	.timedfa{
		background: #FFFFFF;
		border-radius: 8px;
	}
	/deep/  .el-input__wrapper{
		background: #FFFFFF;
		border-radius: 8px;
		box-shadow: none;
		width: 150px;
		height: 42px;
	}
	.statistics-buttom{
		color:#fff!important;
		width: 100px;
		height: 42px;
		border-radius: 8px;
		margin-left: 16px;
	}
	.wdszxty{
		font-size: 16px;
		cursor:pointer;
	}
	.wdszxty:hover{
		color: #0EB6FF;
		font-weight: 600;
	}
	.wdszxty + .wdszxty{
		margin-left: 60px;
	}
	.card-type-active{
		color: #0EB6FF;
		font-weight: 600;
	}
</style>