module.exports = {
  // 系统名称
  name: "团团收",
  // 必填: 后端api地址, 参照下面格式
  // 例如: https://www.你的域名.com/index.php?s=/api
	// http://tuantshou.com
	apiUrl: "https://www.tuantuanshou.com",
	baseUrl:'https://www.tuantuanshou.com',
	fronUrl:"https://www.tuantuanshou.com/",
	// 上传图片url
	uploadUrl:"/api/upload/image",
  // // #ifndef APP-PLUS
  // apiUrl: '/api',
  // // #endif
  wxapp_id:"10001",
	postType:10
}
 