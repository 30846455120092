<template>
	<div>
		<div class="container">
			<div class="header-box">
			  <div class="header">
			    <div class="header-logo" @click="openSkip('home')">
			      <div>
			        <img
			         :src="type === 'home' ? require('@/assets/home/top_logo.png'):require('@/assets/card/logo-hear.png')"
			          class="header-logo-image"
			        />
			      </div>
			    </div>
			    <ul class="header-navigation">
			      <li class="nav-item">
			        <a href="javascript:void(0);"  :class="{'nav-link':type != 'home'}" @click="openSkip('home')">
			          <img src="@/assets/card/home.png" class="nav-icon" v-show="type != 'home'" />
								<img src="@/assets/home/home.png" class="nav-icon" v-show="type == 'home'" />
								<span class="qwfhghv">首页</span>
			          
			        </a>
			      </li>
			      <li class="nav-item" >
			        <a href="javascript:void(0);" :class="{'nav-link':type != 'home','active-header':type === 'card'}" @click="openSkip('card')" >
			          <img :src="type === 'card'? require('@/assets/home/goods.png') : require('@/assets/card/goods.png')" class="nav-icon" v-show="type != 'home'" />
								<img src="@/assets/home/goods.png" class="nav-icon" v-show="type == 'home'" />
								<span class="qwfhghv">卡券回收</span>
			          
			        </a>
			      </li>
			      <li class="nav-item">
			        <a href="javascript:void(0);" :class="{'nav-link':type != 'home','active-header':type === 'wx'}" @click="openSkip('wx')">
								<img :src="type === 'wx'? require('@/assets/home/wx.png') : require('@/assets/card/wx.png')" class="nav-icon" v-show="type != 'home'" />
								<img src="@/assets/home/wx.png" class="nav-icon" v-show="type == 'home'" />
								<span class="qwfhghv">公众号</span>
			        </a>
			      </li>
			      <li class="nav-item">
			        <a href="javascript:void(0);" :class="{'nav-link':type != 'home','active-header':type === 'notice'}" @click="openSkip('notice')">
								<img :src="type === 'notice'? require('@/assets/home/notic.png') : require('@/assets/card/notice.png')" class="nav-icon" v-show="type != 'home'" />
								<img src="@/assets/home/notic.png" class="nav-icon" v-show="type == 'home'" />
								<span class="qwfhghv">官方公告</span>
			          
			        </a>
			      </li>
			      <li class="nav-item">
			        <a href="javascript:void(0);" :class="{'nav-link':type != 'home','active-header':type === 'message'}"  @click="openSkip('message')">
								<img :src="type === 'message'? require('@/assets/home/books.png') : require('@/assets/card/books.png')" class="nav-icon" v-show="type != 'home'" />
								<img src="@/assets/home/books.png" class="nav-icon" v-show="type == 'home'" />
								<span class="qwfhghv">行业资讯</span>
			          
			        </a>
			      </li>
			      <li class="nav-item">
			        <a href="javascript:void(0);" :class="{'nav-link':type != 'home','active-header':type === 'firm'}" @click="openSkip('firm')">
								<img :src="type === 'firm'? require('@/assets/home/firm.png') : require('@/assets/card/firm.png')" class="nav-icon" v-show="type != 'home'" />
								<img src="@/assets/home/firm.png" class="nav-icon" v-show="type == 'home'" />
								<span class="qwfhghv">企业回收</span>
			          
			        </a>
			      </li>
			    </ul>
			  </div>
			  <div class="user-action" v-if="!token">
						<img src="@/assets/card/avtive.png" class="user-avatar" :style="{background:type === 'home'?'':'#C4C4C4'}"  />
						<span :style="{color:type === 'home'?'#fff':'#333'}">
						  <a href="javascript:void(0);" class="login-btn" :style="{color:type === 'home'?'#fff':'#333'}" @click="openLogin">登录</a><span class="line">/</span
						  ><a href="javascript:void(0);" class="register-btn" :style="{color:type === 'home'?'#fff':'#333'}" @click="openRegister">注册</a>
						</span>

			  </div>
				<!-- @mouseleave="fqwfyobb" -->
				<div class="user-action " style="position: relative;"  v-else>
<!-- 					<a href="javascript:void(0);"  @mouseenter="show_account = true" @mouseleave="fqwfyobb"  class="ft16  qwdscvv font-weight500 c_fff" :class="{'qdscgg':type != 'home'}"  @click="openUser" >
						个人中心
						<div class="tdfewtghh zoomIn animated" v-if="show_account" @mouseenter="fqwfggggg" @mouseleave="show_account = false;eofke=false">
							<div class="navbar-account-name">
								<h3 class="ellipsis">我的账户</h3>
							</div>
							<div class="navbar-account-nav">
								<ul class="clearfix " >
									<li>
										<el-button @click.stop="openSkip('card')">我要卖卡</el-button>
									</li>
									<li>
										<el-button @click.stop="openOrder">我的订单</el-button>
									</li>
									<li>
										<el-button @click.stop="openWidthdraw">我要提现</el-button>
									</li>
									<li>
										<el-button @click.stop="openRecord">提现记录</el-button>
									</li>
								</ul>
							</div>
							<div class="navbar-account-set clearfix">
								<el-link class="pull-right" @click="goOut">安全退出</el-link>
							</div>
						</div>
					</a> -->

					    <el-popover
					      :width="300"
					      popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 20px;"
					    >
					      <template #reference>
					        <el-link @click="openUser"  :underline="false" :class="{'qdscgg':type != 'home'}" class="c_fff ft16  qwdscvv font-weight500">个人中心</el-link>
					      </template>
					      <template #default>
									<div class="tdfewtghh">
										<div class="navbar-account-name">
											<h3 class="ellipsis" style="margin-top: 0;">我的账户</h3>
										</div>
										<div class="navbar-account-nav">
											<ul class="clearfix " >
												<li>
													<el-button @click.stop="openSkip('card')">我要卖卡</el-button>
												</li>
												<li>
													<el-button @click.stop="openOrder">我的订单</el-button>
												</li>
												<li>
													<el-button @click.stop="openWidthdraw">我要提现</el-button>
												</li>
												<li>
													<el-button @click.stop="openRecord">提现记录</el-button>
												</li>
											</ul>
										</div>
										<div class="navbar-account-set clearfix" >
											<el-link class="pull-right" @click="goOut">安全退出</el-link>
										</div>
									</div>

					      </template>
					    </el-popover>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import config from '../../config.js'
	export default{
		props:{
			type: {
				type:String,
				default: 'card',
			}
		},
		data(){
			return{
				dialogVisible:false,
				baseUrl:config.baseUrl,
				token:localStorage.getItem("token"),
				show_account:false,
				eofke:false
			}
		},
		methods:{
			fqwfggggg(){
				this.eofke = true
				this.show_account = true
			},
			openShop(){
				this.$router.push({path:'/card/huishou'})
			},
			fqwfyobb(){
				setTimeout(() => {
					if(!this.eofke){
						this.show_account = false
					}
				},1000)
			},
			openOut(){
				this.dialogVisible = true
			},
			goOut(){
				localStorage.clear()
				this.$router.push( {path:'/login'})
				// window.location.href = baseUrl
			},
			openSkip(type){
				if(type === 'home'){
					this.$router.push({path:'/'})
				}else if(type === 'card'){
					this.$router.push({path:'/card/huishou'})
				}else if(type === 'wx'){
					this.$router.push({path:'/official/index'})
				}else if(type === 'notice'){
					this.$router.push({path:'/notice/platform'})
				}else if(type === 'message'){
					this.$router.push({path:'/article/index'})
				}else if(type === 'firm'){
					this.$router.push({path:'/firm/index'})
				}else{
					
				}
			},
			refresh(){
				location.reload();
			},
			openOrder(){
				this.$router.push({path:'/user/order'})
			},
			openWidthdraw(){
				this.$router.push({path:'/user/withdraw'})
			},
			openRecord(){
				this.$router.push({path:'/user/withdraw_record'})
			},
			openLogin(){
				this.$router.push({path:'/login'})
			},
			openRegister(){
				this.$router.push({path:'/register'})
			},
			openUser(){
				this.$router.push({path:'/user'})
			}
		}
	}
</script>

<style scoped lang="less">
	.qdscgg{
		color: #0eb6ff!important;
	}
	// .pt6{
	// 	padding-top: 6px;
	// }
	// .home-header-center-item + .home-header-center-item{
	// 	margin-left: 29px;
	// }
	// .home-header-center-item{
	// 	color: #333;
	// 	border-radius: 16px;
	// 	padding: 0 10px;
	// }
	// .home-header-center-item:hover span{
	// 	background-color: #0EB6FF;
	// 	color: #fff;
	// }
	// .wfsdfrty{
	// 	background-color: #0EB6FF!important;
	// 	color: #fff!important;
	// }
	// .home-header-center-item span{
	// 	padding: 3px 16px;
	// 	border-radius: 16px;
	// 	transition: .3s;
	// 	font-size: 18px;
	// }
	// .home-header-center img{
	// 	height: 26px;
	// 	width: 25px;
	// 	margin-right: 7px;
	// }
	// .home-header-center-item img{
	// 	margin-right: 5px;
	// }
	// .wqv-avtive{
	// 	background-color: #f2f2f2;
	// 	position: relative;
	// 	width: 28px;
	// 	height: 28px;
	// 	border-radius:100%;
	// 	text-align: center;
	// }
	.container{
		width: 1440px;
		margin: 0 auto;
		// pos
		.header-box {
		  display: flex;
		  align-items: center;
		  justify-content: space-between;
		  padding: 22px 0;
		  .header {
		    display: flex;
		    align-items: center;
		    justify-content: space-between;
		    .header-logo {
		      > div {
		        img {
		          // height: 38px;
		        }
		      }
		    }
		    .header-navigation {
		      display: flex;
		      align-items: center;
		      margin-left: 85px;
		      li {
		        margin-right: 30px;
						// width: 121px;
		        a {
		          display: flex;
							transition: .3s;
							width: 113px;
							justify-content: center;
		          align-items: center;
		          font-weight: 500;
		          font-size: 18px;
		          line-height: 27px;
		          color: #ffffff;
		          font-family: Source Han Sans CN, Source Han Sans CN;
							padding: 10px 10px;
							border-radius: 36px;
		          img {
		            margin-right: 5px;
		          }
		        }

						.nav-link{
							color: #333;
						}
						a:hover{
							color: #00b3ff;
							background-color: #f3f3f3;
						}
						.active-header {
							background: #0EB6FF;
							color: #fff!important;
						}
						.active-header:hover {
							background: #0EB6FF;
							color: #fff!important;
						}
		      }
		    }
		  }
		  .user-action {
		    display: flex;
		    align-items: center;
		    img {
		      height: 28px;
		      margin-right: 16px;
					border-radius: 100%;
		    }
		    > span {
		      font-family: Source Han Sans CN, Source Han Sans CN;
		      font-weight: 500;
		      font-size: 16px;
		      color: #ffffff;
		      line-height: 24px;
		      .line {
		        margin: 0 20px;
		      }
		      a {
		        font-family: Source Han Sans CN, Source Han Sans CN;
		        font-weight: 500;
		        font-size: 16px;
		        color: #ffffff;
		        line-height: 24px;
		      }
		    }
		  }
		}
	}
	.haers-right{
		position: absolute;
		right: 30px;
		margin-top: -10px;
		margin-left: 40px;
	}
	/deep/.el-dropdown-link{
		color: #fff;
		border: none;
		box-shadow: none;
	}
	.go-out{
		width: 420px;
		height: 60px;
		color: #fff!important;
		margin-top: 50px;
	}
	.tdfewtghh{

		font-size: 14px;
		// min-width: 280px;
		text-align: center;
		// left: 0;
	}
	.navbar-account-name{
		text-align: center;
		overflow: hidden;
		text-overflow: ellipsis;
		word-wrap: normal;
		white-space: nowrap;
		color: #000;
		padding-top: 0;
		margin-top: 0!important;
	}
	.navbar-account-nav{
		// padding: 0px 15px 15px 15px;
		// padding-bottom: 16px;
	}
	.clearfix{
		width: 100%;
	}
	.clearfix li{
		width: 46%;
		text-align: center;
		float: left;
		padding: 5px;
	}
	.clearfix li button{
		width: 100%;
	}
	.navbar-account-set{
		padding: 15px 0px 0px 0px;
		color: #aaa;
		font-size: 13px;
		border-top: 1px dotted #eee;
		display: flex;
		justify-content: center;
		margin-top: 100px;
	}
	@-webkit-keyframes zoomIn {
	  from {
	    opacity: 0;
	    -webkit-transform: scale3d(.3, .3, .3);
	    transform: scale3d(.3, .3, .3);
	  }
	
	  50% {
	    opacity: 1;
	  }
	}
	
	@keyframes zoomIn {
	  from {
	    opacity: 0;
	    -webkit-transform: scale3d(.3, .3, .3);
	    transform: scale3d(.3, .3, .3);
	  }
	
	  50% {
	    opacity: 1;
	  }
	}
	
	.zoomIn {
		-webkit-animation-name: zoomIn;
		animation-name: zoomIn;
		-webkit-transform-origin: 50% 0;
		transform-origin: 50% 0;
	}
	.animated {
	  -webkit-animation-duration: .3s;
	  animation-duration: .3s;
	  -webkit-animation-fill-mode: both;
	  animation-fill-mode: both;
	}
	.qwdscvv{
		color: #fff;
		font-size: 16px;
	}
	.qwdscvv:hover{
		color: #fff;
	}
</style>