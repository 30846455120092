<template>
	<div class="user-bg-swg html" >
		<div class="bg_fff">
			<cardHeader :type="'notice'"></cardHeader>
		</div>
		<eshopbg></eshopbg>
		<el-row :gutter="20"
			
			element-loading-text="加载中..."
			class="wdsffdzcdtg mt24">
			<el-col :span="4" :offset="3">
				<left :tabStr="'/notice/brief'"></left>
			</el-col>
			<el-col :span="15" >
				<div class="notice-main">
					<div class="notice-title">关于我们</div>
					<div class="bg_fff mt24 notice-content ft16 c_737373" v-loading="loading">
						<div class="zijt-no">
							<div v-html="HTMLDecode(systemsettings.about)">
							
							</div>
							<div class="brief-bottom">
								<div>公司名称：{{getContactUs.title?getContactUs.title:''}}</div>
								<div >客服热线：{{getContactUs.mobile?getContactUs.mobile:''}}</div>
							</div>
						</div>

					</div>
				</div>
			</el-col>
		</el-row>
		<userBottom></userBottom>
	</div>
</template>

<script>
	import cardHeader from '../../components/header/card.vue'
	import userBottom from '../../components/footer/user.vue'
	import eshopbg from '../../components/eshopbg/index.vue'
	import left from '../../components/left/notice.vue'
	export default{
		components:{
			cardHeader,
			userBottom,
			left,
			eshopbg
		},
		data(){
			return{
				loading:false,
				article_content:"",
				getContactUs:JSON.parse(localStorage.getItem("getContactUs")),
				systemsettings:{}
			}
		},
		created() {
			this.systemsettings = JSON.parse(localStorage.getItem("systemsettings"))
			if(!this.systemsettings){
				this.systemsettingsLwwf()
			}
			// this.agreementpc()
		},
		methods:{
			HTMLDecode(text) {
				var temp = document.createElement("div");
				temp.innerHTML = text;
				var output = temp.innerText || temp.textContent;
				temp = null;
				return output;
			},
			systemsettingsLwwf(){
				this.$api.systemsettings()
				.then(result => {
					this.systemsettings = result
					localStorage.setItem("systemsettings",JSON.stringify(result));
				})
			},
			agreementpc(){
				this.loading = true
				this.$api.agreementpc({
					type:3
				})
				.then(result => {
					this.article_content = result.data.article_content
					this.loading = false
				})
			}
		}
	}
</script>

<style scoped lang="less">
	.user-bg-swg{
		background-color: #f7f7f7;
	}
	.brief-bottom{
		border-top: 1px dashed #E5E5E5;
		padding-top: 40px;
		margin-top: 40px;
	}
</style>