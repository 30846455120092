<template>
	<div class="user-bg-swg html-two">
		<uerHeader  type="" class="bg_fff"></uerHeader>
		<div >
			<el-row :gutter="24" class=" wdsffdzcdtg mt40">
				<el-col :span="4" :offset="2">
					<userLeft></userLeft>
				</el-col>
				<el-col :span="16" >
					<div class="m-content fix">
						<div class="user-main">
							<div  class="bg_fff rounded rfwwd pd24" v-loading="loading">
								<el-alert type="info" show-icon :closable="false" v-if="messageBool">
									<p v-for="(item,index) in messVideo" :key="index">{{item.title}}</p>
								</el-alert>
								<el-alert type="info" show-icon :closable="false" v-if="messageVideoBool" class="mb20">
									<p v-for="(item,index) in inHandVideo" :key="index">{{item.title}}</p>
									<p>1、材料内容真实有效，不得做任何修改；</p>
									<p>2、卡密来源的证明材料，需上传视频；</p>
									<p>3、上传视频只支持MPEG、AVI、WMV、MOV、3GP、MP4格式，最大不超过30M；</p>
									<p>4、如卡券为本人购买，需提供本人购买证明（材料中需体现购买平台实名认证界面）、卡券购买平台卡号+卡密界面</p>
									<p>5、证明来源合法性的材料包括但不限于：供卡合同、收卡合同、交易场景、qq或微信 沟通记录、客户签字或盖章的对于卡券来源的情况说明等,必须真实有效，不得虚构</p>
								</el-alert>
								<div style="width:800px;margin:0 auto">
									<div v-if="fProblemBool">
										<h1 style="font-size: 30px;">1·{{fProblem.title}}</h1>
										<el-checkbox-group v-model="checkList">
											<el-checkbox :label="item.content" v-for="(item,index) in fProblem.desc" :key='index' />
										</el-checkbox-group>
										<el-button color="#0EB6FF"  class="settie-buttfona mt40" :disabled="checkList.length != fProblem.desc.length" @click='openNext'>下一步</el-button>
									</div>
									<div v-if="messageBool">
										<h1 >尽职调查表单</h1>
										<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm" label-position="left">
											<el-form-item label="姓名" prop="name">
												<el-input v-model="ruleForm.name" ></el-input>
											</el-form-item>
											<el-form-item label="身份证号" prop="idcard">
												<el-input v-model="ruleForm.idcard" ></el-input>
											</el-form-item>
											<el-form-item label="联系手机号" prop="phone">
												<el-input v-model="ruleForm.phone" ></el-input>
											</el-form-item>
											<el-form-item label="联系微信号" prop="wxchat">
												<el-input v-model="ruleForm.wxchat" ></el-input>
											</el-form-item>
											<el-form-item label="联系地址" prop="address">
												<el-input v-model="ruleForm.address" ></el-input>
											</el-form-item>
											<el-form-item label="卡券来源说明" prop="sourcedescription">
												<el-input type="textarea" v-model="ruleForm.sourcedescription"></el-input>
											</el-form-item>
		<!-- 									<el-form-item>
												<el-button color="#0EB6FF"  class="settie-buttfona mt40" @click="submitForm('ruleForm')">提交</el-button>
											</el-form-item> -->
										</el-form>
										<el-form :model="ruleFormImg" :rules="rulesImg" ref="ImgruleForm" label-width="200px" class="demo-ruleForm" label-position="left">
											<el-form-item label="身份证正面照片" prop="frontofidcard">
												<div class="flex alcenter">
													<el-upload
														class="avatar-uploader"
														list-type="picture-card"
														:action='config.baseUrl + config.uploadUrl'
														:on-success="frontofidcardSuccess"
														:before-upload="beforeAvatarUpload"
														:on-error="handleVideoError"
														:show-file-list="false"
														name="iFile"
														:headers="{
															'Access-Token':token
														}"
														
													>
														<img v-if="ruleFormImg.frontofidcard.file_path" :src="ruleFormImg.frontofidcard.file_path" class="updateImg" />
														<div class="itqdxgtt" v-else>
															<img src="../../assets/user/survery/card_1.png"/>
															<div class="center-trips">
																<div class="csaqw">
																	<el-icon class="fontrcot"><Plus /></el-icon>
																	<div>添加身份证正面照片</div>
																</div>
															</div>
														</div>
													</el-upload>
													<img src="../../assets/user/survery/card_1.png"  class="exampleImg"/>
												</div>
											</el-form-item>
											<el-form-item label="身份证反面照片" prop="backofidcard">
												<div class="flex alcenter">
													<el-upload
														class="avatar-uploader"
														list-type="picture-card"
														:action='config.baseUrl + config.uploadUrl'
														:on-success="backofidcardSuccess"
														:before-upload="beforeAvatarUpload"
														:on-error="handleVideoError"
														:show-file-list="false"
														name="iFile"
														:headers="{
															'Access-Token':token
														}"
														
													>
														<img v-if="ruleFormImg.backofidcard.file_path" :src="ruleFormImg.backofidcard.file_path" class="updateImg" />
														<div class="itqdxgtt" v-else>
															<img src="../../assets/user/survery/card_2.png"/>
															<div class="center-trips">
																<div class="csaqw">
																	<el-icon class="fontrcot"><Plus /></el-icon>
																	<div>添加身份证反面照片</div>
																</div>
															</div>
														</div>
													</el-upload>
													<img src="../../assets/user/survery/card_2.png"  class="exampleImg"/>
												</div>
											</el-form-item>
											<el-form-item label="手持身份证照片" prop="holdingidcard">
												<div class="flex alcenter">
													<el-upload
														class="avatar-uploader"
														list-type="picture-card"
														:action='config.baseUrl + config.uploadUrl'
														:on-success="holdingidcardSuccess"
														:before-upload="beforeAvatarUpload"
														:on-error="handleVideoError"
														:show-file-list="false"
														name="iFile"
														:headers="{
															'Access-Token':token
														}"
													>
														<img v-if="ruleFormImg.holdingidcard.file_path" :src="ruleFormImg.holdingidcard.file_path" class="updateImg" />
														<div class="itqdxgtt" v-else>
															<img src="../../assets/user/survery/shouchi.png"/>
															<div class="center-trips">
																<div class="csaqw">
																	<el-icon class="fontrcot"><Plus /></el-icon>
																	<div>添加手持身份证照片</div>
																</div>
															</div>
														</div>
													</el-upload>
													<img src="../../assets/user/survery/shouchi.png"  class="exampleImg"/>
												</div>
											</el-form-item>
											<el-form-item label="手持身份证视频" prop="holdingvideo">
												<div class="flex alcenter">
													<el-upload
														class="avatar-uploader"
														list-type="picture-card"
														:action='config.baseUrl + config.uploadUrl'
														:on-success="holdingvideoSuccess"
														:before-upload="holdingvideoUpload"
														:on-error="handleVideoError"
														:show-file-list="false"
														name="iFile"
														:headers="{
															'Access-Token':token
														}"
													>
														<video v-if="ruleFormImg.holdingvideo.file_path" :src="ruleFormImg.holdingvideo.file_path" class="updateImg" :controls="true" :autoplay="false"></video>
														<div class="itqdxgtt" v-else>
															<img src="../../assets/user/survery/vido_card.png"/>
															<div class="center-trips">
																<div class="csaqw">
																	<el-icon class="fontrcot"><Plus /></el-icon>
																	<div>添加手持身份证【视频】</div>
																</div>
															</div>
														</div>
													</el-upload>
													<img src="../../assets/user/survery/vido_card.png"  class="exampleImg"/>
												</div>
											</el-form-item>
											<el-form-item label="上传卡密来源证明材料视频" prop="image">
												<el-upload
													class="avatar-uploader"
													list-type="picture-card"
													:action='config.baseUrl + config.uploadUrl'
													:on-success="imageSuccess"
													:before-upload="holdingvideoUpload"
													:on-error="handleVideoError"
													:show-file-list="false"
													name="iFile"
													:headers="{
														'Access-Token':token
													}"
												>
													<video v-if="ruleFormImg.image.file_path" :src="ruleFormImg.image.file_path" class="updateImg" :controls="true" :autoplay="false"></video>
													<div v-else>
														<div class="csaqw text-center">
															<el-icon class="fontrcot"><Plus /></el-icon>
															<div>上传卡密来源证明材料【视频】</div>
														</div>
													</div>
												</el-upload>
											</el-form-item>
											<el-form-item>
												<el-button color="#0EB6FF"  class="settie-buttfona mt40" @click="submitForm('ruleForm')">提交</el-button>
											</el-form-item>
										</el-form>
									</div>
									<div v-if="messageVideoBool">
		
									</div>
								</div>
							</div>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<userBottom></userBottom>
	</div>
</template>

<script>
	import uerHeader from '../../components/header/card.vue'
	import userLeft from '../../components/left/index.vue'
	import userBottom from '../../components/footer/user.vue'
	import config from '../../config.js'
	import { ElMessage, ElMessageBox } from 'element-plus'
	export default{
		data(){
			return{
				config,
				loading:false,
				fProblem:{
					title:'请仔细阅读以下内容并勾选！',
					desc:[
						{
							content:'我本人承诺该帐号一直是本人使用，没有出租、出借、出卖给他人使用。',
							bool:false
						},
						{
							content:'我本人承诺在团团收网站（tuantuanshou.con）提交的卡劵来源合法合规。',
							bool:false
						},
						{
							content:'我本人承诺在本次尽职调查所填写的内容和上传的材料都是本人操作，并且真实有效。',
							bool:false
						}
					]
				},
				messVideo:[
					{
						id:1,
						title:'1、请确保实名信息与本人一致如填写错误或使用其他人信息认证导致不能正常提现后果自负'
					},
					{
						id:2,
						title:'2、请确保输入的姓名与身份证号匹配'
					},
					{
						id:3,
						title:'3、未成年无法认证本平台，请在监护人协助下进行实名认证。'
					}
				],
				inHandVideo:[
					{
						id:1,
						title:'本人手持身份证拍摄视频，视频中需要说的内容是：“我叫XXX，身份证号：XXX，特此证明在团团收注册的用户ID为：XXX的账户，是我本人真实意愿开立且用于卡劵寄售，本人承诺帐号只有本人使用，并且卡密来源合法合规。'
					}
				],
				checkList:[],
				fProblemBool:true,
				messageBool:false,
				messageVideoBool:false,
				token:localStorage.getItem('token'),
				ruleForm: {
					name:"",
					idcard:"",
				  phone: '',
					wxchat:"",
					address:"",
					sourcedescription:"",
				},
				rules: {
				  name: [
				    { required: true, message: '请填写姓名', trigger: 'blur' }
				  ],
					idcard: [
					  { required: true, message: '请填写身份证号码', trigger: 'blur' }
					],
					phone: [
					  { required: true, message: '请填写手机号', trigger: 'blur' }
					],
					wxchat: [
					  { required: true, message: '请填写微信号', trigger: 'blur' }
					],
					address: [
					  { required: true, message: '请填写联系地址', trigger: 'blur' }
					],
					sourcedescription: [
					  { required: true, message: '请填写卡券来源说明', trigger: 'blur' }
					]
				},
				
				ruleFormImg:{
					frontofidcard:"",
					backofidcard:"",
					holdingidcard:"",
					holdingvideo:"",
					image:""
				},
				rulesImg:{
					frontofidcard: [
					  { required: true, message: '请上传身份证正面照片', trigger: 'blur' }
					],
					backofidcard: [
					  { required: true, message: '请上传身份证反面照片', trigger: 'blur' }
					],
					holdingidcard: [
					  { required: true, message: '请上传手持身份证照片', trigger: 'blur' }
					],
					holdingvideo: [
					  { required: true, message: '请上传手持身份证视频', trigger: 'blur' }
					],
					image: [
					  { required: true, message: '上传卡密来源证明材料视频', trigger: 'blur' }
					],
				}
			}
		},
		components:{
			uerHeader,
			userLeft,
			userBottom
		},
		methods:{
			openNext(){
				this.fProblemBool = false
				this.messageBool = true
			},

			handleVideoError(error,uploadFile,uploadFiles){
				ElMessage({
					message: error,
					type: 'error',
				})
			},
			beforeAvatarUpload(file) {
				var img = file.name.substring(file.name.lastIndexOf('.') + 1)
				const suffix = img === 'jpg'
				const suffix2 = img === 'png'
				const suffix3 = img === 'jpeg'
				const isLt1M = file.size / 1024 / 1024 < 1;
				if (!suffix && !suffix2 && !suffix3) {
						this.$message.error("只能上传图片！");
						return false
				}
				// 可以限制图片的大小
				if (!isLt1M) {
						this.$message.error('上传图片大小不能超过 1MB!');
				}
				return suffix || suffix2 || suffix3
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if(formName === 'ruleForm'){
							this.submitForm('ImgruleForm')
						}else{
							this.settlementsubmit()
						}
					} else {
						return false;
					}
				});
			},
			settlementsubmit(){
				this.loading = true
				let query = {}
				for(let i in this.ruleForm){
					query[i] = this.ruleForm[i]
				}
				for(let i in this.ruleFormImg){
					query[i] = this.ruleFormImg[i].file_id
				}
				query.user_id = localStorage.getItem("userInfo").user_id;
				this.$api.duediligence(query)
				.then(result => {
					this.loading = false
					if(result.code === 1){
						ElMessage({
							message: result.msg,
							type: 'success',
						})
						setTimeout(() => {
							this.$router.push({path:'/user/user_datum'})
						},1000)
					}else{
						ElMessage({
							message: result.msg,
							type: 'error',
						})
					}
				})
			},
			holdingvideoUpload(file){
				const fileSize = file.size / 1024 / 1024 < 300
				if (['video/mp4', 'video/ogg', 'video/flv', 'video/avi', 'video/wmv', 'video/rmvb', 'video/mov'].indexOf(file.type) === -1) {
					ElMessage({
						message: '请上传正确的视频格式',
						type: 'warning',
					})
				  return false
				}
				if (!fileSize) {
					ElMessage({
						message: '视频大小不能超过300MB',
						type: 'warning',
					})
				  return false
				}
			},
			frontofidcardSuccess(res, file) {
				this.ruleFormImg.frontofidcard = res.data
			},
			backofidcardSuccess(res, file) {
				this.ruleFormImg.backofidcard = res.data
			},
			holdingidcardSuccess(res, file) {
				this.ruleFormImg.holdingidcard = res.data
			},
			holdingvideoSuccess(res, file) {
				this.ruleFormImg.holdingvideo = res.data
			},
			imageSuccess(res, file){
				this.ruleFormImg.image = res.data
			}
		}
	}
</script>

<style scoped>
	/deep/.el-radio.el-radio--large{
		width: 100%;
	}
	/deep/.el-radio.el-radio--large .el-radio__input .el-radio__inner{
		width: 20px;
		height: 20px;
	}
	/deep/.el-radio.el-radio--large .el-radio__input .el-radio__inner::after{
		width: 8px;
		height: 8px;
	}
	/deep/.el-radio.el-radio--large .el-radio__label{
		font-size: 16px;
	}
	
	/deep/.el-checkbox__inner{
		width: 20px;
		height: 20px;
	}
	/deep/.el-checkbox__inner::after{
		width: 5px;
		height: 12px;
		left: 6px;
	}
	/deep/.el-checkbox__label{
		font-size: 20px;
	}
	/deep/.el-checkbox+.el-checkbox{
		margin-top: 20px;
	}
	
	/* 表单 */
	/deep/.el-form-item__label{
		font-size: 20px;
	}
	/deep/.el-input.is-disabled{
		height: 40px
	}
	/deep/.el-textarea__inner{
		min-height: 80px!important;
	}
	/deep/.el-form-item__content input{
		font-size: 20px;
	}
	/deep/.el-form-item__content textarea{
		font-size: 20px;
	}
	.settie-buttfona{
	 width: 116px;
	 height: 40px;
	 border-radius: 8px;
	 color: #ffffff!important;
	 font-size: 18px;
	 margin-left: 30px;
	}
	/deep/.avatar-uploader .el-upload {
	  border: 1px dashed var(--el-border-color);
	  border-radius: 6px;
	  cursor: pointer;
	  position: relative;
	  overflow: hidden;
	  transition: var(--el-transition-duration-fast);
	}
	
	.avatar-uploader .el-upload:hover {
	  border-color: var(--el-color-primary);
	}
	.el-icon.avatar-uploader-icon {
	  font-size: 28px;
	  color: #8c939d;
	  width: 178px;
	  height: 178px;
	  text-align: center;
	}
	
	.video-avatar {
	  width: 400px;
	  height: 250px;
	}
	.avatar-video{
		width:400px;
		height: 250px;
	}
	.itqdxgtt{
		position: relative;
	}
	.updateImg{
		width: 250px;
		height: 160px;
	}
	.exampleImg{
		width: 240px;
		height: 150px;
		margin-left: 80px;
	}
	.itqdxgtt img{
		width: 250px;
		height: 160px;
	}
	/deep/.el-upload--picture-card{
		width: 250px;
	}
	.center-trips{
		background-color: rgba(0, 0, 0, 0.5);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 160px;
		text-align: center;
		color: #ffffff;
		border-radius: 4px;
	}
	.center-trips .fontrcot{
		font-size: 30px;
		color: #fff;
	}
	.center-trips::before{
		content: "";
		width: 0;
		height: 100%;
		display: inline-block;
		position: relative;
		vertical-align: middle;
	}
	.csaqw{
		display: inline-block;
		vertical-align: middle;
	}
	.mb20{
		margin-bottom: 20px!important;
	}
</style>