<template>
	<div class="user-bg-swg html" >
		<div class="bg_fff">
			<cardHeader :type="'notice'"></cardHeader>
		</div>
		<eshopbg></eshopbg>
		<el-row :gutter="20"
			v-loading="loading"
			element-loading-text="加载中..."
			class="wdsffdzcdtg mt24">
			<el-col :span="4" :offset="3">
				<left :tabStr="'/notice/statement'"></left>
			</el-col>
			<el-col :span="15" >
				<div class="notice-main">
					<div class="" style="width: 100%;">
						<div class="notice-title">回收说明</div>
						<div class="bg_fff mt24 notice-content">
							<div class="article-content zijt-no">
								
								<recycleList></recycleList>
							</div>
						</div>
					</div>

				</div>
			</el-col>
		</el-row>
		<userBottom></userBottom>
	</div>
</template>

<script>
	import cardHeader from '../../components/header/card.vue'
	import userBottom from '../../components/footer/user.vue'
	import eshopbg from '../../components/eshopbg/index.vue'
	import left from '../../components/left/notice.vue'
	import recycleList from '@/components/recycleList/index'
	export default{
		components:{
			cardHeader,
			userBottom,
			left,
			eshopbg,
			recycleList
		},
		data(){
			return{
				loading:false
			}
		},
		created() {
			
		},
		methods:{
			
		}
	}
</script>

<style scoped lang="less">
	.user-bg-swg{
		background-color: #f7f7f7;
	}
</style>