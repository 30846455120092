<template>
	<div class="user-bg-swg html-two">
		<div>
			<uerHeader type="" class="bg_fff"></uerHeader>
						<el-row :gutter="24" class=" wdsffdzcdtg mt40">
							<el-col :span="4" :offset="2">
								<userLeft></userLeft>
							</el-col>
							<el-col :span="16" >
								<div class="m-content fix">

									<div class="user-main">
										<div v-loading="loading" class="bg_fff pd24 rounded rfwwd">
											<el-page-header @back="goBack" style="margin-bottom:20px;">
												<template #title >
													<span>返回</span>
												</template>
												<template #content>
													<span class="text-large font-600 mr-3">订单详情</span>
												</template>
											</el-page-header>
											<el-card shadow="hover" class="mb20 pb25"> 

												<ul class="order-delivery-steps">
													<li class="step-done">
														<div class="rate" :class="tableData.create_time?'rate-active':'-'">
															<i class="icon" :class="tableData.create_time?'active':'-'"></i>
														</div>
														<h5 class="ft16">{{tableData.create_time?'卖卡时间':'未知'}}</h5>
														<p>{{tableData.create_time?tableData.create_time:'-'}}</p>
													</li>
													<li class="step-done">
														<div class="rate" :class="tableData.create_time?'rate-active':'-'">
															<i class="icon" :class="tableData.create_time?'active':'-'"></i>
														</div>
														<h5 class="ft16">{{tableData.create_time?'受理成功':'等待受理'}}</h5>
														<p>{{tableData.create_time?tableData.create_time:'-'}}</p>
													</li>
													<li class="step-done">
														<div class="rate" :class="tableData.accomplish_date?'rate-active':'-'">
															<i class="icon" :class="tableData.accomplish_date?'active':'-'"></i>
														</div>
														<h5 class="ft16">{{tableData.accomplish_date?'验证卡券':'等待受理'}}</h5>
														<p>{{tableData.accomplish_date?tableData.accomplish_date:'-'}}</p>
													</li>
													<li class="step-done">
														<div class="rate" :class="tableData.delivery_status == 40 || tableData.delivery_status == 30?'rate-active':'-'">
															<i class="icon" :class="tableData.delivery_status == 40 || tableData.delivery_status == 30?'active':'-'"></i>
														</div>
														<h5 class="ft16" v-if="tableData.delivery_status == 40 || tableData.delivery_status == 30" :class="tableData.delivery_status == 30 ?'':'text-redd'">{{deliveryList[tableData.delivery_status].name}}</h5>
														<h5 class="ft16" v-else>等待受理</h5>
														<p v-if="tableData.delivery_status == 40 || tableData.delivery_status == 30">{{tableData.accomplish_date?tableData.accomplish_date:'-'}}</p>
														<p v-else>未完成</p>
													</li>
												</ul>
											</el-card>
											<el-descriptions size="large" border>
												<el-descriptions-item label="订单号">{{tableData.order_no}}</el-descriptions-item>
												<el-descriptions-item label="卡类">{{tableData.goods_name}}</el-descriptions-item>
												<el-descriptions-item label="卡号">{{tableData.card?tableData.card:'-'}}</el-descriptions-item>
												<el-descriptions-item label="卡密">{{tableData.card_pass?tableData.card_pass:'-'}}</el-descriptions-item>
												<el-descriptions-item label="面值">
													<span>￥{{tableData.total_price}}</span>
												</el-descriptions-item>
												<el-descriptions-item label="回收折扣">{{tableData.recycle_discount}}%</el-descriptions-item>
												<el-descriptions-item label="到账金额">
													<span class="c_43DB8E">￥{{tableData.recycle_price}}</span>
												</el-descriptions-item>
												<el-descriptions-item label="状态">
													<el-tag effect="dark" :type="tableData.delivery_status in deliveryList? deliveryList[tableData.delivery_status].class:'info'">{{tableData.delivery_status in deliveryList? deliveryList[tableData.delivery_status].name:未知}}</el-tag>
												</el-descriptions-item>
												<el-descriptions-item label="提交时间">
													<span>{{tableData.create_time}}</span>
												</el-descriptions-item>
												<el-descriptions-item label="说明">
													<span>{{tableData.result_message}}</span>
												</el-descriptions-item>
											</el-descriptions>
										</div>
									</div>
								</div>
							</el-col>
						</el-row>

		</div>
		<userBottom></userBottom>
	</div>
</template>

<script>
	import uerHeader from '../../components/header/card.vue'
	import userLeft from '../../components/left/index.vue'
	import userBottom from '../../components/footer/user.vue'
	export default{
		data(){
			return{
				tableData:[],
				loading:false,
				deliveryList:{
					'10': {name:'处理中',class:"",value:12},
					"20": {name:'待结算',class:"",value:13},
					"30": {name:'成功',class:"success",value:14},
					"40": {name:'失败',class:"danger",value:15},
					"50": {name:'待处理',class:"info"},
					"60": {name:'异常订单',class:"danger"},
				},
			}
		},
		components:{
			uerHeader,
			userLeft,
			userBottom
		},
		created(){
			this.getData()
		},
		methods:{
			goBack(){
				this.$router.push({path:'/user/order'})
			},
			getData(){
				this.loading = true
				this.$api.orderList({
					type:1,
					page:1,
					name:this.$route.query.order_no
				})
				.then(result => {
					this.loading = false
					this.tableData = result.data.data[0]
				})
			},
		}
	}
</script>

<style scoped>
	/deep/.el-descriptions--large .el-descriptions__body .el-descriptions__table .el-descriptions__cell{
		font-size: 17px;
	}
	.step-done{
		float: left;
		width: 25%;
		overflow: hidden;
		text-align: center;
		color: #ccc;
	}
	.rate {
		height: 0;
		margin: 3px 0;
		border-top: 4px solid #e5e5e5;
	}
	.rate-active {
	  border-color: #7abd54!important;
	}
	.text-redd{
		color: red!important;
	}
	.rate .icon {
		display: block;
		width: 12px;
		height: 12px;
		overflow: hidden;
		margin: -10px auto 0;
		position: relative;
		border: 3px solid #fff;
		background: #dfdfdf;
		border-radius: 50%;
	}
	.rate .active {
	  background: #68af3f!important;
	}
	.step-done h5 {
		color: #4c9a1f;
		font-weight: 700;
	}
	.step-done h5 {
		padding-top: 7px;
	  /* padding: 7px 0 0px 0; */
	}
	.step-done p {
		font-size: 13px;
		margin: 0 30px;
	}
</style>