<template>
	<div v-loading="hotLoading">
		<div class="bg_fff hot-article-right">
			<div class="hot-article-title">
				热门文章
			</div>
			<div class="mt24">
				<div v-for="(item,index) in hotArticle" :key="index"   class="hot-article-item  ">
					<i class="hotp-index" :class="{'hotp-index-red':index === 0,'hotp-index-orange':index === 1,'hotp-index-yellow':index === 2,'hotp-index-cow':index === 0 || index === 1 || index === 2}" >{{index+1}}</i>
					<a class="hot-fasfqw one-ouddle" @click="openDetail(item)">{{item.article_title}}</a>
				</div>
			</div>
		</div>
		<div class="bg_fff hot-article-right">
			<div class="hot-article-title">
				{{type === 'list'?"随机文章":'最新文章'}}
			</div>
			<div class="mt24">
				<div v-for="(item,index) in type==='list' ?randomArticleList:newList" :key="index"   class="hot-article-item  ">
					<i class="hot-article-item-dot"></i>
					<a class="hot-fasfqw one-ouddle" @click="openDetail(item)">{{item.article_title}}</a>
				</div>
			</div>
		</div>
		<div class="bg_fff hot-article-right">
			<div class="hot-article-title">
				标签
			</div>
			<div class="mt24">
				<div class="flex flex-wrap">
					<el-tag v-for="(item,index) in label" :key="index" class="article-tag circularaw" :type="random()" @click="openLabel(item)" effect="dark" >
						{{item.name}}
					</el-tag>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			type: {
				type:String,
				default: 'list',
			}
		},
		data(){
			return{
				hotArticle:[],
				label:[],
				hotLoading:false,
				randomArticleList:[],
				newList:[]
			}
		},
		created(){
			if(this.type === 'list'){
				this.randomlist()
			}else{
				this.relevantlist()
			}
			this.articleHotpc()
			this.labellist()
		},
		methods:{
			labellist(){
				this.$api.labellist()
				.then(result => {
					this.label = result.data
				})
			},
			relevantlist(){
				this.$api.relevantlist()
				.then(result => {
					this.newList = result.data.data
				})
			},
			randomlist(){
				this.$api.randomlist()
				.then(result => {
					this.randomArticleList = result.data
				})
			},
			random(){
				let typeList = [
					'primary',
					'success',
					'info',
					'warning',
					'danger'
				]
				var randomNumber = Math.floor(Math.random() * 5)
				return typeList[randomNumber]
			},
			openDetail(item){
				if(this.$route.path === '/article/detail'){
					this.$emit('handleDetail',item)
				}else{
					this.$router.push({
						path:"/article/detail",
						query:{article_id:item.article_id}
					})
				}
			},
			openLabel(item){
				if(this.$route.path === '/article/index'){
					this.$emit('handleLabel',item)
				}else{
					this.$router.push({
						path:"/article/index",
						query:{labelId:item.id}
					})
				}
			},
			articleHotpc(){
				this.hotLoading = true
				this.$api.articleHotpc()
				.then(result => {
					this.hotArticle = result.data.data
					this.hotLoading = false
				})
			},
		}
	}
</script>

<style scoped lang="less">
	.hotp-index{
		background: #dee2e6;
		color: #515355;
		font-size: 12px;
		border: 1px solid #c4c4c4;
		height: 18px;
		line-height: 20px;
		display: inline-block;
		width: 18px;
		text-align: center;
		font-weight: 100;
		margin-right: 5px;
		box-shadow: 1px 1px 4px rgb(194, 200, 206, .8);
	}
	.hotp-index-red{
		background-color: #f62020;
		border: 1px solid #f62020;
		box-shadow:1px 1px 4px rgba(246, 32, 32, 0.8);
	}
	.hotp-index-cow{
		color: #fff;
	}
	.hotp-index-orange{
		background-color: #f65220;
		border: 1px solid #f65220;
		box-shadow: 1px 1px 4px rgba(246, 82, 32, 0.8);
	}
	.hotp-index-yellow{
		background-color: #f0a413;
		border: 1px solid #f0a413;
		box-shadow: 1px 1px 4px rgba(240, 164, 18, 0.8);
	}
	.article-tag{
		margin-top: 10px;
		margin-right: 6px;
	}
	.hot-article-item-dot{
		width: 4px;
		height: 4px;
		background-color: #919ba6;
		border-radius: 100%;
		margin-right: 10px;
		    display: inline-block;
	}
	.hot-article-right{
		padding: 28px 20px;
		// min-width: 280px;
	}
	.hot-article-right + .hot-article-right{
		margin-top:16px;
	}
	.hot-article-title{
		font-weight: 500;
		font-size: 24px;
		color: #333333;
	}
	.hot-article-item{
		margin-top: 18px;
		cursor: pointer;
		width: 100%;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.hot-fasfqw:hover{
		color: #0EB6FF;
		background-size: 100% 1px;
	}
	.hot-article-item .qwdsfimg{
		width: 100%;
		height: 108px;
		transition: transform 0.3s ease; /* 平滑变化 */
	}
	.hot-article-item:nth-child(2n){
		margin-right: 0;
	}
	.hot-fasfqw{
		font-size: 18px;
		color: #333333;
		transition: 0.3s;
		flex:1;
		background: linear-gradient(#0EB6FF,#0EB6FF) no-repeat;
		background-size: 0 1px;
		background-position: 0 100%;
		transition: background-size .5s cubic-bezier(.2,.63,.36,1);
		position: relative;
	}
</style>