<template>
	<div class="user-bg-swg html-two">
		<uerHeader  type="" class="bg_fff"></uerHeader>
		<div >
			<el-row :gutter="24" class=" wdsffdzcdtg mt40">
				<el-col :span="4" :offset="2">
					<userLeft></userLeft>
				</el-col>
				<el-col :span="16" >
					<div class="m-content fix">
						<div class="user-main">
							<div class="bg_fff pt24 rounded" v-loading="loading">
								<el-table :data="tableData" stripe style="width: 100%"
											:cell-style="{ textAlign: 'center' ,height:'64px','color':'#737373','font-size':'16px'}"
											:header-cell-style="{ 'text-align': 'center','color':'#333333','font-size':'18px'}"
											 >
									<el-table-column prop="create_time" label="登录时间" ></el-table-column>
									<el-table-column prop="type_name" label="登录平台" ></el-table-column>
									<el-table-column prop="user_id" label="用户id" ></el-table-column>
									<el-table-column prop="ip" label="IP地址" ></el-table-column>
									<el-table-column prop="address" label="地址" ></el-table-column>
								</el-table>
								<div class="flex-center mt24 pb25 bottom-pagin">
									<el-pagination
										v-model:current-page="currentPage"
										:background="true"
										layout="prev, pager, next, jumper"
										:default-page-size="per_page"
										:total="total"
										@current-change="handleCurrentChange"
									/>
								</div>
							</div>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<userBottom></userBottom>
	</div>
</template>

<script>
	import uerHeader from '../../components/header/card.vue'
	import userLeft from '../../components/left/index.vue'
	import userBottom from '../../components/footer/user.vue'
	export default{
		data(){
			return{
				tableData:[],
				currentPage:1,
				total:100,
				loading:false,
				per_page:25
			}
		},
		components:{
			uerHeader,
			userLeft,
			userBottom
		},
		created() {
			this.getData()
		},
		methods:{
			getData(){
				this.loading = true
				this.$api.Log({
					page:this.currentPage
				})
				.then(result => {
					this.currentPage = result.data.current_page
					this.total = result.data.total
					this.per_page = result.data.per_page
					this.tableData = result.data.data
					this.loading = false
				})
			},
			handleCurrentChange(e){
				this.currentPage = e
				this.getData()
			}
		}
	}
</script>

<style scoped>

</style>