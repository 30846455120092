<template>
	<div class="user-bg-swg html-two">
		<uerHeader  type="" class="bg_fff"></uerHeader>
		<div >
			<el-row :gutter="24" class=" wdsffdzcdtg mt40">
				<el-col :span="4" :offset="2">
					<userLeft></userLeft>
				</el-col>
				<el-col :span="16" >
					<div class="m-content fix">
						<div class="user-main" >
							<div v-loading="loading">
								<div >
									<el-config-provider :locale="zhCn">
										<el-date-picker
											v-model="dateCreate"
											type="date"
											placeholder="请选择起始时间"
											size="large"
											:prefix-icon="false"
										/>
										<span class="mlr8">-</span>
										<el-date-picker
											v-model="dateLast"
											type="date"
											placeholder="请选择截止时间"
											size="large"
										/>
									</el-config-provider>
									<el-button type="primary" color="#0EB6FF" class="statistics-buttom" @click="search">确定</el-button>
								</div>
								<div class="bg_fff pd24 mt16 rounded">
									<div class="flex alcenter">
											<a class="wdszxty " href="javascript:;" :class="{'card-type-active':kaleiActive1 === -1}" @click="kaleiActive1 = -1;start_time = null;end_time = null;getData()">全部</a>
											<a href="javascript:;" v-for="(item,index) in dataType" class="wdszxty" :key="index" :class="{'card-type-active':kaleiActive1 === index}" @click="kaleibtn1(index)">
												{{item.name}}
											</a>
									</div>
								</div>
								<div class="bg_fff mt24 pt24 rounded">
									<el-table :data="tableData" stripe style="width: 100%"
									
									v-if="tableData.length > 0"
											:cell-style="{ textAlign: 'left' ,height:'64px','color':'#737373','font-size':'16px'}"
											:header-cell-style="{ 'text-align': 'left','color':'#333333','font-size':'18px'}"
												 >
										<el-table-column prop="create_time" label="日期" ></el-table-column>
										<el-table-column prop="old_money" label="原金额" >
											<template #default="scope,index">
												<p class="c_FF7544">{{scope.row.old_money}}</p>
											</template>
										</el-table-column>
										<el-table-column prop="money" label="变动金额" >
											<template #default="scope,index">
												<p class='c_FF5BA0'>{{scope.row.money}}</p>
											</template>
										</el-table-column>
										<el-table-column prop="new_money" label="变动后金额" >
											<template #default="scope,index">
												<p class="c_43DB8E">{{scope.row.new_money}}</p>
											</template>
										</el-table-column>
										<el-table-column prop="describe" label="描述说明" >
										</el-table-column>
									</el-table>
									<el-empty description="还没有内容" v-else />
									<div class="flex-right mt24 pb25 bottom-pagin">
										<el-pagination
											v-model:current-page="currentPage"
											:background="true"
											layout="prev, pager, next, jumper"
											:total="total"
											@current-change="handleCurrentChange"
										/>
									</div>
								</div>
							</div>
					
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<userBottom></userBottom>
	</div>
</template>

<script>
	import uerHeader from '../../components/header/card.vue'
	import userLeft from '../../components/left/index.vue'
	import userBottom from '../../components/footer/user.vue'
	import zhCn from'element-plus/lib/locale/lang/zh-cn'
	export default{
		data(){
			return{
				dateCreate:"",
				dateLast:"",
				zhCn,
				dataType:[
					{
						name:'今天',
						id:1
					},
					{
						name:'近七天',
						id:2
					},
					{
						name:'一个月',
						id:3
					},
					{
						name:'三个月',
						id:4
					}
				],
				kaleiActive1:-1,
				start_time:null,
				end_time:null,
				tableData:[],
				currentPage:1,
				total:100,
				loading:false
			}
		},
		components:{
			uerHeader,
			userLeft,
			userBottom
		},
		created() {
			this.getData()
		},
		methods:{
			search(){
				this.kaleiActive1 = -1
				this.start_time = this.dateLast
				this.end_time = this.dateCreate
				this.getData()
			},
			fun_date(aa) {
				var date1 = new Date(),
				time1 = date1.getFullYear() + "-" + (date1.getMonth() + 1) + "-" + date1.getDate(); //time1表示当前时间
				var date2 = new Date(date1);
				date2.setDate(date1.getDate() + aa + 1);
				let mm = date2.getMonth() + 1
				let dd = date2.getDate()
				if (mm < 10) {
					mm = String(mm)
					mm = '0' + mm
				}
				if (dd < 10) {
					dd = String(dd)
					dd = '0' + dd
				}
				var time2 = date2.getFullYear() + "-" + mm + "-" + dd;
				return time2
			},
			kaleibtn1(index) {
				this.kaleiActive1 = index
				this.dateLast = ""
				this.dateCreate = ""
				let times = this.dataType[index]
				let yy = new Date().getFullYear();
				let mm = new Date().getMonth() + 1;
				let dd = new Date().getDate();
				if (mm < 10) {
					mm = String(mm)
					mm = '0' + mm
				}
				if (dd < 10) {
					dd = String(dd)
					dd = '0' + dd
				}
				if (times.id === 1) {
					this.start_time = yy + '-' + mm + '-' + dd
					this.end_time = yy + '-' + mm + '-' + dd
				} else if (times.id === 2) {
					this.start_time = yy + '-' + mm + '-' + dd
					this.end_time = this.fun_date(-7)
				} else if (times.id === 3) {
					this.start_time = yy + '-' + mm + '-' + dd
					this.end_time = this.fun_date(-30)
				} else if (times.id === 4) {
					this.start_time = yy + '-' + mm + '-' + dd
					this.end_time = this.fun_date(-90)
				}
				this.getData()
			},
			handleCurrentChange(e){
				this.currentPage = e
				this.getData()
			},
			getData(){
				this.loading = true
				this.$api.getBalanceLog({
					page:this.currentPage,
					start_time: this.end_time,
					end_time: this.start_time,
				})
				.then(result => {
					this.currentPage = result.data.list.current_page
					this.total = result.data.list.total
					this.tableData = result.data.list.data
					this.loading = false
				})
			},
		}
	}
</script>

<style scoped>
	/deep/ .el-input__prefix-inner{
		display:none;
	}
	.timedfa{
		background: #FFFFFF;
		border-radius: 8px;
	}
	/deep/  .el-input__wrapper{
		background: #FFFFFF;
		border-radius: 8px;
		box-shadow: none;
		width: 150px;
		height: 42px;
	}
	.statistics-buttom{
		color:#fff!important;
		width: 100px;
		height: 42px;
		border-radius: 8px;
		margin-left: 16px;
		font-size: 16px;
	}
	.wdszxty{
		font-size: 16px;
		color: #333;
	}
	.wdszxty + .wdszxty{
		margin-left: 60px;
	}
	.card-type-active{
		color: #0EB6FF;
		font-weight: 600;
	}
	.card-type-active{
		color: #0EB6FF!important;
		font-weight: 600;
	}
</style>