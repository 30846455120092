<template>
	<div>
		<div class="text-center ft16 mt100">
			<div class="text-left" style="width:500px;margin:0 auto" v-loading='sdwwq'>
				<div v-if="!realInfo.name && !advaRealInfo.name">
					<div class="flex alcenter">
						<div class="real-title sqschyu">真实姓名</div>
						<el-input  placeholder="在此输入您的姓名" v-model="name" />
					</div>
					<div class="flex alcenter mt16 sqschyu">
						<div class="real-title">身份证号码</div>
						<el-input  placeholder="在此输入您的身份证号" v-model="card" />
					</div>
					<div class="flex alcenter mt16">
						<div class="real-title">&nbsp;</div>
						<el-button color="#0EB6FF" class="real-button" @click="openReal">开始实名认证</el-button>
					</div>
				</div>
				<div v-if="realInfo.name && !advaRealInfo.name">
					<div class="text-center">
						<p class="c_737373">请完成高级实名认证</p>
						<div class="flex-center alcenter mt16">
							<div class="ft40 font-weight500">请确保高级实名认证和初级认证是同一人</div>
						</div>
						<div class="flex-center alcenter mt24">
							<img src="../../../assets/user/user_dum/warning.png" class="waring-img" />
							<div class="ft12 ">完成高级认证后，可用额度不限额，平台所有产品均可使用</div>
						</div>
						<div class="mt24">
							<el-button color="#4F8BFF" class="ipdate-button" @click="openRenzhengAlready" :loading="loading">
								<!-- <img src="../../../assets/user/withdraw/alipay.png"/> -->
								支付宝认证
							</el-button>
							<el-button color="#3ED77B" class="ipdate-button" :loading="loading" @click="openRenzhengwxready">
								<!-- <img src="../../../assets/user/withdraw/wx.png"/> -->
								微信认证
							</el-button>
						</div>
					</div>
				</div>
				<div v-if="realInfo.name && advaRealInfo.name">
					<el-card shadow="hover">
						<el-descriptions title="已完成实名认证" size="large">
							<el-descriptions-item label="姓名">{{advaRealInfo.name}}</el-descriptions-item>
							<el-descriptions-item label="证件号">{{advaRealInfo.id_number.substring(0, 3) + '**********' + advaRealInfo.id_number.substring(15, 17) }}</el-descriptions-item>
							<el-descriptions-item label="认证方式">
								<el-tag >{{advaRealInfo.mode}}</el-tag>
							</el-descriptions-item>
							<el-descriptions-item label="认证时间">{{advaRealInfo.create_time}}</el-descriptions-item>
						</el-descriptions>
					</el-card>
				</div>
			</div>

		</div>
		<pop ref="popMain"></pop>
		<el-dialog v-model="visible" title="" width='522px' custom-class="pop-radius" @close='claswoyt' :close-on-click-modal="false">
			<div class="text-center">
				<p class="ft24 font-weight700 c_333333">请用{{type === 1?'支付宝':'微信'}}扫码下方二维码</p>
				<p class="c_737373 ft18 mt20 " style="">{{topMsg}}</p>
				<img :src="img_real"  class="mt30 withdawfv" />
			</div>
			<template #footer>
				<span class="dialog-footer">
				</span>
			</template>
		</el-dialog>
	</div>

</template>

<script>
	import { ElMessage, ElMessageBox } from 'element-plus'
	import pop from './components/pop/index'
	import config from '../../../config.js'
	export default{
		data(){
			return{
				realInfo:{},
				advaRealInfo:{},
				name:"",
				card:"",
				sdwwq:false,
				loading:false,
				img_real:"",
				topMsg:"",
				visible:false,
				type:null,
				timerResult:null,
			}
		},
		components:{
			pop
		},
		created() {
			this.getData()
		},
		methods:{
			claswoyt(){
				clearInterval(this.timerResult);
			},
			openRenzhengwxready(){
				this.type = 2
				this.loading = true
				this.$api.wxAuthentication()
				.then(result => {
					if(result.code === 1){
						let url = result.data.url
						this.topMsg = result.data.content
						this.getImg(url)
					}
				})
			},
			openRenzhengAlready(){
				this.type = 1
				this.loading = true
				this.$api.aliAuthentication()
				.then(result => {
					if(result.code === 1){
						const token = localStorage.getItem("token");
						let url  = config.apiUrl+"/api/user/startCertify?id="+result.data.id+"&wxapp_id="+config.wxapp_id+"&token="+token
						this.topMsg = result.data.content
						this.getImg(url)
					}
				})
			},
			getImg(url){
				this.$api.getJsonQrcode({
					data:encodeURIComponent(url)
				})
				.then(res => {
					this.img_real = 'data:image/png;base64,' +res.data
					this.loading = false
					// this.$refs.popMain.oepnReal()
					this.visible = true
					this.timerResult = setInterval(async ()=> {
						let res = await this.loadDetail2()
						 if(res.name){
							 ElMessage({
							 	message: '认证成功',
							 	type: 'success',
							 })
							 this.visible = false
							 clearInterval(this.timerResult)
						 }
					},2000)
				})
			},
			postReal(){
				this.$api.realUser({
					name:this.name,
					card:this.card
				})
				.then(result => {
					if(result.code === 1){
						ElMessage({
							message: result.msg,
							type: 'success',
						})
						this.getData()
					}else{
						ElMessage({
							message: result.msg,
							type: 'error',
						})
					}
				})
			},
			openReal(){
				if(!this.name){
					ElMessage({
						message: '请输入真实姓名',
						type: 'warning',
					})
					return
				}
				if(!this.card){
					ElMessage({
						message: '请输入身份证号码',
						type: 'warning',
					})
					return
				}
				ElMessageBox.confirm(
					'请确保实名信息与本人一致如填写错误或使用其他人信息认证导致不能正常提现后果自负',
					'温馨提示',
					{
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning',
					}
				)
					.then(() => {
						this.postReal()
					})
					.catch(() => {

					})
			},
			async getData(){
				this.sdwwq = true
				await this.loadDetail1()
				if(this.realInfo.name){
					await this.loadDetail2()
				}
				this.sdwwq = false
			},
			loadDetail2() {
				return new Promise((resolve,reject) => {
					this.$api.getUserRealStatus({
						type:2
					})
					.then(result => {
						this.advaRealInfo = result.data
						resolve(result.data)
					})
				})
			},
			loadDetail1() {
				return new Promise((resolve,reject) => {
					this.$api.getUserRealStatus({
						type:1
					})
					.then(result => {
						this.realInfo = result.data
						resolve(result.data)
					})
				})
			},
		}
	}
</script>

<style scoped>
	.real-title{
		width:150px;
	}
	.el-input{
		border-radius: 8px;
		height: 52px;
		box-show:none;
		width: 316px;
	}
	.real-button {
		border-radius: 8px;
		height: 60px;
		color:#fff!important;
		width: 316px;
	}
	.ipdate-button{
		height: 60px;
		color: #fff!important;
		width: 216px;
	}
	/deep/.pop-radius{
		border-radius: 24px;
	}
	/deep/.el-dialog__headerbtn .el-dialog__close{
		font-size: 20px;
	}
	.withdawfv{
		width: 194px;
		height: 194px;
	}
</style>