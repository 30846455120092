<template>
	<div class="user-bg-swg html-two">
			<uerHeader class="bg_fff" type=""></uerHeader>
			<div class="">
				<el-row :gutter="24" class=" wdsffdzcdtg mt40">
					<el-col :span="4" :offset="2">
						<userLeft></userLeft>
					</el-col>
					<el-col :span="16" >
						<div class="user-main " >
							<div class="" v-loading="loading">
								<div class="flex flex-between">
									<el-input v-model="serch" placeholder="输入关键字/订单号" class="order-input"  :prefix-icon="Search" @keyup.enter="getData()">
										<template #prepend>
											<el-icon><Search /></el-icon>
										</template>
										<template #append>
											<div @click="getData" class="wduqsc">搜索</div>
										</template>
									</el-input>
									<div class="order-serch-button">
										<el-button class="c_3ED77B" @click="exportExeclAll">导出EXCELL</el-button>
										<el-button @click="exportExecl">只导出成功记录</el-button>
									</div>
								</div>
								<div class="mt24 order-screen bg_fff rounded " >
									<div class="flex alcenter order-goods qwdxzvdfrty">
										<a class="wdszxty " href="javascript:;" :class="{'card-type-active':cardId === -1}" @click="cardId = -1;cardNmae = '';getData()">全部</a>
										<a v-for="(item,index) in cardType" class="wdszxty" :key="index" href="javascript:;" @click="cardify(item,index)" :class="{'card-type-active':cardId === item.cate_id}">
											{{item.name}}
										</a>
									</div>
									<div class="flex alcenter mt16 qwdxzvdfrty">
										<a class="wdszxty " href="javascript:;" :class="{'card-type-active':kaleiActive1 === -1}" @click="kaleiActive1 = -1;start_time = null;end_time = null;getData()">全部</a>
										<a href="javascript:;" v-for="(item,index) in dataType" class="wdszxty" :key="index" :class="{'card-type-active':kaleiActive1 === index}" @click="kaleibtn1(index)">
											{{item.name}}
										</a>
									</div>
									<div class="flex alcenter mt30 qwdxzvdfrty">
										<a  href="javascript:;" class="wdszxty " :class="{'card-type-active':deliveryType === -1}" @click="deliveryType = -1;getData()">全部</a>
										<a  href="javascript:;" v-for="(item,index) in delivery" class="wdszxty" :key="index" :class="{'card-type-active':deliveryType === item.value}" @click="orderType(item)">
											{{item.name}}
										</a>
									</div>
								</div>
								<div class="bg_fff mt24 pt24 rounded">
									<el-table :data="tableData" stripe style="width: 100%" v-if="tableData.length > 0"
											:cell-style="{ textAlign: 'left' ,height:'64px','color':'#737373','font-size':'14px'}"
											:header-cell-style="{ 'text-align': 'left','color':'#333333','font-size':'18px'}"
												 >
										<el-table-column prop="goods_name" label="回收类型"  width="200">
											 <template #default="scope,index">
													<div class="alcenter qwddscx " style="display:flex;">
														<img :src="scope.row.img?scope.row.img.file_path:''" />
														      <el-tooltip
														        class="box-item"
														        effect="dark"
														        :content="scope.row.goods_name"
														        placement="top-start"
														      >
																		<div class="ellipsis">{{scope.row.goods_name}}</div>
														        <!-- <el-button>top-start</el-button> -->
														      </el-tooltip>
													</div>
											 </template>
										</el-table-column>
										<el-table-column prop="order_no" label="订单号"  min-width="200"></el-table-column>
										<el-table-column prop="card" label="卡号/卡密" min-width="200">
											<template #default="scope,index">
												<p class='ft16'>{{scope.row.card?scope.row.card:'无卡号'}}</p>
												<p class='ft16 mt7'>{{scope.row.card_pass?scope.row.card_pass:'无卡密'}}</p>
											</template>
										</el-table-column>
										<el-table-column prop="delivery_status" label="状态">
											<template #default="scope,index">
												<p class='ft16' :style="{'color':deliveryList[scope.row.delivery_status].class}">{{deliveryList[scope.row.delivery_status].name}}</p>
											</template>
										</el-table-column>
										<el-table-column prop="create_time" label="回收时间" min-width="200">
											<template #default="scope,index">
												<p class="ft16">{{scope.row.create_time}}</p>
											</template>
										</el-table-column>
										<el-table-column prop="total_price" label="实际面值" min-width="100">
											<template #default="scope,index">
												<p class="ft16">￥{{scope.row.total_price}}</p>
											</template>
										</el-table-column>
										<el-table-column prop="recycle_discount" label="折扣" min-width="100">
											<template #default="scope,index">
												<p class="ft16">{{scope.row.recycle_discount}}%</p>
											</template>
										</el-table-column>
										<el-table-column prop="result_message" label="说明"  min-width="150"></el-table-column>
										<el-table-column prop="handle" label="操作" min-width="100">
											<template #default="scope,index">
												<div >
													<div>
														<el-button color="#0EB6FF" class="menu-order menu-order-c-f" round @click="openDetail(scope.row.order_no)" >查看详情</el-button>
													</div>
													<!-- is_api === 1 -->
													<div class="mt8" v-if="(scope.row.delivery_status == 30 || scope.row.delivery_status === 40) && userInfo && userInfo.is_api === 1">
														<el-button color="#43DB8E" class="menu-order menu-order-c-f" round @click="reissue(scope.row)">补单</el-button>
													</div>
													<div class="mt8" v-if="scope.row.delivery_status === 20">
														<el-button  class="menu-order" round  @click="openSettle(scope.row)" >立即结算</el-button>
													</div>
												</div>

	<!-- 											<div class="mt10">
													<el-link type="primary" class="mr10" >查看详情</el-link>
													<el-link type="info" >立即结算</el-link>
												</div> -->
											</template>
										</el-table-column>
									</el-table>
									<el-empty description="还没有内容" v-else />
									<div class="flex-center mt24 pb25 bottom-pagin">
										<el-pagination
											v-model:current-page="currentPage"
											:background="true"
											layout="prev, pager, next, jumper"
											:total="total"
											@current-change="handleCurrentChange"
										/>
									</div>
								</div>
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
		<el-dialog v-model="dialogVisible" title="" width='522px' custom-class="pop-radius">
			<div class="text-center">
				<!-- <p class="ft24 font-weight700 c_333333">结算状态</p> -->
				<div v-if="settle.status === 0" class="alcenter flex-center">
					<el-icon class="waring-wds ft25"><InfoFilled /></el-icon>
					<span class="ft20 ml16">审核中</span>
				</div>
				<div v-if="settle.status === 1" class="alcenter flex-center">
					<el-icon class="success-wds ft25"><SuccessFilled /></el-icon>
					<span class="ft20 ml16">结算成功</span>
				</div>
				<div v-if="settle.status === 2 || settle.status === 3" class="alcenter flex-center">
					<el-icon class="error-wds ft25"><WarningFilled /></el-icon>
					<span class="ft20 ml16">{{settle.status === 2?'可重新提交':"审核驳回"}}</span>
				</div>
				<div v-if="settle.status === 4" class="alcenter flex-center">
					<el-icon class="error-wds ft25"><CircleCloseFilled /></el-icon>
					<span class="ft20 ml16">该订单不支持快速结算</span>
				</div>
				<div class="ft18 c_909399 mt24">
					<p v-if="settle.status === 3 || settle.status===2">{{settle.cause}}</p>
					<p v-else>{{settle.status === 0?'审核时间为1个工作日，请耐心等待':settle.status===1?'结算成功，请耐心等待1~2分钟':settle.status===4?'到账时间：':''}}</p>
					<span  v-if="settle.status===4">{{present.result_date}}</span>
				</div>
				<div>
					<div class="order-error-bg">
						<div class="order-error-bg-item">
							<div class="etgd">订单号：</div>
							<div class="order-error-bg-item-text">{{present.order_no}}</div>
						</div>
						<div class="order-error-bg-item">
							<div class="etgd">卡种：</div>
							<div class="order-error-bg-item-text">{{present.goods_name}}</div>
						</div>
						<div class="order-error-bg-item">
							<div class="etgd">卡号：</div>
							<div class="order-error-bg-item-text">{{present.card?present.card:'无卡号'}}</div>
						</div>
						<div class="order-error-bg-item">
							<div class="etgd">卡密：</div>
							<div class="order-error-bg-item-text">{{present.card_pass?present.card_pass:'无卡号'}}</div>
						</div>
					</div>
				</div>
				<div class="prl70 mt40 ">
					<div v-if="settle.status === 2">
						<el-button color="#0EB6FF" class="main-button mt20" @click="openaweWl(present)">重新提交</el-button>
					</div>
					
					<el-button type="info" color="#909399" class="main-button mt20" @click="dialogVisible = false;">关闭</el-button>
				</div>
			</div>
			<template #footer>
				<span class="dialog-footer">
				</span>
			</template>
		</el-dialog>
		<userBottom></userBottom>
	</div>
</template>

<script>
	import uerHeader from '../../components/header/card.vue'
	import userLeft from '../../components/left/index.vue'
	import userBottom from '../../components/footer/user.vue'
	import { ElMessage, ElMessageBox } from 'element-plus'
	export default{
		data(){
			return{
				tableData:[],
				cardType:[],
				cardId:-1,
				cardNmae:"",
				start_time:null,
				end_time:null,
				kaleiActive1:-1,
				dataType:[
					{
						name:'今天',
						id:1
					},
					{
						name:'近七天',
						id:2
					},
					{
						name:'一个月',
						id:3
					},
					{
						name:'三个月',
						id:4
					}
				],
				delivery: {
					'12': {name:'处理中',class:"#0EB6FF",value:12},
					"13": {name:'待结算',class:"#FCD150",value:13},
					"14": {name:'成功',class:"#3ED77B",value:14},
					"15": {name:'失败',class:"#FF7544",value:15},
				},
				deliveryType:-1,
				deliveryList:{
					'10': {name:'处理中',class:"#0EB6FF",value:12},
					"20": {name:'待结算',class:"#FCD150",value:13},
					"30": {name:'成功',class:"#3ED77B",value:14},
					"40": {name:'失败',class:"#FF7544",value:15},
					"50": {name:'待处理',class:"#E49700"},
					"60": {name:'异常订单',class:"#FF7544"},
				},
				currentPage:1,
				total:100,
				loading:false,
				serch:"",
				
				present:{},
				settle:{},
				dialogVisible:false,
				// userInfo:JSON.parse(localStorage.getItem("userInfo"))
			}
		},
		components:{
			uerHeader,
			userLeft,
			userBottom
		},
		created(){
			this.userInfo = JSON.parse(localStorage.getItem("userInfo"))
			this.get_coupon()
			this.getData()
		},
		methods:{
			sendNotf(order_id){
				this.$api.sendNotf({
					order_id
				})
				.then(result => {
					if(result.code === 1){
						ElMessage({
							message: result.msg,
							type: 'success',
						})
					}else{
						ElMessage({
							message: result.msg,
							type: 'error',
						})
					}
				})
			},
			reissue(item){
				ElMessageBox.confirm(
					'是否补发通知？',
					'提示',
					{
						confirmButtonText: '是',
						cancelButtonText: '否',
						type: 'warning',
					}
				)
					.then(() => {
						this.sendNotf(item.order_goods_id)
					})
					.catch(() => {

					})
			},
			openDetail(order_no){
				this.$router.push({path:'/user/detail',query:{order_no}})
			},
			openaweWl(item){
				// ,query:{item.order_no}
				this.$router.push({path:'/user/settle',query:{order_no:item.order_no}})
			},
			comTime(create_time,last_time,item){
				console.log('bbbb')
				let order_no = create_time.split('|')[0]
				let time = Number(create_time.split('|')[1]);
				if(order_no != item.order_no){
					return true
				}
				console.log('bbbb')
				if(Math.floor(Math.abs(last_time - time)) >= 3600){
					localStorage.removeItem('surveyStatus')
					return true
				}else{
					return false
				}
			},
			openSettle(item){
				this.$api.approvalStatus({
					order_id:item.order_no
				})
				.then(result => {
					this.present = item
					this.settle = result.data
					if(result.code === 1){
						this.dialogVisible = true
					}else{
						let create_time = localStorage.getItem('surveyStatus')
						let last_time = Date.parse(new Date()) / 1000
						if(!create_time){
							
								this.openaweWl(item)
							
						}else{
							if(this.comTime(create_time,last_time,item)){
								this.openaweWl(item)
							}else{
								this.settle['status'] = 4
								this.dialogVisible = true
							}
						}
					}
				})
				
			},
			exportExeclAll(){
				window.location = "https://lipin.tuantuanshou.com/api/order/export?token=" + localStorage.getItem("token")+'&type=11'
			},
			exportExecl(){
				window.location = "https://lipin.tuantuanshou.com/api/order/export?token=" + localStorage.getItem("token")+'&type=14'
			},
			getData(){
				this.loading = true
				this.$api.orderList({
					page:this.currentPage,
					type: this.deliveryType,
					start_time: this.end_time,
					end_time: this.start_time,
					category_name: this.cardNmae,
					name:this.serch
				})
				.then(result => {
					this.loading = false
					this.currentPage = result.data.current_page
					this.total = result.data.total
					this.tableData = result.data.data
				})
			},
			orderType(item){
				this.deliveryType = item.value
				this.getData()
			},
			fun_date(aa) {
				var date1 = new Date(),
				time1 = date1.getFullYear() + "-" + (date1.getMonth() + 1) + "-" + date1.getDate(); //time1表示当前时间
				var date2 = new Date(date1);
				date2.setDate(date1.getDate() + aa + 1);
				let mm = date2.getMonth() + 1
				let dd = date2.getDate()
				if (mm < 10) {
					mm = String(mm)
					mm = '0' + mm
				}
				if (dd < 10) {
					dd = String(dd)
					dd = '0' + dd
				}
				var time2 = date2.getFullYear() + "-" + mm + "-" + dd;
				return time2
			},
			kaleibtn1(index) {
				this.kaleiActive1 = index
				let times = this.dataType[index]
				let yy = new Date().getFullYear();
				let mm = new Date().getMonth() + 1;
				let dd = new Date().getDate();
				if (mm < 10) {
					mm = String(mm)
					mm = '0' + mm
				}
				if (dd < 10) {
					dd = String(dd)
					dd = '0' + dd
				}
				if (times.id === 1) {
					this.start_time = yy + '-' + mm + '-' + dd
					this.end_time = yy + '-' + mm + '-' + dd
				} else if (times.id === 2) {
					this.start_time = yy + '-' + mm + '-' + dd
					this.end_time = this.fun_date(-7)
				} else if (times.id === 3) {
					this.start_time = yy + '-' + mm + '-' + dd
					this.end_time = this.fun_date(-30)
				} else if (times.id === 4) {
					this.start_time = yy + '-' + mm + '-' + dd
					this.end_time = this.fun_date(-90)
				}
				this.getData()
			},
			cardify(item,index){
				this.cardId = item.cate_id
				this.cardNmae = item.name
				this.getData()
			},
			get_coupon() {
				this.$api.list({
				}).then(res => {
					if (res.code === 1) {
						this.cardType = res.data
					}
				})
			},
			handleCurrentChange(e){
				this.currentPage = e
				this.getData()
			}
		}
	}
</script>

<style scoped>
	.order-input{
		width: 340px;
		border-radius: 8px;
		height: 42px;
		color: #B3B3B3;
	}
	/deep/ .order-input .el-input-group__prepend{
		background-color: #fff;
		box-shadow: none;
	}
	/deep/ .order-input .el-input-group__append{
		background-color: #fff;
		box-shadow: none;
		border-left:1px solid #EEEEEE;
		color: #B3B3B3;
	}
	/deep/ .order-input .el-input__wrapper{
		box-shadow: none;
	}
	.order-serch-button button{
		height: 42px;
		border-radius: 8px;
		color: #333333;
		border: none;
	}
	/deep/ .real-inputd .el-input__wrapper{
		 background: #F7F9FC;
		 border-radius: 8px;
		 color: #B3B3B3;
		 height: 60px;
		 border-color: #F7F9FC;
	}
	/deep/.pop-radius{
		border-radius: 24px;
	}
	.order-screen{
		padding: 15px 26px 24px 26px;
	}
	.wdszxty{
		font-size: 16px;
	}
	.wdszxty + .wdszxty{
		margin-left: 60px;
	}
	.card-type-active{
		color: #0EB6FF!important;
		font-weight: 600;
	}
	.usergoods-item{
		color: #737373;
		font-weight: 400;
		display: flex;
		align-items: center;
		font-size: 16px;
		border-right: 1px solid #EEEEEE;
		padding-right: 24px;
	}
	.usergoods-item img{
		width: 30px;
		height: 30px;
		margin-right: 8px;
	}
	.usergoods-item + .usergoods-item{
		margin-left: 24px;
	}
	.usergoods-item:last-child{
		border: none;
		padding-right: 0;
	}
	.order-goods{
		padding-bottom: 24px;
		border-bottom: 1px dashed #E5E5E5;
	}
	.qwddscx img{
		width: 24px;
		height: 24px;
		padding: 4px;
		border: 1px solid #EEEEEE;
		border-radius: 8px;
	}
	.menu-order{
		
		width: 72px;
		height: 34px;
	}
	.menu-order-c-f{
		color:#fff!important;
	}
	.qwdxzvdfrty a{
		color: #333333;
	}
	.qwdxzvdfrty .active{
		
	}
	.qwdxzvdfrty a:hover{
		color: #79bbff;
	}
	.wduqsc{
		
	}
	.ft25{
		font-size:25px;
	}
	.waring-wds{
		color:#E6A23C;
	}
	.success-wds{
		color: #67C23A;
	}
	.error-wds{
		color:#F56C6C;
	}
	.order-error-bg{
		border-radius: 16px;
		padding: 30px 40px;
		margin-top: 30px;
	}
	.order-error-bg-item{
		display: flex;
		align-items: center;
		font-size: 19px;
	}
	.order-error-bg-item + .order-error-bg-item{
		margin-top: 16px;
	}
	.order-error-bg-item-text{
		color: rgb(153, 153, 153);
	}
	.mt8{
		margin-top: 8px;
	}
	
	.ellipsis {
	  white-space: nowrap;      /* 确保文本在一行内显示 */
	  overflow: hidden;         /* 超出容器部分的文本隐藏 */
	  text-overflow: ellipsis;  /* 使用省略号表示被截断的文本 */
	}
	.ft16{
		font-size: 14px;
	}
</style>