<template>
	<div class="user-bg-swg html-two">
		<uerHeader  type="" class="bg_fff"></uerHeader>
		<div >
			<el-row :gutter="24" class=" wdsffdzcdtg mt40">
				<el-col :span="4" :offset="2">
					<userLeft></userLeft>
				</el-col>
				<el-col :span="16" >
					<div class="m-content fix">
						<div class="user-main">
							<div class="bg_fff rounded rfwwd pd24" v-loading="loading">
								<el-steps

									:active="!subAppli ? 0:1"
									finish-status="success"
								>
									<el-step title="第一步" />
									<el-step title="第二步" />
								</el-steps>
								<el-alert type="warning" show-icon :closable="false" v-if="subAppli">
									<p>1、材料内容真实有效，不得做任何修改；</p>
									<p>2、卡密来源的证明材料，需上传视频；</p>
									<p>3、上传视频只支持MPEG、AVI、WMV、MOV、3GP、MP4格式，最大不超过30M；</p>
									<p>4、如卡券为本人购买，需提供本人购买证明（材料中需体现购买平台实名认证界面）、卡券购买平台卡号+卡密界面</p>
									<p>5、证明来源合法性的材料包括但不限于：供卡合同、收卡合同、交易场景、qq或微信 沟通记录、客户签字或盖章的对于卡券来源的情况说明等,必须真实有效，不得虚构</p>
								</el-alert>
								<div style="width:800px;margin:0 auto">
									<div v-if="!subAppli">
										<h1 v-if="buttBoo">{{problemNumber+1}}·{{suveList[problemNumber].title}}</h1>
										<h1 v-else>{{problemNumber+2}}·{{fProblem.title}}</h1>
										<div v-if="buttBoo">
											<el-radio-group v-model="desc_id">
												<el-radio :label="item.id" size="large" v-for="(item,index) in proDcit.desc" :key="index">
													{{item.content}}
												</el-radio>
											</el-radio-group>
										</div>
										<div v-else>
											<el-checkbox-group v-model="checkList">
												<el-checkbox :label="item.content" v-for="(item,index) in fProblem.desc" :key='index' />
											</el-checkbox-group>
										</div>
										<el-button color="#0EB6FF"  class="settie-buttfona mt40" :disabled="buttBoo?!desc_id:checkList.length != fProblem.desc.length" @click='openNext'>下一步</el-button>
									</div>
									<div v-else>
										<h1 >申请快速结算表单</h1>
										<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm" label-position="left">
											<el-form-item label="订单号" prop="order_no">
												<el-input v-model="ruleForm.order_no" disabled></el-input>
											</el-form-item>
											<el-form-item label="卡种" prop="goods_name">
												<el-input v-model="ruleForm.goods_name" disabled></el-input>
											</el-form-item>
											<el-form-item label="卡号" prop="card">
												<el-input v-model="ruleForm.card" disabled></el-input>
											</el-form-item>
											<el-form-item label="卡密" prop="card_pass">
												<el-input v-model="ruleForm.card_pass" disabled></el-input>
											</el-form-item>
											<el-form-item label="结算时间" prop="result_date">
												<el-input v-model="ruleForm.result_date" disabled></el-input>
											</el-form-item>
											<el-form-item label="备注" prop="remark">
												<el-input type="textarea" v-model="ruleForm.remark"></el-input>
											</el-form-item>
											<el-form-item label="卡密来源证明视频" prop="imgList">
												<video v-if="imageUrl.length !=0 " :src="ruleForm.imgList[0].file_path" class="avatar-video" :controls="true" :autoplay="false" ></video>
												<el-upload
													v-else
													class="avatar-uploader"
													list-type="picture-card"
													action='https://www.tuantuanshou.com/api/upload/image'
													:on-success="handleAvatarSuccess"
													:before-upload="beforeAvatarUpload"
													:on-error="handleVideoError"
													:on-preview="handlePictureCardPreview"
													name="iFile"
													:headers="{
														'Access-Token':token
													}"
													
												>
													
													<el-icon  class="avatar-uploader-icon"><Plus /></el-icon>
												</el-upload>
					
											</el-form-item>
											<el-form-item>
												<el-button color="#0EB6FF"  class="settie-buttfona mt40" @click="submitForm('ruleForm')">提交</el-button>
											</el-form-item>
										</el-form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<userBottom></userBottom>
	</div>
</template>

<script>
	import uerHeader from '../../components/header/card.vue'
	import userLeft from '../../components/left/index.vue'
	import userBottom from '../../components/footer/user.vue'
	import config from '../../config.js'
	import { ElMessage, ElMessageBox } from 'element-plus'
	export default{
		data(){
			return{
				issueList:[],
				suveList:[
					{}
				],
				proDcit:[],
				problemNumber:0,
				fProblem:{
					title:'请仔细阅读以下内容并勾选！',
					desc:[
						{
							content:'我本人承诺该帐号一直是本人使用，没有出租、出借、出卖给他人使用。',
							bool:false
						},
						{
							content:'我本人承诺在团团收网站（tuantuanshou.con）提交的卡劵来源合法合规。',
							bool:false
						},
						{
							content:'我本人承诺在本次尽职调查所填写的内容和上传的材料都是本人操作，并且真实有效。',
							bool:false
						}
					]
				},
				checkList:[],
				desc_id:"",
				buttBoo:true,
				subAppli:false,
				tableData:{},
        ruleForm: {
					goods_name:"",
					order_no:"",
          remark: '',
					card:"",
					card_pass:"",
					result_date:"",
					imgList:[]
        },
        rules: {
          remark: [
            { required: true, message: '请填写备注', trigger: 'blur' }
          ],
					imgList: [
					  { required: true, message: '请填加卡券来源视频', trigger: 'blur' }
					]
        },
				imageUrl:[],
				config,
				token:localStorage.getItem('token'),
				loading:false
			}
		},
		components:{
			uerHeader,
			userLeft,
			userBottom
		},
		created() {
			this.getData()
			this.alwaysFunc()
		},
		methods:{
			handlePictureCardPreview(file){
				// this.dialogImageUrl = file.url;
			},
			handleAvatarSuccess(res, file) {
				this.imageUrl.push(res.data)
				this.ruleForm.imgList = this.imageUrl
			},
			handleVideoError(error,uploadFile,uploadFiles){
				ElMessage({
					message: error,
					type: 'error',
				})
			},
			beforeAvatarUpload(file) {
        const fileSize = file.size / 1024 / 1024 < 300
        if (['video/mp4', 'video/ogg', 'video/flv', 'video/avi', 'video/wmv', 'video/rmvb', 'video/mov'].indexOf(file.type) === -1) {
					ElMessage({
						message: '请上传正确的视频格式',
						type: 'warning',
					})
          return false
        }
        if (!fileSize) {
					ElMessage({
						message: '视频大小不能超过300MB',
						type: 'warning',
					})
          return false
        }
			},
			getData(){
				this.$api.orderList({
					type:1,
					page:1,
					name:this.$route.query.order_no
				})
				.then(result => {
					this.tableData = result.data.data[0]
					for(let k in this.ruleForm){
						this.ruleForm[k] = this.tableData[k]
					}
					this.ruleForm['remark'] = ''
					// this.ruleForm(pick(this.tableData, ['card','card_pass']))
				})
			},
			settlementsubmit(){
				this.loading = true
				let user_id = JSON.parse(localStorage.getItem("userInfo")).user_id;
				this.$api.settlementsubmit({
					order_id:this.tableData.order_no,
					user_remark:this.ruleForm.remark,
					image:this.ruleForm.imgList[0].file_id,
					user_id,
				})
				.then(result => {
					if(result.code === 1){
						this.loading = false
						ElMessage({
							message: '提交成功',
							type: 'success',
						})
						setTimeout(() => {
							this.$router.push({path:'/user/order'})
						},1000)
					}
				})
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.settlementsubmit()
					} else {
						return false;
					}
				});
			},
			
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			openNext(){
				if(this.buttBoo){
					this.$api.surveysubmitl({
						desc_id:this.desc_id
					})
					.then(result => {
						if(result.code === 1){
							if(this.problemNumber + 1 < this.suveList.length){
								
								this.problemNumber += 1;
								this.problemDetail();
							}else{
								this.buttBoo = false;
								// if(this.wycbd){
								// 	this.buttBoo = false;
								// 	this.wycbd = false
								// 	return
								// }
								// uni.navigateTo({
								// 	url:`/pageace_ali/pages/cart/survey_next?detail=${this.orderDetail}`
								// })
							}
						}else{
							localStorage.setItem('surveyStatus',this.$route.query.order_no + '|' + Date.parse(new Date()) / 1000)
						}
					})
				}else{
					this.subAppli = true
				}
			},
			async alwaysFunc(){
				this.suveList = await this.surveyList()
				this.problemDetail()
			},
			async problemDetail(){
				let list = await this.surveydesc(this.suveList[this.problemNumber].id)
				// list.desc = this.changeData(list.desc)
				this.proDcit = list
				console.log(this.proDcit)
			},
			surveyList(){
				return new Promise((resolve,reject) => {
					this.$api.surveyList({
					})
					.then(result => {
						resolve(result.data); 
					})
				})
			},
			surveydesc(id){
				return new Promise((resolve,reject) => {
					this.$api.surveydesc({
						id,
					})
					.then(result => {
						resolve(result.data)
					})
				})
			},
		}
	}
</script>

<style scoped>
	/deep/.el-radio.el-radio--large{
		width: 100%;
	}
	/deep/.el-radio.el-radio--large .el-radio__input .el-radio__inner{
		width: 20px;
		height: 20px;
	}
	/deep/.el-radio.el-radio--large .el-radio__input .el-radio__inner::after{
		width: 8px;
		height: 8px;
	}
	/deep/.el-radio.el-radio--large .el-radio__label{
		font-size: 16px;
	}
	
	/deep/.el-checkbox__inner{
		width: 20px;
		height: 20px;
	}
	/deep/.el-checkbox__inner::after{
		width: 5px;
		height: 12px;
		left: 6px;
	}
	/deep/.el-checkbox__label{
		font-size: 20px;
	}
	/deep/.el-checkbox+.el-checkbox{
		margin-top: 20px;
	}
	
	/* 表单 */
	/deep/.el-form-item__label{
		font-size: 20px;
	}
	/deep/.el-input.is-disabled{
		height: 40px
	}
	/deep/.el-textarea__inner{
		min-height: 80px!important;
	}
	/deep/.el-form-item__content input{
		font-size: 20px;
	}
	/deep/.el-form-item__content textarea{
		font-size: 20px;
	}
	.settie-buttfona{
	 width: 116px;
	 height: 40px;
	 border-radius: 8px;
	 color: #ffffff!important;
	 font-size: 18px;
	 margin-left: 30px;
	}
	/deep/.avatar-uploader .el-upload {
	  border: 1px dashed var(--el-border-color);
	  border-radius: 6px;
	  cursor: pointer;
	  position: relative;
	  overflow: hidden;
	  transition: var(--el-transition-duration-fast);
	}
	
	.avatar-uploader .el-upload:hover {
	  border-color: var(--el-color-primary);
	}
	.el-icon.avatar-uploader-icon {
	  font-size: 28px;
	  color: #8c939d;
	  width: 178px;
	  height: 178px;
	  text-align: center;
	}
	
	.video-avatar {
	  width: 400px;
	  height: 250px;
	}
	.avatar-video{
		width:400px;
		height: 250px;
	}
</style>