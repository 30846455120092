<template>
	<div class="user-bg-swg html" >
		<div class="bg_fff">
			<cardHeader :type="'notice'"></cardHeader>
		</div>
		<eshopbg></eshopbg>
		<el-row :gutter="20"
			v-loading="loading"
			element-loading-text="加载中..."
			class="wdsffdzcdtg mt24">
			<el-col :span="4" :offset="3">
				<left :tabStr="'/notice/question'"></left>
			</el-col>
			<el-col :span="15" >
				<div class="notice-main">
					<div class="notice-title">常见问题</div>
					<div class="bg_fff mt24 notice-content">
						<el-collapse accordion @change="lickProblem">
							<el-collapse-item :name="index" v-for="(item,index) in problemList" :key="index">
								<template #title class="locsg">
									<div :class="{'text-main':problemIndex === index}">{{item.article_title}}</div>
								</template>
								<div class="wqfbbjj" v-html="HTMLDecode(item.article_content)"></div>
							</el-collapse-item>
						</el-collapse>
					</div>
				</div>
			</el-col>
		</el-row>
		<userBottom></userBottom>
	</div>
</template>

<script>
	import cardHeader from '../../components/header/card.vue'
	import userBottom from '../../components/footer/user.vue'
	import eshopbg from '../../components/eshopbg/index.vue'
	import left from '../../components/left/notice.vue'
	import * as Api from '@/api/notice'
	export default{
		components:{
			cardHeader,
			userBottom,
			left,
			eshopbg
		},
		data(){
			return{
				loading:false,
				problemList:[],
				problemIndex:""
			}
		},
		created() {
			this.getList()
		},
		methods:{
			lickProblem(e){
				this.problemIndex = e
			},
			HTMLDecode(text) {
				var temp = document.createElement("div");
				temp.innerHTML = text;
				var output = temp.innerText || temp.textContent;
				temp = null;
				return output;
			},
			getList(){
				Api.getProblem()
				.then(result => {
					this.problemList = result.data
				})
			}
		}
	}
</script>

<style scoped lang="less">
	.user-bg-swg{
		background-color: #f7f7f7;
	}
	/deep/ .el-collapse{
		border:none;
	}
	/deep/ .el-collapse-item__header{
		border: none;
		font-weight: 400;
		font-size: 18px;
		color: #737373;
		padding: 16px;
	}
	/deep/ .el-collapse-item:nth-child(2n) > div > .el-collapse-item__header{
		background: #FAFAFA;
	}
	.wqfbbjj{
		background: #F2FAFF;
		border-radius: 8px 8px 8px 8px;
		border: 1px solid #0EB6FF;
		padding: 24px;
		color: #737373;
		font-size: 16px;
		margin-top: 10px;
	}
	.text-main{
		color: #0EB6FF!important;
	}
</style>