<template>
	<div class="user-bg-swg html" >
		<div class="bg_fff">
			<cardHeader :type="'message'"></cardHeader>
		</div>
		<eshopbg></eshopbg>
		<el-row :gutter="20"
			v-loading="loading"
			element-loading-text="加载中..."
			class="wdsffdzcdtg mt24">
			<el-col :span="15" :offset="3">
				<div>
					<div class="flex alcenter card-top bg_fff">

						<div v-for="(item,index) in cardList" :key="index" class="card-top-item" :class="{'card-active':cate_id == item.category_id}" @click="tabCate(item)">
							{{item.name}}
						</div>
					</div>
					<div class="bg_fff article-main">
						<div class="article-detail-title">{{articleDetail.article_title}}</div>
						<div class="flex alcenter c_737373 mt16 ft16 mb40">
							<div class="mr32">
								<span class="mr10">来自</span>
								<span class="c_0EB6FF">团团收</span>
							</div>
							<div class="flex alcenter mr32">
								<img src="@/assets/article/time.png" class="time-img mr8" />
								<div class="ft16 c_737373">{{articleDetail.publish_time}}</div>
							</div>
							<div class="flex alcenter ">
								<div class="iconfont icon-Icon-guankanliang mr8"></div>
								<div>{{articleDetail.actual_views?articleDetail.actual_views:articleDetail.virtual_views}}</div>
							</div>
						</div>
						<div class="news-detail" >
						  <div class="news-detail-digest">
								<div class="tindent">
									{{articleDetail.abstract}}
								</div>
						  </div>
						</div>
						<div class="article-hint"></div>
						<div class="mt24 c_333333 article-content" v-if="articleDetail.article_content" v-html="checkescape2Html(articleDetail.article_content)"></div>
						<div class="flex flex-wrap mt8" v-if="articleDetail.label && articleDetail.label.length > 0">
							<div class="articel-tag" v-for="(item,index) in articleDetail.label" @click="openLabel(item)" :key="index" >
								#{{item.name}}
							</div>
						</div>
						<div class="alcenter flex-between mt16">
							<div>
								<span>上一篇：</span>
								<span class="article-nexs" @click="openDetail(articleDetail.next)" v-if="articleDetail.next && articleDetail.next.article_id">{{articleDetail.next.article_title}}</span>
								<span v-else>没有上一篇</span>
							</div>
							<div>
								<span>下一篇：</span>
								<span class="article-nexs"  @click="openDetail(articleDetail.upper)" v-if="articleDetail.upper && articleDetail.upper.article_id">{{articleDetail.upper.article_title}}</span>
								<span v-else>没有下一篇</span>
							</div>
						</div>
						<div class="article-hint mt30"></div>
						<div class="c_737373 ft16 mt8">
							免责声明：本文是由我司原创独立撰写首发本网站中，供使用百度等各大搜索引擎的用户可以第一时间查阅并解决自己所遇到的优化难题。而其中部分转载的文章版权归原作者所有，如有侵权请联系客服删除。
						</div>
					</div>
					<div class="like-main bg_fff" v-if="articleList.length > 0">
						<div class="like-title">猜你喜欢</div>
						<div class="mt24 flex alcenter flex-wrap">
							<div v-for="(item,index) in articleList" :key="index" class="list-item" @click="openDetail(item)">
								<div class="list-img">
									<img :src="item.image.file_path" class="list-img" />
								</div>
								<div class="hot-fasfqwqq two-ouddle">{{item.article_title}}</div>
							</div>
						</div>
					</div>
				</div>
			</el-col>
			<el-col :span="4" >
				<articleRight type="detail" @handleDetail="openDetail"></articleRight>
			</el-col>
		</el-row>
		<div style="margin-bottom:20px;"></div>
		<userBottom></userBottom>
	</div>
</template>

<script>
	import cardHeader from '../../components/header/card.vue'
	import userBottom from '../../components/footer/user.vue'
	import eshopbg from '../../components/eshopbg/index.vue'
	import articleRight from './components/articleRight'
	export default{
		components:{
			cardHeader,
			userBottom,
			eshopbg,
			articleRight
		},
		data(){
			return{
				loading:false,
				cardList:[],
				cate_id:"",
				articleDetail:{},
				articleList:[],
				hotArticle:[],
				article_id:"",
			}
		},
		created() {
			this.article_id = this.$route.query.article_id
			if(!this.article_id){
				this.$router.push({
					path:"/",
				})
				return
			}
			this.getCategoryList()
			this.articleHotpc()
			this.news_api()
			this.agreementpc()
			
		},
		methods:{
			openLabel(item){
				this.$router.push({
					path:"/article/index",
					query:{labelId:item.id}
				})
			},
			tabCate(item){
				this.cate_id = item.category_id
				this.$router.push({
					path:"/article/index",
					query:{cate_id:item.category_id}
				})
			},
			getCategoryList(){
				return new Promise((resolve,reject) => {
					this.$api.categorypc()
					.then(res => {
						let list = res.data
						this.cardList = list
						// this.cate_id = this.cardList[0].category_id
						resolve()
					})
				})
			},
			checkescape2Html(str){
				const regex = new RegExp('img ', 'gi')
				const regexSvg = new RegExp('svg ', 'gi')
				let objStr = str.replace(regex,"img class='fweffgbbnb'")
				objStr = objStr.replace(regexSvg,"svg width='13' height='13' style='top: -3px;position: relative;'")
				let arrEntities = {
					'lt': '<',
					'gt': '>',
					'nbsp': ' ',
					'amp': '&',
					'quot': '"'
				};
				return objStr.replace(/&(lt|gt|nbsp|amp|quot);/ig, function(all, t) {
					return arrEntities[t];
				});
			},
			openDetail(item){
				this.article_id = item.article_id
				this.agreementpc()
				this.news_api()
			},
      seoConformity(property,title){
      	const newMeta = document.createElement('meta');
        if(document.querySelector(`meta[property="${property}"]`)){
          document.querySelector(`meta[property="${property}"]`).setAttribute('content',title)
          return false
        }else{
          newMeta.setAttribute('property', property);
          newMeta.setAttribute('content', title);
          return newMeta
        }

      },
			agreementpc(){
				this.loading = true
				this.$api.Articledetail({
					article_id:this.article_id
				})
				.then(result => {
					this.articleDetail = result.data
					this.cate_id = this.articleDetail.category_id
					document.title = `${this.articleDetail.article_title}-资讯详情-团团收`
					let seoMain = [
						{
							property:'og:type',
							value:'article',
							fixation:true
						},
						{
							property:'og:release_date',
							value:'view_time',
							fixation:false
						},
						{
							property:'og:title',
							value:'article_title',
							fixation:false
						},
						{
							property:'og:description',
							value:'abstract',
							fixation:false
						},
						{
							property:'og:site_name',
							value:'团团收',
							fixation:true
						},
						{
							property:'og:url',
							value:window.location.href,
							fixation:true
						},
					]
					let title = ""
					let bswv = ""
					for(let i=0;i<seoMain.length;i++){
						title = ""
						if(seoMain[i].fixation){
							title = seoMain[i].value
						}else{
							title = this.articleDetail[seoMain[i].value]
						}
						bswv = this.seoConformity(seoMain[i].property,title)
						if(bswv){
						  document.head.appendChild(bswv);
						}
					}
					this.$el.scrollIntoView();
					this.loading =false
				})
			},
			news_api(){
				this.$api.relevantlist({
					article_id:this.article_id
				})
				.then(result => {
					let num = 4
					let list = result.data.data
					let articleList = []
					for(let i=0;i<list.length;i++){
						if(i === num){
							break
						}
						articleList.push(list[i])
					}
					this.articleList = articleList
				})
			},
			articleHotpc(){
				this.hotLoading = true
				this.$api.articleHotpc()
				.then(result => {
					this.hotArticle = result.data.data
					this.hotLoading = false
				})
			}
		}
	}
</script>

<style scoped lang="less">
	.user-bg-swg{
		background-color: #f7f7f7;
	}
	.article-main{
		padding: 21px 37px;
		margin-top: 19px;
	}



	.two-ouddle{
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}


	.article-detail-title{
		font-weight: 400;
		font-size: 24px;
		color: #333333;
	}
	.time-img{
		width: 18px;
		height: 18px;
	}
	.article-hint{
		width: 100%;
		background-color: #E5E5E5;
		height: 1px;
	}
	.article-content{
		// margin-bottom: 114px;
	}
	.like-main{
		margin-top: 29px;
		padding: 28px 20px;
	}
	.like-main .like-title{
		font-weight: 500;
		font-size: 24px;
		color: #333333;

	}
	.list-item{
		width: 22%;
		margin-right: 4%;
		cursor: pointer;
		height: 240px;
	}
	.list-item:nth-child(4n){
		margin-right: 0;
	}
	.list-img{
		width: 100%;
		transition: transform 0.3s ease; /* 平滑变化 */
		height: 168px;
	}
	.list-item:hover > .list-img{
		transform: scale(1.1); /* 鼠标悬停时，图片放大1.1倍 */
	}
	.list-item:hover > .hot-fasfqw{
		color: #0EB6FF;
	}
	/deep/.fweffgbbnb{
		max-width:800px;
	}
	.news-title h2 {
	  font-weight: 400;
	  line-height: 44px;
	  color: #333;
	  font-size: 32px;
	  margin-bottom: 7px;
	  border: none;
	}
	.news-detail-digest{
	  margin-top: 15px;
	  border-left: 4px solid #0EB6FF;
	  background: #f9f9f9;
	  padding: 15px;
	  font-size: 14px;
	  position: relative;
	  margin-bottom: 20px;
	}
	.news-detail-digest .contitle{
	  position: absolute;
	  left: 0;
	  top: 15px;
	  border-left: 2px solid #198bfc;
	  padding: 0 5px;
	  color: #198bfc;
	  font-size: 18px;
	}
	.news-detail-digest .tindent{
	  line-height: 25px;
		font-size: 16px;
	}
	.articel-tag{
		background: #fff;
		text-align: center;
		height: 30px;
		line-height: 32px;
		margin-right: 5px;
		display: inline-block;
		padding: 0 12px;
		box-shadow: 1px 2px 8px rgba(206, 213, 217, 0.4);
		font-size: 12px;
		margin-bottom: 10px;
		cursor: pointer;
		transition: 0.3s;
	}
	.articel-tag:hover{
		background:#f8f9fa;
	}
	.hot-fasfqwqq{
		margin-top: 16px;
		font-size: 18px;
		color: #333;
		transition: .3s;
	}
	.hot-fasfqwqq:hover{
		color: #0EB6FF;
	}
	.article-nexs{
		transition: .3s;
		cursor: pointer;
	}
	.article-nexs:hover{
		color: #0EB6FF;
	}
	.detail-svg{
		width: 13px!important;
		height: 13px!important;
	}
</style>