<template>
	<div>
		<div v-html="HTMLDecode(article_content)">

		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				article_content:""
			}
		},
		created() {
			this.agreementpc()
		},
		methods:{
			HTMLDecode(text) {
				var temp = document.createElement("div");
				temp.innerHTML = text;
				var output = temp.innerText || temp.textContent;
				temp = null;
				return output;
			},
			agreementpc(){
				this.$api.agreementpc({
					type:10
				})
				.then(result => {
					this.article_content = result.data.article_content
				})
			}
		}
	}
</script>

<style>
</style>