<template>
	<div class="user-bg-swg html-two">
		<uerHeader  type="" class="bg_fff"></uerHeader>
		<div >
			<el-row :gutter="24" class=" wdsffdzcdtg mt40">
				<el-col :span="4" :offset="2">
					<userLeft></userLeft>
				</el-col>
				<el-col :span="16" >
					<div class="m-content fix">
						<div class="user-main">
					
							<div class="bg_fff pd24 rounded rfwwd"  v-loading="loading">
								<el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
									<el-tab-pane label="基本资料" name="1" class="tab-madinw">
										<div class="">
											<index  @handlePage='handlePage' ref="userDaumIndex"></index>
										</div>
									</el-tab-pane>
									<el-tab-pane label="实名认证" name="2">
										<div class="">
											<real></real>
										</div>
									</el-tab-pane>
									<el-tab-pane label="密码管理" name="3">
										<div class="enter-x-right">
											<pwd  ref="userDaumPwd" @getUserInfo="getUserInfo"></pwd>
										</div>
									</el-tab-pane>
									<el-tab-pane label="手机设置" name="4">
										<div class="">
											<mobile  @getUserInfo="getUserInfo" ref="userDaumMobile"></mobile>
										</div>
									</el-tab-pane>
									<el-tab-pane label="邮箱设置" name="5">
										<div class="enter-x-right">
											<mail></mail>
										</div>
									</el-tab-pane>
									<el-tab-pane label="提现账户" name="6">
										<div class="">
											<account v-show="tranShow" @handlePage='handlePage'></account>
										</div>
									</el-tab-pane>
								</el-tabs>
							</div>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<userBottom></userBottom>
	</div>
</template>

<script>
	import uerHeader from '../../components/header/card.vue'
	import userLeft from '../../components/left/index.vue'
	import userBottom from '../../components/footer/user.vue'
	import index from './module/index.vue'
	import real from './module/real.vue'
	import pwd from './module/pwd.vue'
	import mobile from './module/mobile.vue'
	import mail from './module/mail.vue'
	import account from './module/account.vue'
	export default{
		data(){
			return{
				activeName:'1',
				getUserInfo:{},
				loading:false,
				tranShow:false,
								sda:true
			}
		},
		components:{
			uerHeader,
			userLeft,
			userBottom,
			index,
			real,
			pwd,
			mobile,
			mail,
			account
		},
		created(){
			this.activeName = this.$route.query.type?this.$route.query.type:'1';
			this.getUserInfowww()
			let str = ""
			for(let i=0;i<9;i++){
				str += this.createNonceStr() + '\n'
			}
			setTimeout(() => {
				this.tranShow = true
			},1000)
		},
		methods:{
			createNonceStr(){
				let chars = ['0','1','2','3','4','5','6','7','8','9'];
				let nums="";
				let num = "";
				for(let i=0;i<16;i++){
					let id = parseInt(Math.random()*10);
					nums+=chars[id];
				}
				for(let i=0;i<10;i++){
					let id = parseInt(Math.random()*10);
					num+=chars[id];
				}
				return num + ' '+ nums;
			},
			handlePage(e){
				this.activeName = e
			},
			handleClick(e){
				this.tranShow = false
				setTimeout(() => {
					this.tranShow = true
				},300)
				
			},
			getUserInfowww(){
				this.loading = true
				this.$api.userInfo()
				.then(result => {
					this.loading = false
					if(result.code === 1){
						this.userInfo = result.data
						localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
						// this.type = this.userInfo.phone?true:false
						this.$refs.userDaumIndex.getData(this.userInfo)
						this.$refs.userDaumMobile.getData(this.userInfo)
						this.$refs.userDaumPwd.getData(this.userInfo)
					}
				})
				.catch(error => {
					this.loading = false
				})
			},
		}
	}
</script>

<style scoped>
	/deep/  .el-tabs__nav-wrap::after{
		border-bottom: 1px dashed #E5E5E5;
		background-color: transparent;
	}
	/deep/ .el-tabs__item{
		padding-right: 60px;
		padding-bottom: 22px;
		font-weight: 400;
		font-size: 18px;
	}
	
	.enter-x-right {
		z-index: 9;
		opacity: 0;
		animation: enter-x-left 0.4s ease-in-out 0.3s;
		animation-fill-mode: forwards;
		transform: translateX(-50px);
	}
	@keyframes enter-x-left {
		to {
			opacity: 1;
			transform: translateX(0);
		}
	}

</style>