<template>
	<div class="user-bg-swg html" >
		<div class="bg_fff">
			<cardHeader :type="'notice'"></cardHeader>
		</div>
		<eshopbg></eshopbg>
		<el-row :gutter="20"
			element-loading-text="加载中..."
			class="wdsffdzcdtg mt24">
			<el-col :span="4" :offset="3">
				<left :tabStr="'/notice/agreement'"></left>
			</el-col>
			<el-col :span="15" >
				<div class="notice-main ">
					<div class="" style="width: 100%;">
						<div class="notice-title">{{article_content.name}}</div>
						<div class="bg_fff mt24 notice-content " v-loading="loading">
							<iframe :src="url" style="width: 100%;border: none;" class="fron-main"></iframe>
						</div>
					</div>

				</div>
			</el-col>
		</el-row>
		<userBottom></userBottom>
	</div>
</template>

<script>
	import cardHeader from '../../components/header/card.vue'
	import userBottom from '../../components/footer/user.vue'
	import eshopbg from '../../components/eshopbg/index.vue'
	import left from '../../components/left/notice.vue'
	import config from '@/config'
	export default{
		components:{
			cardHeader,
			userBottom,
			left,
			eshopbg
		},
		data(){
			return{
				loading:false,
				article_content:"",
				config:"",
				url:""
			}
		},
		created() {
			this.config = config
			this.agreementpc()
		},
		methods:{
			HTMLDecode(text) {
				var temp = document.createElement("div");
				temp.innerHTML = text;
				var output = temp.innerText || temp.textContent;
				temp = null;
				return output;
			},
			agreementpc(){
				this.loading = true
				this.$api.getContent({
					type:7
				})
				.then(result => {
					this.article_content = result.data
					this.url = config.fronUrl + result.data.url
					this.loading = false
				})
			}
		}
	}
</script>

<style scoped lang="less">
	.user-bg-swg{
		background-color: #f7f7f7;
	}
</style>