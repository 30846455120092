<template>
	<div>
		<div class="text-center ft16 mt100" v-loading="loading">
			<div class="text-left" style="width:500px;margin:0 auto" v-if="!type">
				<div class="flex alcenter">
					<div class="real-title sqschyu">手机号</div>
					<el-input  placeholder="在此输入您的手机号" v-model="phone"/>
				</div>
				<div class="flex alcenter mt16 sqschyu">
					<div class="real-title">验证码</div>
					<el-input  placeholder="在此输入您的验证码"  class="wfasftry" v-model="code" @keyup.enter="bindPhone"/>
					<el-button color="#0EB6FF" class="code-button ml16"  @click="getBindCode" :disabled="time!=0">{{time===0?'验证码':time +'s后重新获取'}}</el-button>
				</div>
				<div class="flex alcenter mt16">
					<div class="real-title">&nbsp;</div>
					<el-button color="#0EB6FF" class="real-button" @click="bindPhone">确认提交</el-button>
				</div>
			</div>
			<div  v-else>
				<p class="c_737373">已绑定手机</p>
				<div class="flex-center alcenter mt16">
					<img src="../../../assets/user/user_dum/phone.png" class="phone-img" />
					<div class="ft40 font-weight500">{{userInfo.phone.substring(0, 3) + "****" + userInfo.phone.substring(7, 11)}}</div>
				</div>
				<div class="flex-center alcenter mt24">
					<img src="../../../assets/user/user_dum/warning.png" class="waring-img" />
					<div class="ft12 ">绑定手机可以用于安全身份验证、找回密码等重要操作如有问题，请联系客服咨询</div>
				</div>
				<el-button color="#0EB6FF" class="ipdate-button" @click="dialogVisible = true">更换绑定手机</el-button>
			</div>
		</div>
		<el-dialog v-model="dialogVisible" title="" width='522px' custom-class="pop-radius" @close='closeDialog'>
			<div class="text-center">
				<p class="ft24 font-weight700 c_333333">修改绑定的手机号</p>
				<div class="flex-center alcenter mt40">
					<div class="real-title sqschyu">原手机号</div>
					<el-input  placeholder="在此输入您的手机号" v-model="userInfo.phone" disabled />
				</div>
				<div class="flex-center alcenter mt16">
					<div class="real-title sqschyu">新手机号</div>
					<el-input  placeholder="在此输入您的手机号" v-model="phone"/>
				</div>
				<div class="flex-center alcenter mt16 sqschyu">
					<div class="real-title">验证码</div>
					<el-input  placeholder="在此输入您的验证码" v-model="code"  class="" @keyup.enter="updatePhone">
						<template #suffix>
							<el-link :underline="false"  type="primary" @click="getCode" :disabled="time!=0">{{time===0?'发送验证码':time +'s后重新获取'}}</el-link>
						</template>
					</el-input>
					
					<!-- <el-button color="#0EB6FF" class="code-button ml16" @click="getCode" :disabled="time!=0">{{time===0?'验证码':time +'s后重新获取'}}</el-button> -->
				</div>
				<el-button color="#0EB6FF" class="phone-update-button" @click="updatePhone" >确认提交</el-button>
			</div>
			<template #footer>
				<span class="dialog-footer">
				</span>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import { ElMessage, ElMessageBox } from 'element-plus'
	export default{
		data(){
			return{
				type:false,
				dialogVisible:false,
				userInfo:{},
				time:0,
				phone:"",
				interval:null,
				code:"",
				loading:false
			}
		},
		created(){
			this.getUserInfo()
		},
		methods:{
			bindPhone(){
				if (this.checkPhone(this.phone)){
					ElMessage({
						message: '请输入正确的手机号',
						type: 'warning',
					})
					return
				}
				if (!this.code){
					ElMessage({
						message: '请输入验证码',
						type: 'warning',
					})
					return
				}
				this.$api.bind({
					phone:this.phone,
					code:this.code
				})
				.then(result => {
					if(result.code === 1){
						ElMessage({
							message: result.msg,
							type: 'success',
						})
						this.getUserInfo()
						this.closeDialog()
					}else{
						ElMessage({
							message: result.msg,
							type: 'error',
						})
					}
				})
			},
			getBindCode(){
				if (this.checkPhone(this.phone)){
					ElMessage({
						message: '请输入正确的手机号',
						type: 'warning',
					})
					return
				}
				this.$api.sendBindMobileMessage({
					mobile:this.phone,
				})
				.then(result => {
					if(result.code === 1){
						ElMessage({
							message: result.msg,
							type: 'success',
						})
						this.time = 60 ;
						this.interval = setInterval(() => {
							if(this.time == 0){
								clearInterval(this.interval)
								return
							}
							this.time --
						},1000)
					}else{
						ElMessage({
							message: result.msg,
							type: 'error',
						})
					}
				})
			},
			closeDialog(){
				this.phone = ""
				this.code = ""
				this.time = 0
				this.interval = null
			},
			updatePhone(){
				if (this.checkPhone(this.phone)){
					ElMessage({
						message: '请输入正确的手机号',
						type: 'warning',
					})
					return
				}
				if (!this.code){
					ElMessage({
						message: '请输入验证码',
						type: 'warning',
					})
					return
				}
				this.$api.bindPhone({
					phone:this.phone,
					code:this.code,
				})
				.then(result => {
					if(result.code === 1){
						ElMessage({
							message: result.msg,
							type: 'success',
						})
						this.getUserInfo()
						this.closeDialog()
					}else{
						ElMessage({
							message: result.msg,
							type: 'error',
						})
					}
				})
			},
			// 手机号格式检查
			checkPhone(mobile){
				return !mobile.match(/^1[3|4|5|6|7|8|9][0-9]{9}$/);
			},
			getCode(){
				if (this.checkPhone(this.phone)){
					ElMessage({
						message: '请输入正确的手机号',
						type: 'warning',
					})
					return
				}
				this.$api.sendBindMessage({
					mobile:this.phone
				})
					.then(result => {
						if(result.code === 1){
							ElMessage({
								message: result.msg,
								type: 'success',
							})
							this.time = 60 ;
							this.interval = setInterval(() => {
								if(this.time == 0){
									clearInterval(this.interval)
									return
								}
								this.time --
							},1000)
						}else{
							ElMessage({
								message: result.msg,
								type: 'error',
							})
						}
					})
			},
			getData(item){
				
				this.userInfo = item
				this.type = this.userInfo.phone?true:false
			},
			getUserInfo(){
				this.$emit("getUserInfo")
			},
		}
	}
</script>

<style scoped>
	/deep/.pop-radius{
		border-radius: 24px;
	}
	.real-title{
		width:150px;
	}
	.el-input{
		border-radius: 8px;
		height: 52px;
		box-show:none;
		width: 316px;
	}
	.wfasftry{
		width: 206px;
	}
	.real-button {
		border-radius: 8px;
		height: 60px;
		color:#fff!important;
		width: 316px;
	}
	.code-button{
		border-radius: 8px;
		width: 94px;
		height: 52px;
		color:#fff!important;
	}
	.phone-img{
		width: 22px;
		height: 30px;
		margin-right: 10px;
	}
	.waring-img{
		margin-right: 10px;
		width: 16px;
		height: 16px;
	}
	.ipdate-button{
		border-radius: 8px;
		width: 316px;
		height: 42px;
		margin: 0 auto;
		margin-top: 24px;
		color:#fff!important;
	}
	.phone-update-button{
		border-radius: 8px;
		height: 60px;
		color:#fff!important;
		width:350px;
		margin-top:40px;
	}
</style>